import { BOOKING_LIST_RECEIVED, BOOKING_LIST_LOADER } from "../constants/ActionTypes";

const initialSettings = {
  bookingList: [],
  bookingLoader: false
};

const bookings = (state = initialSettings, action) => {
  switch (action.type) {
    case BOOKING_LIST_RECEIVED:
      return {
        ...state,
        bookingList: action.payload
      };
    case BOOKING_LIST_LOADER:
      return { ...state, bookingLoader: action.payload };

    default:
      return state;
  }
};

export default bookings;
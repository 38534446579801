import React, { lazy } from "react";
import { Button, Paper } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import IntlMessages from '../../../util/IntlMessages';
import CircularProgress from "@material-ui/core/CircularProgress";
import { validateServiceForm } from "../../../util/validators/validateForm";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import * as actions from "../../../actions";
import { connect } from "react-redux";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { NotificationManager } from "react-notifications";
import InputLabel from "@material-ui/core/InputLabel";
import ReactCrop from "react-image-crop";
import Dropzone from "react-dropzone";
import "react-image-crop/dist/ReactCrop.css";
import { Add, Remove } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';

const ContainerHeader = lazy(() => import("../../../components/ContainerHeader/index"));
const CardBox = lazy(() => import("../../../components/CardBox/index"));
const SimpleSelect = lazy(() => import("../components/routes/selects/simple/SimpleSelect"));

const handleDropRejected = (...args) =>
  NotificationManager.error("Image cant be uploaded, try another", "Error");
class ServiceCreate extends React.Component {
  constructor() {
    super();
    this.state = {
      error: "",
      description: "",
      name: "",
      status: "1",
      categoryId: "",
      categoryError: "",
      loader: true,
      loadingButton: false,
      nameError: "",
      price: "",
      priceError: "",
      redirect: false,
      type: "",
      noResponse: false,
      errorMessage: "",
      preview: null,
      categoryData: [],
      howItWorks1Error: "",
      howItWorks2Error: "",
      howItWorks3Error: "",
      preview: null,
      generalPhotos: "",
      status: 1,
      crop: {
        x: 40,
        y: 40,
        width: 100,
        height: 100,
      },
      croppedImageUrl: "",
      questionoire: [],
    };
    this.handleDrop = this.handleDrop.bind(this);
  }
  handleDrop([{ preview }]) {
    this.setState({ preview });
  }

  onImageLoaded = (image, pixelCrop) => {
    this.imageRef = image;
  };
  onCropComplete = async (crop) => {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ croppedImageUrl });
    }
  };

  getCroppedImg(image, pixelCrop, fileName) {
    const canvas = document.createElement("canvas");

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      pixelCrop.x * scaleX,
      pixelCrop.y * scaleY,
      pixelCrop.width * scaleX,
      pixelCrop.height * scaleY,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );

    return new Promise((resolve, reject) => {
      this.fileUrl = canvas.toDataURL();
      resolve(this.fileUrl);
    });
  }

  submit = params => {
    this.setState({ loadingButton: true });
    const {
      name,
      description,
      price,
      status,
      categoryId,
      howItWorks1,
      howItWorks2,
      howItWorks3,
      questionoire
    } = this.state;

    if (
      validateServiceForm(
        { name, categoryId, price, howItWorks1, howItWorks2, howItWorks3, questionoire },
        this.handleError
      )
    ) {
      this.props.serviceCreateAction(
        {
          name,
          description,
          price,
          status,
          categoryId,
          how_it_works1: this.state.howItWorks1,
          how_it_works2: this.state.howItWorks2,
          how_it_works3: this.state.howItWorks3,
          image: this.state.croppedImageUrl,
          questionoire
        },
        this.onDone
      );
    } else {
      NotificationManager.error("Validation Error", "Oops!");
    }
  };
  handleError = params => {
    this.setState(params);
    this.setState({ loadingButton: false });
  };
  onDone = (success, message) => {
    if (success) {
      this.setState({ loadingButton: false });
      return this.props.history.push("/app/service/list");
    } else {
      this.setState({ loadingButton: false });
    }
  };

  componentDidMount() {
    this.setState({ loader: true });
    this.props.subcategoryListAction(this.onCategoryDone);
  }
  onCategoryDone = (success, data) => {
    if (success) {
      this.setState({ categoryData: data });
    }
    this.setState({ loader: false });
  };
  renderButton = () => {
    if (this.state.loadingButton) {
      return <CircularProgress />;
    }
    return (
      <Button
        variant="raised"
        color="primary"
        className="jr-btn"
        onClick={this.submit}
      >
        Add
      </Button>
    );
  };
  handleChange = categoryId => event => {
    this.setState({ [categoryId]: event.target.value, categoryError: "" });
  };

  renderMultiQuestionoireForm = (
    index,
    { question, questionError }
  ) => {
    return (
      <div className="row">
        <div className="col-md-4 col-xs-12">
          <TextField
            type="text"
            error={questionError ? true : false}
            helperText={questionError}
            name={question}
            label="Question"
            id={index}
            fullWidth
            placeholder="Add Question"
            InputLabelProps={{
              shrink: true
            }}
            value={question}
            margin="normal"
            onChange={event =>
              this.handleQuestionoireChange(index, event)
            }
          />
        </div>

        <div className="col-md-4 col-xs-12">
          <Button
            variant="outlined"
            onClick={() => this.removeQuestionoireItem(index)}
          >
            <Remove />
            <FormattedMessage id="remove" defaultMessage="Remove" />
          </Button>
        </div>
      </div>
    );
  };

  handleQuestionoireChange = (index, event) => {
    const questionoireValues = this.state.questionoire;
    questionoireValues[index].question = event.target.value;
    questionoireValues[index].questionError = '';
    this.setState({ questionoire: questionoireValues });
  };

  addNewQuestionoireItem = () => {
    this.setState(
      prevState => ({
        questionoire: [
          ...prevState.questionoire,
          {
            question: '',
            questionError: ''
          }
        ]
      }),
      () => console.log(this.state.questionoire)
    );
  };
  removeQuestionoireItem = index => {
    let newFaqs = this.state.questionoire;
    newFaqs.splice(index, 1);
    this.setState({ questionoire: newFaqs });
  };


  render() {
    const { match, history } = this.props;

    const {
      status,
      loader,
      categoryData,
      categoryError,
      nameError,
      priceError,
      descriptionError,
      questionoire
    } = this.state;
    if (loader) {
      return (
        <div className="loader-view loader-center">
          <CircularProgress />
        </div>
      );
    }
    return (
      <div className="animated slideInUpTiny animation-duration-3">
        <ContainerHeader
          title={<IntlMessages id="add-service" />}
          match={match}
          history={history}
        />

        <CardBox styleName="col-lg-12">
          <form
            className="row"
            noValidate
            autoComplete="off"
            id="category-create"
            encType="multipart/form-data"
          >
            {/* <div className="col-md-12 col-xs-12">
              <div className="row">
                <div className="col-md-2">
                  <h3>Drop Image here to Upload</h3>
                  <Dropzone
                    onDrop={this.handleDrop}
                    accept="image/jpeg,image/jpg,image/png"
                    multiple={false}
                    onDropRejected={handleDropRejected}
                  >
                    <p> Drag a file here or click to upload.</p>
                  </Dropzone>
                </div>
                <div className="col-md-6">
                  {preview && (
                    <div>
                      <h3>Original Image</h3>
                      <ReactCrop
                        src={preview}
                        imageStyle={{
                          width: "100%",
                          height: "100%"
                        }}
                        onImageLoaded={this.onImageLoaded}
                        onComplete={this.onCropComplete}
                        crop={this.state.crop}
                        onChange={crop => {
                          this.setState({ crop });
                        }}
                      />
                    </div>
                  )}
                </div>
                <div className="col-md-4">
                  {croppedImageUrl && (
                    <div>
                      <h3>Final Image</h3>
                      <img
                        width={"100%"}
                        height={"100%"}
                        alt="Crop"
                        src={croppedImageUrl}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div> */}
            <div className="col-12">
              <div className="row">
                <div className="col-md-4 col-12">
                  <TextField
                    error={nameError ? true : false}
                    helperText={nameError}
                    id="name"
                    label="Name"
                    name="name"
                    InputLabelProps={{
                      shrink: true
                    }}
                    placeholder="Add Name"
                    fullWidth
                    onChange={event => {
                      this.setState({
                        name: event.target.value,
                        nameError: ""
                      });
                    }}
                    disabled={loader ? true : false}
                    margin="normal"
                  />
                </div>
                <div className="col-md-4 col-xs-12">
                  <FormControl
                    className="w-100 mt-3"
                    error={categoryError ? true : false}
                  >
                    <InputLabel htmlFor="categoryId">Sub Category</InputLabel>
                    <Select
                      fullWidth
                      name="categoryId"
                      value={this.state.categoryId}
                      onChange={this.handleChange("categoryId")}
                      input={<Input id="categoryId" />}
                    >
                      {categoryData &&
                        categoryData.map((item, index) => {
                          return (
                            <MenuItem value={item.key} key={index}>
                              {item.heading}
                            </MenuItem>
                          );
                        })}
                    </Select>
                    <FormHelperText>{categoryError}</FormHelperText>
                  </FormControl>
                </div>
                <div className="col-md-4 col-xs-12">
                  <SimpleSelect
                    fullWidth
                    label="Status"
                    name="status"
                    id="status"
                    value={status}
                    onChange={status => {
                      this.setState({ status });
                    }}
                    disabled={loader ? true : false}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-xs-12">
                  <TextField
                    error={priceError ? true : false}
                    helperText={priceError}
                    id="price"
                    label="Price"
                    InputLabelProps={{
                      shrink: true
                    }}
                    name="price"
                    placeholder="Add Price"
                    fullWidth
                    margin="normal"
                    disabled={loader ? true : false}
                    onChange={event => {
                      this.setState({
                        price: event.target.value,
                        priceError: ""
                      });
                    }}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-xs-12">
                  <TextField
                    error={descriptionError ? true : false}
                    helperText={descriptionError}
                    id="description"
                    label="Description"
                    InputLabelProps={{
                      shrink: true
                    }}
                    name="description"
                    placeholder="Add Description"
                    fullWidth
                    margin="normal"
                    disabled={loader ? true : false}
                    onChange={event => {
                      this.setState({
                        description: event.target.value,
                        descriptionError: ""
                      });
                    }}
                  />
                </div>

                <div className="col-md-12">
                  {' '}
                  <Paper className="col-md-12">
                    <h4>
                      <FormattedMessage
                        id="enterQuestionoire"
                        defaultMessage="Enter Your Questionoire Details"
                      />
                    </h4>
                    {questionoire && questionoire.length > 0 &&
                      questionoire.map((faqItem, index) => {
                        return (
                          <React.Fragment key={index}>
                            {this.renderMultiQuestionoireForm(
                              index,
                              faqItem
                            )}
                          </React.Fragment>
                        );
                      })}

                    <div className="mt-3 mb-3 pb-3">
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={this.addNewQuestionoireItem}
                      >
                        <Add />
                        <FormattedMessage
                          id="add"
                          defaultMessage="Add"
                        />
                      </Button>
                    </div>
                  </Paper>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-xs-12">
              <div className="mt-3" />
              {this.renderButton()}
            </div>
          </form>
        </CardBox>
      </div>
    );
  }
}

export default connect(
  null,
  actions
)(ServiceCreate);

import {
  createHelpdeskSettings,
  helpdeskReply,
  helpdeskUpdate
} from "../apiHelpers/helpdesk/create";
import { helpdesklist } from "../apiHelpers/helpdesk/list";
import { helpdeskView } from "../apiHelpers/helpdesk/view";

export const helpdeskSettingCreateAction = (params, done) => async dispatch => {
  const list = await createHelpdeskSettings(params);
  if (list.status == "OK") {
    done(true, list.data, list.message);
  } else {
    done(false, list.message);
  }
};

export const helpdeskListAction = done => async dispatch => {
  const list = await helpdesklist();
  if (list.status == "OK") {
    done(true, list.data, list.message);
  } else {
    done(false, list.message);
  }
};

export const helpdeskViewAction = (params, done) => async dispatch => {
  const list = await helpdeskView(params);
  if (list.status == "OK") {
    done(true, list.data, list.message);
  } else {
    done(false, list.message);
  }
};

export const helpdeskReplyAction = (params, done) => async dispatch => {
  const list = await helpdeskReply(params);
  if (list.status == "OK") {
    done(true, list.data, list.message);
  } else {
    done(false, list.message);
  }
};

export const markHelpdeskStatusAction = (params, done) => async dispatch => {
  const list = await helpdeskUpdate(params);
  if (list.status == "OK") {
    done(true, list.data, list.message);
  } else {
    done(false, list.message);
  }
};
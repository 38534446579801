import React, { lazy } from "react";
import { Button, Paper } from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import IntlMessages from '../../../util/IntlMessages';
import CircularProgress from "@material-ui/core/CircularProgress";
import { validateServiceForm } from "../../../util/validators/validateForm";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import * as actions from "../../../actions";
import { connect } from "react-redux";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";

import InputLabel from "@material-ui/core/InputLabel";
import { NotificationManager } from "react-notifications";
import { Add, Remove } from '@material-ui/icons';
import { FormattedMessage } from 'react-intl';

const ContainerHeader = lazy(() => import("../../../components/ContainerHeader/index"));
const CardBox = lazy(() => import("../../../components/CardBox/index"));
const SimpleSelect = lazy(() => import("../components/routes/selects/simple/SimpleSelect"));
const HOCLoader = lazy(() => import("../../../components/MyComponents/HOCLoader"));

class ServiceUpdate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: "",
      description: "",
      name: "",
      price: "",
      priceError: "",
      status: "1",
      categoryId: "",
      categoryError: "",
      loader: true,
      loadingButton: false,
      nameError: "",
      redirect: false,
      type: "",
      noResponse: false,
      errorMessage: "",
      preview: null,
      categoryData: [],
      serviceDetail: "",
      itemId: props.location.state ? props.location.state.itemId : "",
      questionoire: []
    };
  }
  componentDidMount() {
    if (!this.props.location.state) {
      return this.props.history.push("/app/service/list");
    } else {
      this.getData(this.props.location.state.itemId);
      this.props.subcategoryListAction(this.onCategoryDone);
    }
  }
  onCategoryDone = (success, data) => {
    console.log({ data });
    if (success) {
      this.setState({ categoryData: data });
    }
    this.setState({ loader: false });
  };
  //apicall
  getData = async itemId => {
    this.props.serviceDetailAction(
      {
        id: itemId
      },
      this.onDoneDetail
    );
  };

  onDoneDetail = (success, data) => {
    console.log("service detail", data);
    if (success) {
      this.setState(
        {
          serviceDetail: data,
          name: data.heading,
          status: data.status,
          price: data.price,
          description: data.description,
          categoryId: data.category_id,
          questionoire: data.questionoire ? data.questionoire : [],
        },
        () => {
          this.setState({ loader: false });
        }
      );
    } else {
      this.setState({ loader: false, noResponse: true, error: data });
    }
  };
  //apicall
  submit = params => {
    const { name, description, price, status, categoryId, itemId, questionoire } = this.state;

    if (validateServiceForm({ name, categoryId, price, questionoire }, this.handleError)) {
      this.setState({ loadingButton: true });
      this.props.serviceUpdateAction(
        {
          id: itemId,
          name,
          price,
          description,
          status,
          categoryId,
          questionoire
        },
        this.onDone
      );
    }
  };
  handleError = params => {
    this.setState(params);
    this.setState({ loadingButton: false });
  };
  onDone = (success, data) => {
    if (success) {
      this.setState({ loadingButton: false });
      NotificationManager.success("Service updated successfully", "Success");
      return this.props.history.push("/app/service/list");
    } else {
      this.setState({ loadingButton: false });
    }
  };

  renderButton = () => {
    if (this.state.loadingButton) {
      return <CircularProgress />;
    }
    return (
      <Button
        variant="contained"
        color="primary"
        className="jr-btn"
        onClick={this.submit}
      >
        Update
      </Button>
    );
  };
  handleChange = categoryId => event => {
    this.setState({ [categoryId]: event.target.value, categoryError: "" });
  };

  handleQuestionoireChange = (index, event) => {
    const questionoireValues = this.state.questionoire;
    questionoireValues[index].question = event.target.value;
    questionoireValues[index].questionError = '';
    this.setState({ questionoire: questionoireValues });
  };

  renderMultiQuestionoireForm = (
    index,
    { question, questionError }
  ) => {
    return (
      <div className="row">
        <div className="col-md-4 col-xs-12">
          <TextField
            type="text"
            error={questionError ? true : false}
            helperText={questionError}
            name={question}
            label="Question"
            id={index}
            fullWidth
            placeholder="Add Question"
            InputLabelProps={{
              shrink: true
            }}
            value={question}
            margin="normal"
            onChange={event =>
              this.handleQuestionoireChange(index, event)
            }
          />
        </div>

        <div className="col-md-4 col-xs-12">
          <Button
            variant="outlined"
            onClick={() => this.removeQuestionoireItem(index)}
          >
            <Remove />
            <FormattedMessage id="remove" defaultMessage="Remove" />
          </Button>
        </div>
      </div>
    );
  };

  addNewQuestionoireItem = () => {
    this.setState(
      prevState => ({
        questionoire: [
          ...prevState.questionoire,
          {
            question: '',
            questionError: ''
          }
        ]
      }),
      () => console.log(this.state.questionoire)
    );
  };
  removeQuestionoireItem = index => {
    let newFaqs = this.state.questionoire;
    newFaqs.splice(index, 1);
    this.setState({ questionoire: newFaqs });
  };


  render() {
    const { match } = this.props;

    const {
      status,
      loader,
      noResponse,
      errorMessage,
      categoryData,
      categoryError,
      nameError,
      descriptionError,
      priceError,
      serviceDetail,
      loadingButton,
      categoryId,
      questionoire
    } = this.state;
    if (loader) {
      return (
        <div className="loader-view loader-center">
          <CircularProgress />
        </div>
      );
    }
    return (
      <HOCLoader
        loading={loader}
        noResponse={noResponse}
        errorShow={errorMessage}
        retryLink={"/app/category/list"}
      >
        <div className="animated slideInUpTiny animation-duration-3">
          <ContainerHeader
            title={<IntlMessages id="update-service" />}
            match={match}
          />

          <CardBox styleName="col-lg-12">
            <form
              className="row"
              noValidate
              autoComplete="off"
              id="category-create"
              encType="multipart/form-data"
            >
              <div className="col-12">
                <div className="row">
                  <div className="col-md-4 col-12">
                    <TextField
                      error={nameError ? true : false}
                      helperText={nameError}
                      id="name"
                      label="Name"
                      name="name"
                      InputLabelProps={{
                        shrink: true
                      }}
                      placeholder="Add Name"
                      fullWidth
                      onChange={event => {
                        this.setState({
                          name: event.target.value,
                          nameError: ""
                        });
                      }}
                      defaultValue={serviceDetail.heading}
                      disabled={loadingButton ? true : false}
                      margin="normal"
                    />
                  </div>
                  <div className="col-md-4 col-xs-12">
                    <FormControl
                      className="w-100 mt-3"
                      error={categoryError ? true : false}
                    >
                      <InputLabel htmlFor="categoryId">Sub Category</InputLabel>
                      <Select
                        fullWidth
                        name="categoryId"
                        value={this.state.categoryId}
                        onChange={this.handleChange("categoryId")}
                        input={
                          <Input
                            value={this.state.categoryId}
                            id="categoryId"
                            disabled={loadingButton ? true : false}
                          />
                        }
                      >
                        {categoryData &&
                          categoryData.map((item, index) => {
                            return (
                              <MenuItem value={item.key} key={index}>
                                {item.heading}
                              </MenuItem>
                            );
                          })}
                      </Select>
                      <FormHelperText>{categoryError}</FormHelperText>
                    </FormControl>
                  </div>
                  <div className="col-md-4 col-xs-12">
                    {/* <SimpleSelect
                      fullWidth
                      label="Status"
                      name="status"
                      id="category-status"
                      defaultValue={status}
                      onChange={status => {
                        this.setState({ status });
                      }}
                      defaultValue={serviceDetail.status}
                      disabled={loadingButton ? true : false}
                    /> */}

                    <FormControl className="w-100 mt-3">
                      <InputLabel htmlFor={"status"}>Status</InputLabel>
                      <Select
                        value={status}
                        onChange={this.handleChange("status")}
                        input={<Input id={"status"} />}
                      >
                        <MenuItem value={1}>
                          <em>Active</em>
                        </MenuItem>
                        <MenuItem value={2}>Inactive</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 col-xs-12">
                    <TextField
                      error={priceError ? true : false}
                      helperText={priceError}
                      id="price"
                      label="Price"
                      InputLabelProps={{
                        shrink: true
                      }}
                      onChange={event => {
                        this.setState({
                          price: event.target.value,
                          priceError: ""
                        });
                      }}
                      name="price"
                      placeholder="Add Price"
                      fullWidth
                      margin="normal"
                      defaultValue={serviceDetail.price}
                      disabled={loadingButton ? true : false}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 col-xs-12">
                    <TextField
                      error={descriptionError ? true : false}
                      helperText={descriptionError}
                      id="description"
                      label="Description"
                      InputLabelProps={{
                        shrink: true
                      }}
                      onChange={event => {
                        this.setState({
                          description: event.target.value,
                          descriptionError: ""
                        });
                      }}
                      name="description"
                      placeholder="Add Description"
                      fullWidth
                      margin="normal"
                      defaultValue={serviceDetail.description}
                      disabled={loadingButton ? true : false}
                    />
                  </div>

                  <div className="col-md-12">
                    {' '}
                    <Paper className="col-md-12 mt-3">
                      <h4>
                        <FormattedMessage
                          id="enterQuestionoire"
                          defaultMessage="Enter Your Questionoire Details"
                        />
                      </h4>
                      {questionoire && questionoire.length > 0 &&
                        questionoire.map((faqItem, index) => {
                          return (
                            <React.Fragment key={index}>
                              {this.renderMultiQuestionoireForm(
                                index,
                                faqItem
                              )}
                            </React.Fragment>
                          );
                        })}

                      <div className="mt-3 mb-3 pb-3">
                        <Button
                          variant="outlined"
                          color="primary"
                          onClick={this.addNewQuestionoireItem}
                        >
                          <Add />
                          <FormattedMessage
                            id="add"
                            defaultMessage="Add"
                          />
                        </Button>
                      </div>
                    </Paper>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-xs-12">
                <div className="mt-3" />
                {this.renderButton()}
              </div>
            </form>
          </CardBox>
        </div>
      </HOCLoader>
    );
  }
}

export default connect(
  null,
  actions
)(ServiceUpdate);

import React, { Component } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import { lighten } from "@material-ui/core/styles/colorManipulator";
import { Link, Redirect } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import * as actions from "../../actions";
import DeleteAlert from "../Alert/DeleteAlert";
import { FormattedMessage, injectIntl } from "react-intl";
import { NotificationManager } from "react-notifications";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
// import '../../assets/css/style.css';
import { isMobile } from "react-device-detect";
import WSButton from "../Button/WSButton";

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === "desc"
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

class AddressListHead extends Component {
  createSortHandler = (property) => (event) => {
    const { onRequestSort } = this.props;
    onRequestSort(event, property);
  };
  addNewItem() {
    this.setState({
      addBtn: "create",
      userId: this.props.actualData[0].createdBy,
    });
  }
  render() {
    const { formatMessage } = this.props;
    const rows = [
      {
        id: "name",
        label: "Name",
      },
      {
        id: "email",
        label: "Email",
      },
      {
        id: "address_line1",
        label: "Address",
      },
      {
        id: "city",
        label: "City",
      },
      {
        id: "state",
        label: "State",
      },
      {
        id: "country",
        label: "Country",
      },
      {
        id: "phone",
        label: "Phone",
      },
      {
        id: "is_default",
        label: "Default",
      },
      {
        id: "action",
        label: "Action",
      },
    ];

    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
    } = this.props;

    return (
      <TableHead>
        <TableRow>
          {rows.map(
            (row) => (
              <TableCell
                key={row.id}
                align={row.numeric ? "right" : "left"}
                padding={row.disablePadding ? "none" : "default"}
                sortDirection={orderBy === row.id ? order : false}
              >
                <Tooltip
                  title="Sort"
                  placement={row.numeric ? "bottom-end" : "bottom-start"}
                  enterDelay={300}
                >
                  <TableSortLabel
                    active={orderBy === row.id}
                    direction={order}
                    onClick={this.createSortHandler(row.id)}
                  >
                    {row.label}
                  </TableSortLabel>
                </Tooltip>
              </TableCell>
            ),
            this
          )}
        </TableRow>
      </TableHead>
    );
  }
}

AddressListHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.string.isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const toolbarStyles = (theme) => ({
  root: {
    paddingRight: theme.spacing.unit,
  },
  highlight:
    theme.palette.type === "light"
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  spacer: {
    flex: "1 1 100%",
  },
  actions: {
    color: theme.palette.secondary.main,
  },
  title: {
    flex: "0 0 auto",
  },
});

let AddressListToolbar = (props) => {
  const {
    numSelected,
    classes,
    deleteAction,
    match,
    itemId,
    providerDetails,
  } = props;

  return (
    <Toolbar
      className={classNames(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      <div className={classes.title}>
        {numSelected > 0 ? (
          <Typography color="inherit" variant="subtitle1">
            {numSelected}&nbsp;
            <FormattedMessage id="selected" defaultMessage="selected" />
          </Typography>
        ) : (
          <h6
            style={{
              fontWeight: 500,
              fontSize: isMobile ? "14px" : "16px",
            }}
          >
            &nbsp;
            <FormattedMessage
              id="customer.myAddresses"
              defaultMessage="Provider's Addresses"
            />
          </h6>
        )}
      </div>
      <div className={classes.spacer} />
      <div className="addNewBtn">
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton aria-label="Delete" onClick={this.deleteAddress}>
              <DeleteIcon onClick={deleteAction} />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Add">
            <WSButton
              className="addNewAddressBtn"
              variant="fab"
              // onClick={()=>this.addNewItem()}
              aria-label="Add"
              style={{
                padding: 0,
                height: 40,
              }}
            >
              <Link
                style={{
                  textDecoration: "none",
                  color: "#e91e63",
                  fontSize: "14px",
                }}
                to={{
                  pathname: `/app/provider/address/add`,
                  state: { providerDetails: providerDetails },
                }}
              >
                Add New
              </Link>
            </WSButton>
          </Tooltip>
        )}
      </div>
    </Toolbar>
  );
};

AddressListToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

AddressListToolbar = withStyles(toolbarStyles)(AddressListToolbar);

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
  },
  table: {
    minWidth: 600,
  },
});

class AddressList extends Component {
  constructor(props) {
    super();
    this.state = {
      order: "asc",
      orderBy: "created_at",
      selected: [],
      data: props.providerDetails.addresses,
      page: 0,
      rowsPerPage: 5,
      clickValue: "",
      item: "",
      itemId: "",
      showAlert: false,
      defaultAddressvalue: "",
      deleteAlert: false,
      selectedDeleteKey: [],
      userId: props.location.state ? props.location.state.userId : "",
    };
  }

  actionCallbacks(action, res) {
    if (action === "update") {
      this.setState({
        clickValue: "update",
        item: res,
        menuState: false,
        itemId: res.key,
      });
    }
    if (action === "delete") {
      let deleteKey = [];
      deleteKey.push(res.key);
      this.setState(
        {
          selectedDeleteKey: deleteKey,
          menuState: false,
        },
        () => {
          this.deleteRecord();
        }
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { providerDetails } = this.props;
    if (
      prevProps.providerDetails.addresses.length !==
      providerDetails.addresses.length
    ) {
      this.setState({ data: providerDetails.addresses });
    }
  }

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";
    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }
    this.setState({ order, orderBy });
  };

  handleSelectAllClick = (event) => {
    if (event.target.checked) {
      this.setState((state) => ({
        selected: state.data.map((n) => n.key),
      }));
      return;
    }
    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    this.setState({ selected: newSelected });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  isSelected = (id) => this.state.selected.indexOf(id) !== -1;

  deleteRecord = () => {
    this.setState({ showAlert: true });
  };

  deleteAddress = () => {
    const { deleteAddressAction } = this.props;
    const { selectedDeleteKey } = this.state;
    deleteAddressAction(
      {
        ids: selectedDeleteKey,
      },
      (success, data) => this.onDeleteDone(success, data)
    );
  };

  onDeleteDone = (success, data) => {
    this.setState({ loader: false });
    const { history, location } = this.props;
    if (success) {
      this.setState({ showAlert: false });
      history.push(`/app/user/providers`);
      NotificationManager.success(data.message, "Success");
    }
  };

  renderDefaultAddress = () => {
    const { data } = this.state;
    const defaultAddressvalue = data.filter(
      (address) => address.is_default === 1
    )[0];
    if (defaultAddressvalue) {
      return (
        <div>
          <h4>
            <b>
              &nbsp;
              <FormattedMessage
                id="defaultAddress"
                defaultMessage="Default Address"
              />
            </b>
          </h4>
          <p>{defaultAddressvalue.name},</p>
          <p>
            {defaultAddressvalue.phone},<br />
            {defaultAddressvalue.email},<br />
            {defaultAddressvalue.address_line1},{defaultAddressvalue.city},{" "}
            {defaultAddressvalue.state}
            ,&nbsp;
            {defaultAddressvalue.country}
          </p>
        </div>
      );
    }
  };

  render() {
    const { classes, match, intl, providerDetails } = this.props;
    const { formatMessage } = intl;
    const {
      data,
      order,
      orderBy,
      selected,
      rowsPerPage,
      page,
      clickValue,
      item,
      itemId,
      showAlert,
      userId,
    } = this.state;
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, data.length - page * rowsPerPage);

    return (
      <div>
        <Paper className={classes.root}>
          <AddressListToolbar
            numSelected={selected.length}
            deleteAction={this.deleteRecord}
            match={match}
            itemId={this.state.itemId}
            providerDetails={this.props.providerDetails}
          />
          <div className="table-responsive">
            <Table className="table" aria-labelledby="tableTitle">
              <AddressListHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={this.handleRequestSort}
                rowCount={data.length}
                formatMessage={formatMessage}
              />
              <TableBody>
                {data.length > 0 ? (
                  stableSort(data, getSorting(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((n) => {
                      const isSelected = this.isSelected(n.key);
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isSelected}
                          tabIndex={-1}
                          key={n.key}
                          selected={isSelected}
                        >
                          <TableCell component="th" scope="row">
                            {n.name}
                          </TableCell>
                          <TableCell component="th" scope="row">
                            {n.email}
                          </TableCell>
                          <TableCell>{n.address_line1}</TableCell>
                          <TableCell>{n.city}</TableCell>
                          <TableCell>{n.state}</TableCell>
                          <TableCell>{n.country}</TableCell>
                          <TableCell>{n.phone}</TableCell>
                          <TableCell>
                            {n.is_default ? <CheckCircleIcon /> : ""}
                          </TableCell>
                          <TableCell>
                            {data.length > 1 ? (
                              <div>
                                <WSButton
                                  id="update"
                                  className="mr-10 mb-5"
                                  variant="outlined"
                                >
                                  <Link
                                    style={{
                                      textDecoration: "none",
                                      color: "#e91e63",
                                      fontSize: "14px",
                                    }}
                                    to={{
                                      pathname: `/app/provider/address/update/${n.key}`,
                                      state: {
                                        item: n,
                                        fromPage:
                                          window.location.pathname ===
                                          "/user/profile"
                                            ? "myProfile"
                                            : "myAddress",
                                        providerDetails: providerDetails,
                                        userId: userId,
                                      },
                                    }}
                                  >
                                    Update
                                  </Link>
                                </WSButton>
                                <WSButton
                                  id="delete"
                                  className="mb-5"
                                  variant="outlined"
                                  onClick={() =>
                                    this.actionCallbacks("delete", n)
                                  }
                                >
                                  Delete
                                </WSButton>
                              </div>
                            ) : (
                              <WSButton
                                id="update"
                                className="mr-10 mb-5"
                                variant="outlined"
                              >
                                <Link
                                  style={{
                                    textDecoration: "none",
                                    color: "#e91e63",
                                    fontSize: "14px",
                                  }}
                                  to={{
                                    pathname: `/app/provider/address/update/${n.key}`,
                                    state: {
                                      item: n,
                                      fromPage:
                                        window.location.pathname ===
                                        "/user/profile"
                                          ? "myProfile"
                                          : "myAddress",
                                      providerDetails: providerDetails,
                                      userId: userId,
                                    },
                                  }}
                                >
                                  Update
                                </Link>
                              </WSButton>
                            )}
                          </TableCell>
                        </TableRow>
                      );
                    })
                ) : (
                  <TableRow>
                    <TableCell className="noDataFound">
                      No Address Found
                    </TableCell>
                  </TableRow>
                )}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 49 * emptyRows }}>
                    <TableCell colSpan={8} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            className="dashboard-table-pagination"
            classes={{
              selectIcon: "tablePaginationSelectIcon",
            }}
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{
              "aria-label": "Previous Page",
            }}
            nextIconButtonProps={{
              "aria-label": "Next Page",
            }}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </Paper>
        <DeleteAlert
          open={showAlert}
          title="address"
          close={() => this.setState({ showAlert: false })}
          handleYes={this.deleteAddress}
          handleNo={() => this.setState({ showAlert: false })}
        />
      </div>
    );
  }
}

AddressList.propTypes = {
  classes: PropTypes.object.isRequired,
};

const composedComponent = compose(withStyles(styles), connect(null, actions));

export default composedComponent(injectIntl(AddressList));

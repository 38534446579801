import React, { lazy } from "react";
import { withRouter } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  Line,
  LineChart,
  Pie,
  PieChart,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  RadarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis
} from "recharts";
import {
  announcementsNotification,
  appNotification,
  chartData,
  customers,
  data,
  data1,
  data2,
  marketingData,
  pieChartData,
  products,
  radarChartData
} from "./data";
import IntlMessages from '../../../util/IntlMessages';
import * as actions from "../../../actions";
import { connect } from "react-redux";
import { compose } from "redux";

const SalesStatistic = lazy(() => import("../../../components/dashboard/eCommerce/SalesStatistic"));
const ChartCard = lazy(() => import("../../../components/dashboard/Common/ChartCard"));
const ProductImage = lazy(() => import("../../../components/dashboard/eCommerce/ProductImage"));
const OrderTable = lazy(() => import("../../../components/dashboard/eCommerce/OrderTable"));
const MarketingTable = lazy(() => import("../../../components/dashboard/Common/MarketingTable"));
const PopularProduct = lazy(() => import("../../../components/dashboard/Common/PopularProduct"));
const ContainerHeader = lazy(() => import("../../../components/ContainerHeader/index"));
const ReportBox = lazy(() => import("../../../components/ReportBox/index"));
const UserDetailTable = lazy(() => import("../../../components/dashboard/Common/UserDetailTable"));
const LatestNotifications = lazy(() => import("../../../components/dashboard/Common/LatestNotifications"));
const CustomerAroundWorld = lazy(() => import("../../../components/dashboard/Common/CustomerAroundWorld"));
const CardMenu = lazy(() => import("../../../components/dashboard/Common/CardMenu"));
const CardHeader = lazy(() => import("../../../components/dashboard/Common/CardHeader/index"));
const HOCLoader = lazy(() => import("../../../components/MyComponents/HOCLoader"));

class Dashboard extends React.Component {
  onOptionMenuSelect = event => {
    this.setState({ menuState: true, anchorEl: event.currentTarget });
  };
  handleRequestClose = () => {
    this.setState({ menuState: false });
  };

  constructor() {
    super();
    this.state = {
      anchorEl: undefined,
      menuState: false,
      dashboardData: [],
      loader: true,
      settingData: [],
      currency: "$"
    };
  }
  componentDidMount() {
    this.props.getDashboardDataAction(this.onDone);
    this.props.getSettingsAction(this.onSettingDone);
  }
  onDone = (success, data) => {
    if (success) {
      this.setState({ dashboardData: data, loader: false });
    }
  };
  onSettingDone = (success, data) => {
    if (success) {
      this.setState({
        settingData: data,
        currency: data.thirdParty.currencySymbol
      });
    }
  };
  chartClick = (tab) => {
    this.props.history.push(tab);
  }

  render() {
    const { dashboardData, currency } = this.state;
    return (
      <HOCLoader loading={this.state.loader}>
        <div className="dashboard animated slideInUpTiny animation-duration-3">
          <ContainerHeader
            match={this.props.match}
            title={<IntlMessages id="sidebar.dashboard" />}
          />

          <div className="row">
            <div className="col-lg-3 col-sm-6 col-12">
              <ChartCard styleName="bg-secondary text-white">
                <div className="chart-title">
                  <h2>
                    {dashboardData.totalCustomers
                      ? dashboardData.totalCustomers
                      : 0}
                  </h2>
                  <p>
                    <a style={{ color: 'white' }} href="javascript:void(0);" onClick={() => this.chartClick('/app/user/customers')}>
                      <IntlMessages id="usersregistered" />
                    </a>
                  </p>
                </div>

                <ResponsiveContainer width="100%" height={110}>
                  <BarChart data={data}>
                    <Bar dataKey="pv" fill="white" />
                    <Bar dataKey="uv" fill="white" />
                  </BarChart>
                </ResponsiveContainer>
              </ChartCard>
            </div>

            <div className="col-lg-3 col-sm-6 col-12">
              <ChartCard styleName="bg-primary text-white">
                <div className="chart-title">
                  <h2>
                    {dashboardData.totalProviders
                      ? dashboardData.totalProviders
                      : 0}
                  </h2>
                  <p>
                    <a style={{ color: 'white' }} href="javascript:void(0);" onClick={() => this.chartClick('/app/user/providers')}>
                      <IntlMessages id="providersRegistered" />
                    </a>
                  </p>
                </div>
                <ResponsiveContainer width="100%" height={110}>
                  <AreaChart
                    data={data1}
                    margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                  >
                    <Area
                      type="monotone"
                      dataKey="pv"
                      stroke="rgba(255,255,255,0.5)"
                      activeDot={{ r: 8 }}
                      fillOpacity={0.5}
                      fill="white"
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </ChartCard>
            </div>

            <div className="col-lg-3 col-sm-6 col-12">
              <ChartCard styleName="bg-teal lighten-1 text-white">
                <div className="chart-title">
                  <h2>
                    {dashboardData.totalBookings
                      ? dashboardData.totalBookings
                      : 0}
                  </h2>
                  <p>
                    <a style={{ color: 'white' }} href="javascript:void(0);" onClick={() => this.chartClick('/app/booking/list')}>
                      <IntlMessages id="totalbookings" />
                    </a>
                  </p>
                </div>
                <ResponsiveContainer width="100%" height={110}>
                  <LineChart data={data2}>
                    <Line
                      type="monotone"
                      dataKey="uv"
                      stroke="#ffffff"
                      activeDot={{ r: 8 }}
                    />
                    <Line type="monotone" dataKey="pv" stroke="#ffffff" />
                    <Line type="monotone" dataKey="amt" stroke="#ffffff" />
                  </LineChart>
                </ResponsiveContainer>
              </ChartCard>
            </div>

            <div className="col-lg-3 col-sm-6 col-12">
              <ChartCard styleName="bg-blue text-white">
                <div className="chart-title">
                  <h2>
                    {currency}
                    {dashboardData.totalRevenue}
                  </h2>
                  <p>
                    <IntlMessages id="totalrevenue" />
                  </p>
                </div>

                <ResponsiveContainer width="100%" height={110}>
                  <LineChart data={data1}>
                    <Line dataKey="uv" stroke="#ffffff" activeDot={{ r: 8 }} />
                  </LineChart>
                </ResponsiveContainer>
              </ChartCard>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <SalesStatistic data={dashboardData.bookingChart} />
            </div>
          </div>

          <div className="row">
            <div className="col-xl-4 col-md-6 col-12">
              <div className="jr-card">
                <CardHeader
                  heading={<IntlMessages id="dashboard.newCustomers" />}
                  styleName="mb-2"
                />
                <UserDetailTable data={dashboardData.latestCustomers} />
              </div>
            </div>

            <div className="col-xl-8 col-md-6 col-12">
              <div className="col-lg-12">
                <div className="jr-card">
                  <div className="jr-card-header d-flex align-items-center">
                    <div className="mr-auto">
                      <h3 className="d-inline-block mb-0">
                        <IntlMessages id="table.recentOrders" />
                      </h3>
                      <span className="text-white badge badge-success">
                        <IntlMessages id="latest" />
                      </span>
                    </div>
                  </div>
                  <OrderTable data={dashboardData.latestBookings} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </HOCLoader>
    );
  }
}

const composedComponent = compose(
  connect(
    null,
    actions
  )
);
export default composedComponent(withRouter(Dashboard));

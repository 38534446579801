import React, { lazy } from "react";

const BannerTable = lazy(() => import("../../../components/MyComponents/BannerTable"));

class BannerList extends React.Component {
  render() {
    const { role, title, match } = this.props;
    return (
      <BannerTable
        headings={[
          { name: "Banner", id: "banner" },
          { name: "Title", id: "title" },
          { name: "Description", id: "description" },
          { name: "Actions", id: "action" }
        ]}
        title={title}
        match={match}
      />
    );
  }
}

export default BannerList;

import React, { lazy } from "react";

const CustomerTable = lazy(() => import("../../../components/MyComponents/CustomerTable"));
const ProviderTable = lazy(() => import("../../../components/MyComponents/ProviderTable"));

const Table = ({ match, headings, actualData, title, role, mode, refresh }) => {
  if (mode == "customer") {
    return (
      <CustomerTable
        match={match.url}
        headings={headings}
        title={title}
        role={role}
      />
    );
  } else if (mode == "provider") {
    return (
      <ProviderTable
        match={match.url}
        headings={headings}
        actualData={actualData}
        title={title}
        role={role}
      />
    );
  }
};

export default Table;

import React, { Component } from "react";
import { NavLink, withRouter, Link } from "react-router-dom";

import Button from "@material-ui/core/Button";

import IntlMessages from '../../util/IntlMessages';
import CustomScrollbars from "../../util/CustomScrollbars";

class SidenavContent extends Component {
  componentDidMount() {
    const { history } = this.props;
    const that = this;
    const pathname = `#${history.location.pathname}`; // get current path
    const subMenuLi = document.querySelectorAll(".sub-menu > li");
    for (let i = 0; i < subMenuLi.length; i++) {
      subMenuLi[i].onclick = function (event) {
        // event.stopPropagation();
      };
    }
    const menuLi = document.getElementsByClassName("menu");
    for (let i = 0; i < menuLi.length; i++) {
      menuLi[i].onclick = function (event) {
        for (let j = 0; j < menuLi.length; j++) {
          const parentLi = that.closest(this, "li");
          if (
            menuLi[j] !== this &&
            (parentLi === null || !parentLi.classList.contains("open"))
          ) {
            menuLi[j].classList.remove("open");
          } else {
          }
        }
        this.classList.add("open");
        // event.stopPropagation();
      };
    }
    const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
    try {
      const activeNav = this.closest(activeLi, "ul"); // select closest ul
      if (activeNav.classList.contains("sub-menu")) {
        this.closest(activeNav, "li").classList.add("open");
      } else {
        this.closest(activeLi, "li").classList.add("open");
      }
    } catch (error) { }
  }

  closest(el, selector) {
    try {
      let matchesFn;
      // find vendor prefix
      [
        "matches",
        "webkitMatchesSelector",
        "mozMatchesSelector",
        "msMatchesSelector",
        "oMatchesSelector"
      ].some(function (fn) {
        if (typeof document.body[fn] == "function") {
          matchesFn = fn;
          return true;
        }
        return false;
      });
      let parent;
      // traverse parents
      while (el) {
        parent = el.parentElement;
        if (parent && parent[matchesFn](selector)) {
          return parent;
        }
        el = parent;
      }
    } catch (e) { }
    return null;
  }

  render() {
    return (
      <CustomScrollbars className=" scrollbar">
        <ul className="nav-menu">
          <li className="nav-header">
            <IntlMessages id="sidebar.main" />
          </li>
          <li className="menu no-arrow">
            <NavLink to="/app/dashboard/">
              <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" />
              <span className="nav-text">
                <IntlMessages id="sidebar.dashboard" />
              </span>
            </NavLink>
          </li>
          <li className="menu collapse-box">
            <Button href="javascript:void(0)">
              <i className="zmdi zmdi-accounts" />
              <span className="nav-text">
                <IntlMessages id="sidebar.users" />
              </span>
            </Button>
            <ul className="sub-menu">
              <li>
                <Link className="prepend-icon" to="/app/user/customers">
                  <span className="nav-text">
                    <IntlMessages id="sidebar.customers" />
                  </span>
                </Link>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/user/providers">
                  <span className="nav-text text-transform-none">
                    <IntlMessages id="sidebar.providers" />
                  </span>
                </NavLink>
              </li>
            </ul>
          </li>
          <li className="menu collapse-box">
            <Button href="javascript:void(0)">
              <i className="zmdi zmdi-view-comfy" />
              <span className="nav-text">
                <IntlMessages id="sidebar.category-management" />
              </span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon" to="/app/category/list">
                  <span className="nav-text">
                    <IntlMessages id="sidebar.categories" />
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/subcategory/list">
                  <span className="nav-text text-transform-none">
                    <IntlMessages id="sidebar.subcategories" />
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/service/list">
                  <span className="nav-text text-transform-none">
                    <IntlMessages id="sidebar.services" />
                  </span>
                </NavLink>
              </li>
            </ul>
          </li>
          <li className="menu collapse-box">
            <Button href="javascript:void(0)">
              <i className="zmdi zmdi-calendar-note" />
              <span className="nav-text">
                <IntlMessages id="sidebar.booking-management" />
              </span>
            </Button>
            <ul className="sub-menu">
              <li>
                <NavLink className="prepend-icon" to="/app/booking/list">
                  <span className="nav-text">
                    <IntlMessages id="sidebar.bookings" />
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink className="prepend-icon" to="/app/transaction/list">
                  <span className="nav-text text-transform-none">
                    <IntlMessages id="sidebar.transactions" />
                  </span>
                </NavLink>
              </li>
            </ul>
          </li>
          {/* <li className="menu no-arrow">
            <NavLink to="/app/gallery/list">
              <i className="zmdi zmdi-folder-person" />
              <span className="nav-text">
                <IntlMessages id="sidebar.file-management" />
              </span>
            </NavLink>
          </li> */}
          <li className="menu no-arrow">
            <NavLink to="/app/promoCode/list">
              <i className="zmdi zmdi-card-giftcard" />
              <span className="nav-text">
                <IntlMessages id="sidebar.Promo-cards-/-offers" />
              </span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink to="/app/rating/list">
              <i className="zmdi zmdi-star" />
              <span className="nav-text">
                <IntlMessages id="sidebar.ratings-reviews" />
              </span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink to="/app/cms/list">
              <i className="zmdi zmdi-file-text" />
              <span className="nav-text">
                <IntlMessages id="sidebar.cms" />
              </span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink to="/app/helpdesk/list">
              <i className="zmdi zmdi-comments" />
              <span className="nav-text">
                <IntlMessages id="helpdesk" />
              </span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink to="/app/banner/list">
              <i className="zmdi zmdi-map" />
              <span className="nav-text">
                <IntlMessages id="banners" />
              </span>
            </NavLink>
          </li>
          <li className="menu no-arrow">
            <NavLink to="/app/settings">
              <i className="zmdi zmdi-settings" />
              <span className="nav-text">
                <IntlMessages id="sidebar.manage-settings" />
              </span>
            </NavLink>
          </li>
          {/* <li className="menu no-arrow">
            <NavLink to="/app/godsView">
              <i className="zmdi zmdi-map" />
              <span className="nav-text">
                <IntlMessages id="godsview" />
              </span>
            </NavLink>
          </li> */}
        </ul>
      </CustomScrollbars>
    );
  }
}

export default withRouter(SidenavContent);
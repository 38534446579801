import { getCmsList } from "../apiHelpers/cms/list";
import { createCms, updateCms, deleteCms } from "../apiHelpers/cms/create";
import { viewCms } from "../apiHelpers/cms/detail";

export const cmsListAction = done => async dispatch => {
  const apiData = await getCmsList();
  if (apiData.status == "OK") {
    done(true, apiData.data);
  } else {
    done(false, apiData.message);
  }
};

export const cmsCreateAction = (params, done) => async dispatch => {
  const apiData = await createCms(params);
  if (apiData.status == "OK") {
    done(true, apiData.data);
  } else {
    done(false, apiData.message);
  }
};

export const cmsDetailAction = (params, done) => async dispatch => {
  const apiData = await viewCms(params);
  if (apiData.status == "OK") {
    done(true, apiData.data);
  } else {
    done(false, apiData.message);
  }
};

export const cmsUpdateAction = (params, done) => async dispatch => {
  const apiData = await updateCms(params);
  if (apiData.status == "OK") {
    done(true, apiData.data);
  } else {
    done(false, apiData.message);
  }
};

export const deleteCMSAction = (params, done) => async dispatch => {
  const apiData = await deleteCms(params);
  if (apiData.status == "OK") {
    done(true, apiData.data);
  } else {
    done(false, apiData.message);
  }
};
import axios from "axios";
import { NotificationManager } from "react-notifications";

import {
  errorResponse,
  validateApiData,
} from "../../util/validators/validators";
import { getCookies } from "../../util/getToken";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const custCreate = async (params) => {
  let authToken = getCookies();
  try {
    const { data } = await axios({
      method: "post",
      url: `${SERVER_URL}/admin/user/customer-create`,
      data: params,
      headers: { "x-auth-token": authToken },
    });
    if (data.status == "OK") {
      NotificationManager.success(data.message, "Created!");
    } else {
      NotificationManager.error(data.message, "Oops!");
    }
    return validateApiData(data) ? data : errorResponse;
  } catch (e) {
    console.log("Error in user detail", e);
    NotificationManager.error(errorResponse.message, "Oops!");
    return errorResponse;
  }
};

export const custUpdate = async (params) => {
  let authToken = getCookies();
  try {
    const { data } = await axios({
      method: "post",
      url: `${SERVER_URL}/admin/customer/update-profile`,
      data: params,
      headers: { "x-auth-token": authToken },
    });
    if (data.status == "OK") {
      NotificationManager.success(data.message, "Updated!");
    } else {
      NotificationManager.error(data.message, "Oops!");
    }
    return validateApiData(data) ? data : errorResponse;
  } catch (e) {
    console.log("Error in update profile", e);
    NotificationManager.error(errorResponse.message, "Oops!");
    return errorResponse;
  }
};

export const proCreate = async (params, done) => {
  let authToken = getCookies();
  try {
    const { data } = await axios({
      method: "post",
      url: `${SERVER_URL}/admin/user/provider-create`,
      data: params,
      headers: { "x-auth-token": authToken },
    });
    console.log(data, "datadatadata123");
    if (data.status == "OK") {
      // NotificationManager.success(data.message, "Created!");
      done(true, data.message);
    } else {
      done(false, data.message);
      // NotificationManager.error(data.message, "Oops!");
    }
    return validateApiData(data) ? data : errorResponse;
  } catch (e) {
    console.log("Error in user detail", e);
    NotificationManager.error(errorResponse.message, "Oops!");
    return errorResponse;
  }
};

export const proUpdate = async (params) => {
  let authToken = getCookies();
  try {
    const { data } = await axios({
      method: "post",
      url: `${SERVER_URL}/admin/provider/update-profile`,
      data: params,
      headers: { "x-auth-token": authToken },
    });
    if (data.status == "OK") {
      NotificationManager.success(data.message, "Updated!");
    } else {
      NotificationManager.error(data.message, "Oops!");
    }
    return validateApiData(data) ? data : errorResponse;
  } catch (e) {
    console.log("Error in update profile", e);
    NotificationManager.error(errorResponse.message, "Oops!");
    return errorResponse;
  }
};

export const proAddPortfolio = async (params) => {
  let authToken = getCookies();
  try {
    const { data } = await axios({
      method: "post",
      url: `${SERVER_URL}/admin/provider/add-portfolio`,
      data: params,
      headers: { "x-auth-token": authToken },
    });
    if (data.status == "OK") {
      NotificationManager.success(data.message, "Updated!");
    } else {
      NotificationManager.error(data.message, "Oops!");
    }
    return validateApiData(data) ? data : errorResponse;
  } catch (e) {
    console.log("Error in update profile", e);
    NotificationManager.error(errorResponse.message, "Oops!");
    return errorResponse;
  }
};

export const proDeletePortfolio = async (params) => {
  let authToken = getCookies();
  console.log(params, 'paramsparamsparams')
  try {
    const { data } = await axios({
      method: "post",
      url: `${SERVER_URL}/admin/provider/delete-portfolio`,
      data: params,
      headers: { "x-auth-token": authToken },
    });
    if (data.status == "OK") {
      NotificationManager.success(data.message, "Updated!");
    } else {
      NotificationManager.error(data.message, "Oops!");
    }
    return validateApiData(data) ? data : errorResponse;
  } catch (e) {
    console.log("Error in update profile", e);
    NotificationManager.error(errorResponse.message, "Oops!");
    return errorResponse;
  }
};

export const adminUpdate = async (params) => {
  let authToken = getCookies();
  try {
    const { data } = await axios({
      method: "post",
      url: `${SERVER_URL}/admin/my/update`,
      data: params,
      headers: { "x-auth-token": authToken },
    });
    if (data.status == "OK") {
      NotificationManager.success(data.message, "Updated!");
    } else {
      NotificationManager.error(data.message, "Oops!");
    }
    return validateApiData(data) ? data : errorResponse;
  } catch (e) {
    console.log("Error in update profile", e);
    NotificationManager.error(errorResponse.message, "Oops!");
    return errorResponse;
  }
};

export const customerDelete = async (params) => {
  let authToken = getCookies();
  try {
    const { data } = await axios({
      method: "delete",
      url: `${SERVER_URL}/admin/customer/deleted`,
      data: params,
      headers: { "x-auth-token": authToken },
    });
    if (data.status == "OK") {
      NotificationManager.success(data.message, "Success!");
    } else if (data.status == "NOK") {
      NotificationManager.error(data.message, "Oops!");
    }
    return validateApiData(data) ? data : errorResponse;
  } catch (e) {
    console.log("Error in update profile", e);
    NotificationManager.error(errorResponse.message, "Oops!");
    return errorResponse;
  }
};

export const customerRestore = async (params) => {
  let authToken = getCookies();
  try {
    const { data } = await axios({
      method: "post",
      url: `${SERVER_URL}/admin/customer/restore`,
      data: params,
      headers: { "x-auth-token": authToken },
    });
    if (data.status == "OK") {
      NotificationManager.success(data.message, "Success!");
    } else if (data.status == "NOK") {
      NotificationManager.error(data.message, "Oops!");
    }
    return validateApiData(data) ? data : errorResponse;
  } catch (e) {
    console.log("Error in update profile", e);
    NotificationManager.error(errorResponse.message, "Oops!");
    return errorResponse;
  }
};

export const providerRestore = async (params) => {
  let authToken = getCookies();
  try {
    const { data } = await axios({
      method: "post",
      url: `${SERVER_URL}/admin/customer/restore`,
      data: params,
      headers: { "x-auth-token": authToken },
    });
    if (data.status == "OK") {
      NotificationManager.success(data.message, "Success!");
    } else if (data.status == "NOK") {
      NotificationManager.error(data.message, "Oops!");
    }
    return validateApiData(data) ? data : errorResponse;
  } catch (e) {
    console.log("Error in update profile", e);
    NotificationManager.error(errorResponse.message, "Oops!");
    return errorResponse;
  }
};

export const changeAdminPassword = async (params) => {
  let authToken = getCookies();
  try {
    const { data } = await axios({
      method: "post",
      url: `${SERVER_URL}/admin/my/change-password`,
      data: params,
      headers: { "x-auth-token": authToken },
    });
    if (data.status == "OK") {
      NotificationManager.success(data.message, "Success!");
    } else if (data.status == "NOK") {
      NotificationManager.error(data.message, "Oops!");
    }
    return validateApiData(data) ? data : errorResponse;
  } catch (e) {
    console.log("Error in update profile", e);
    NotificationManager.error(errorResponse.message, "Oops!");
    return errorResponse;
  }
};

export const payProvider = async (params) => {
  let authToken = getCookies();
  try {
    const { data } = await axios({
      method: "post",
      url: `${SERVER_URL}/admin/provider/pay`,
      data: params,
      headers: { "x-auth-token": authToken },
    });
    if (data.status == "OK") {
      NotificationManager.success(data.message, "Success!");
    } else if (data.status == "NOK") {
      NotificationManager.error(data.message, "Oops!");
    }
    return validateApiData(data) ? data : errorResponse;
  } catch (e) {
    console.log("Error in update profile", e);
    NotificationManager.error(errorResponse.message, "Oops!");
    return errorResponse;
  }
};

export const chargeList = async (params) => {
  let authToken = getCookies();
  try {
    const { data } = await axios({
      method: "post",
      url: `${SERVER_URL}/admin/provider/charges`,
      data: params,
      headers: { "x-auth-token": authToken },
    });
    if (data.status == "OK") {
      NotificationManager.success(data.message, "Success!");
    } else if (data.status == "NOK") {
      NotificationManager.error(data.message, "Oops!");
    }
    return validateApiData(data) ? data : errorResponse;
  } catch (e) {
    console.log("Error in update profile", e);
    NotificationManager.error(errorResponse.message, "Oops!");
    return errorResponse;
  }
};

export const addBankDetails = async (params) => {
  let authToken = getCookies();
  try {
    const { data } = await axios({
      method: "post",
      url: `${SERVER_URL}/admin/provider/add-bank`,
      data: params,
      headers: { "x-auth-token": authToken },
    });
    return validateApiData(data) ? data : errorResponse;
  } catch (e) {
    console.log("Error in update profile", e);
    NotificationManager.error(errorResponse.message, "Oops!");
    return errorResponse;
  }
};

export const updateFullProviderProfile = async (params) => {
  let authToken = getCookies();
  try {
    const { data } = await axios({
      method: "post",
      data: params,
      url: `${SERVER_URL}/admin/provider/update-profile`,
      headers: { "x-auth-token": authToken },
    });
    return data;
  } catch (e) {
    return e;
  }
};

export const getNationality = async () => {
  let authToken = getCookies();
  try {
    const { data } = await axios({
      method: "get",
      url: `${SERVER_URL}/nationality/list`,
      headers: { "x-auth-token": authToken },
    });
    return data;
  } catch (e) {
    return e;
  }
};

export const getLanguages = async () => {
  let authToken = getCookies();
  try {
    const { data } = await axios({
      method: "get",
      url: `${SERVER_URL}/language/list`,
      headers: { "x-auth-token": authToken },
    });
    return data;
  } catch (e) {
    return e;
  }
};
export const updateAddress = async (params) => {
  let authToken = getCookies();
  try {
    const { data } = await axios({
      method: "post",
      data: params,
      url: `${SERVER_URL}/admin/address/update`,
      // url: `https://78cd3e28ae32.ngrok.io`,
      headers: { "x-auth-token": authToken },
    });
    return validateApiData(data) ? data : errorResponse;
  } catch (e) {
    return errorResponse;
  }
};
export const addService = async (params) => {
  let authToken = getCookies();
  try {
    const { data } = await axios({
      method: "post",
      data: params,
      url: `${SERVER_URL}/admin/provider-service/create`,
      headers: { "x-auth-token": authToken },
    });
    return validateApiData(data) ? data : errorResponse;
  } catch (e) {
    return errorResponse;
  }
};
export const deleteAddress = async (params) => {
  let authToken = getCookies();
  try {
    const { data } = await axios({
      method: "delete",
      data: params,
      url: `${SERVER_URL}/admin/address/deleted`,
      headers: { "x-auth-token": authToken },
    });
    return validateApiData(data) ? data : errorResponse;
  } catch (e) {
    return errorResponse;
  }
};
export const deleteServicePackage = async (params) => {
  let authToken = getCookies();
  try {
    const { data } = await axios({
      method: "post",
      data: params,
      url: `${SERVER_URL}/admin/package/delete`,
      headers: { "x-auth-token": authToken },
    });
    return validateApiData(data) ? data : errorResponse;
  } catch (e) {
    return errorResponse;
  }
};

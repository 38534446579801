import axios from "axios";
import { NotificationManager } from "react-notifications";

import { errorResponse, validateApiData } from "../../util/validators/validators";
import { getCookies } from "../../util/getToken";

const SERVER_URL = process.env.REACT_APP_SERVER_URL

export const serviceList = async params => {
  let authToken = getCookies();
  try {
    const { data } = await axios({
      method: "get",
      url: `${SERVER_URL}/admin/service/list`,
      data: params,
      headers: { "x-auth-token": authToken }
    });
    let newData;
    if (data) {
      newData = data.data.map(item => {
        return {
          ...item,
          name: item.heading,
          categoryName: item.category.heading
        };
      });
      data.data = newData;
      return validateApiData(data) ? data : errorResponse;
    }
  } catch (e) {
    console.log("Error in service list", e);
    NotificationManager.error(errorResponse.message, "Oops!");
    return errorResponse;
  }
};

export const subcategoryList = async params => {
  let authToken = getCookies();
  try {
    const { data } = await axios({
      method: "post",
      url: `${SERVER_URL}/admin/sub-category/list`,
      params: params,
      headers: { "x-auth-token": authToken }
    });
    if (data) {
      return validateApiData(data) ? data : errorResponse;
    }
  } catch (e) {
    console.log("Error in service list", e);
    NotificationManager.error(errorResponse.message, "Oops!");
    return errorResponse;
  }
};
import React, { lazy } from "react";

const RatingTable = lazy(() => import("../../../components/MyComponents/RatingTable"));

class RatingList extends React.Component {
  render = () => {
    const { title = "Bookings", match } = this.props;

    return (
      <RatingTable
        headings={[
          { name: "AR", id: "ar" },
          { name: "R1 Parameter", id: "r1" },
          { name: "R2 Parameter", id: "r2" },
          { name: "R3 Parameter", id: "r3" },
          { name: "From", id: "name" },
          { name: "To", id: "toName" },
          { name: "Review", id: "review" },
          { name: "Status", id: "status" },
          { name: "Actions", id: "actions" }
        ]}
        title={title}
        match={match}
      />
    );
  };
}

export default RatingList;

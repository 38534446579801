import { getAllBookings, userBookingList } from "../apiHelpers/booking/all";
import { bookingDelete, bookingRestore } from "../apiHelpers/booking/create";
import { getBookingDetail } from "../apiHelpers/booking/detail";

export const bookingListAction = done => async dispatch => {
  const apiData = await getAllBookings();
  if (apiData.status == "OK") {
    done(true, apiData.data);
  } else {
    done(false, apiData.message);
  }
};

export const userBookingListAction = (params, done) => async dispatch => {
  const apiData = await userBookingList(params);
  if (apiData.status == "OK") {
    done(true, apiData.data);
  } else {
    done(false, apiData.message);
  }
};

export const bookingDetailAction = (params, done) => async dispatch => {
  const apiData = await getBookingDetail(params);
  if (apiData.status == "OK") {
    done(true, apiData.data);
  } else {
    done(false, apiData.message);
  }
};

export const deleteBookingAction = (params, done) => async dispatch => {
  const apiData = await bookingDelete(params);
  if (apiData.status == "OK") {
    done(true, apiData.data);
  } else {
    done(false, apiData.message);
  }
};

export const restoreBookingAction = (params, done) => async dispatch => {
  const apiData = await bookingRestore(params);
  if (apiData.status == "OK") {
    done(true, apiData.data);
  } else {
    done(false, apiData.message);
  }
};
import React, { lazy } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import IntlMessages from '../../../util/IntlMessages';
import CircularProgress from "@material-ui/core/CircularProgress";
import { validateHelpdeskReplyForm } from "../../../util/validators/validateForm";
import { NotificationManager } from "react-notifications";
import * as actions from "../../../actions";
import { connect } from "react-redux";
import "react-image-crop/dist/ReactCrop.css";
import moment from "moment";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
let id = 0;

const ContainerHeader = lazy(() => import("../../../components/ContainerHeader/index"));
const CardBox = lazy(() => import("../../../components/CardBox/index"));
const HOCLoader = lazy(() => import("../../../components/MyComponents/HOCLoader"));

function createData(name, calories, fat, carbs, protein) {
  id += 1;
  return { id, name, calories, fat, carbs, protein };
}

const data = [
  createData("Frozen yoghurt", 159, 6.0, 24, 4.0),
  createData("Ice cream sandwich", 237, 9.0, 37, 4.3),
  createData("Eclair", 262, 16.0, 24, 6.0),
  createData("Cupcake", 305, 3.7, 67, 4.3),
  createData("Gingerbread", 356, 16.0, 49, 3.9)
];

class Reply extends React.Component {
  constructor(props) {
    super();
    this.state = {
      error: "",
      descriptionError: "",
      description: "",
      loader: true,
      loaderButton: false,
      noResponse: false,
      errorMessage: "",
      helpdeskData: "",
      itemId: props.location.state ? props.location.state.itemId : ""
    };
  }
  componentDidMount() {
    if (this.props.location.state) {
      this.getData();
    } else {
      this.props.history.push("/app/helpdesk/list");
    }
  }
  getData = () => {
    this.props.helpdeskViewAction(
      { helpdeskId: this.state.itemId },
      this.onDataDone
    );
  };
  onDataDone = (success, data) => {
    if (success) {
      this.setState({ helpdeskData: data, loader: false });
    } else {
      this.setState({ loader: false, noResponse: true, error: data });
    }
  };

  submit = () => {
    const { description } = this.state;
    if (validateHelpdeskReplyForm({ description }, this.handleError)) {
      this.setState({ loaderButton: true });
      this.props.helpdeskReplyAction(
        {
          description,
          id: this.state.itemId
        },
        this.onDone
      );
    } else {
      NotificationManager.error("Validation Error", "Oops!");
    }
  };
  handleError = params => {
    this.setState(params);
  };

  onDone = (success, data) => {
    if (success) {
      this.setState({ loaderButton: false });
      this.props.history.push("/app/helpdesk/list");
    }
  };
  renderButton = () => {
    if (this.state.loaderButton) {
      return <CircularProgress />;
    }
    return (
      <Button
        variant="raised"
        color="primary"
        className="jr-btn"
        onClick={this.submit}
      >
        Reply
      </Button>
    );
  };

  render() {
    const { match } = this.props;
    const {
      error,
      description,
      descriptionError,
      loaderButton,
      helpdeskData
    } = this.state;
    return (
      <HOCLoader
        loading={this.state.loader}
        noResponse={this.state.noResponse}
        redirect={this.state.redirect}
        errorShow={error}
      >
        <div className="animated slideInUpTiny animation-duration-3">
          <ContainerHeader
            title={<IntlMessages id="helpdesk" />}
            match={match}
          />

          <div className="row">
            <CardBox styleName="col-lg-12">
              <form
                className="row"
                noValidate
                autoComplete="off"
                id="customer-create"
              >
                <div className="col-md-3 col-xs-12">
                  <h4>
                    <b>Category</b>
                  </h4>
                  <div className="badge text-uppercase text-white bg-primary ">
                    {helpdeskData.category}
                  </div>
                </div>
                <div className="col-md-9 col-xs-12">
                  <h4>
                    <b>Ticket Description</b>
                  </h4>
                  {helpdeskData.description}
                </div>

                {helpdeskData.thread && (
                  <div className="col-md-12 col-xs-12">
                    <h4>
                      <b>Message Thread</b>
                    </h4>

                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Reply By</TableCell>
                          <TableCell>Message</TableCell>
                          <TableCell>Created At</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {helpdeskData.thread.map(n => {
                          return (
                            <TableRow key={n.id}>
                              <TableCell>{n.name}</TableCell>
                              <TableCell>{n.description}</TableCell>
                              <TableCell>
                                {moment().format(
                                  "DD-MMM-Y hh:mm:ss a",
                                  n.created_at
                                )}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </div>
                )}

                <div className="col-md-12 col-xs-12">
                  <TextField
                    error={descriptionError ? true : false}
                    helperText={descriptionError}
                    type="text"
                    name="description"
                    label="Add Reply"
                    id="description"
                    fullWidth
                    placeholder="Add Reply"
                    InputLabelProps={{
                      shrink: true
                    }}
                    onChange={event => {
                      this.setState({
                        description: event.target.value,
                        descriptionError: "",
                        error: ""
                      });
                    }}
                    margin="normal"
                    disabled={loaderButton ? true : false}
                  />
                </div>
                <div className="col-md-12 col-xs-12">
                  <div className="mt-3" />
                  {this.renderButton()}
                </div>
              </form>
            </CardBox>
          </div>
        </div>
      </HOCLoader>
    );
  }
}

export default connect(
  null,
  actions
)(Reply);

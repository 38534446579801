import React from 'react';
import PropTypes from "prop-types";

const Select = props => {
    const {
        name,
        id,
        onChange,
        onBlur,
        value,
        disabled,
        className,
        style,
        children
    } = props;
    return (
        <select
            name={name}
            id={id}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            disabled={disabled}
            class={className}
            style={style}
        >
            {children}
        </select>
    );
};

Select.defaultProps = {
    name: '',
    disabled: false,
};

Select.propTypes = {
    name: PropTypes.string,
    id: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    value: PropTypes.any,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    style: PropTypes.object,
};

export default Select;
import React, { lazy } from "react";

const CMSTable = lazy(() => import("../../../components/MyComponents/CMSTable"));

const CMSList = () => {
  return (
    <div className="w-100">
      <CMSTable
        headings={[
          { name: "Title", id: "title" },
          { name: "SEO Meta Title", id: "metaTitle" },
          { name: "SEO Meta Keywords", id: "metaKeyword" },
          { name: "SEO Meta Description", id: "metaDescription" },
          { name: "Showing On", id: "showingon" },
          { name: "Status", id: "status" },
          { name: "Actions", id: "actions" }
        ]}
      />
    </div>
  );
};

export default CMSList;

import React, { lazy } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import IntlMessages from '../../../util/IntlMessages';
import CircularProgress from "@material-ui/core/CircularProgress";
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/VisibilityOutlined';
import VisibilityOff from '@material-ui/icons/VisibilityOffOutlined';
import { validateAdminChangePasswordForm } from "../../../util/validators/validateForm";
import { NotificationManager } from "react-notifications";
import * as actions from "../../../actions";
import { connect } from "react-redux";
import "react-image-crop/dist/ReactCrop.css";

const ContainerHeader = lazy(() => import("../../../components/ContainerHeader/index"));
const CardBox = lazy(() => import("../../../components/CardBox/index"));
const HOCLoader = lazy(() => import("../../../components/MyComponents/HOCLoader"));

class ChangePassword extends React.Component {
  constructor(props) {
    super();
    this.state = {
      error: "",
      passwordError: "",
      newPasswordError: "",
      confirmPasswordError: "",
      password: "",
      newPassword: "",
      confirmPassword: "",
      loader: false,
      loaderButton: false,
      noResponse: false,
      errorMessage: "",
      showOldPassword: false,
      showNewPassword: false,
      showConfirmPassword: false
    };
  }

  submit = params => {
    const { password, newPassword, confirmPassword } = this.state;
    if (
      validateAdminChangePasswordForm(
        { password, newPassword, confirmPassword },
        this.handleError
      )
    ) {
      this.setState({ loaderButton: true });
      this.props.adminChangePasswordAction(
        {
          password,
          newPassword,
          confirmPassword
        },
        this.onDone
      );
    } else {
      NotificationManager.error("Validation Error", "Oops!");
    }
  };
  handleError = params => {
    this.setState(params);
  };

  onDone = (success, data) => {
    this.setState({ loaderButton: false });
  };
  renderButton = () => {
    if (this.state.loaderButton) {
      return <CircularProgress />;
    }
    return (
      <Button
        variant="raised"
        color="primary"
        className="jr-btn"
        onClick={this.submit}
      >
        Change Password
      </Button>
    );
  };
  handleClickShowOldPassword = () => {
    {
      this.state?.showOldPassword === false ?
        this.setState({ showOldPassword: true })
        :
        this.setState({ showOldPassword: false })
    }
  }
  handleClickShowNewPassword = () => {
    {
      this.state?.showNewPassword === false ?
        this.setState({ showNewPassword: true })
        :
        this.setState({ showNewPassword: false })
    }
  }
  handleClickShowConfirmPassword = () => {
    {
      this.state?.showConfirmPassword === false ?
        this.setState({ showConfirmPassword: true })
        :
        this.setState({ showConfirmPassword: false })
    }
  }
  render() {
    const { match } = this.props;
    const {
      error,
      passwordError,
      newPasswordError,
      confirmPasswordError,
      loaderButton,
      showOldPassword,
      showNewPassword,
      showConfirmPassword
    } = this.state;
    return (
      <HOCLoader
        loading={this.state.loader}
        noResponse={this.state.noResponse}
        redirect={this.state.redirect}
        errorShow={error}
      >
        <div className="animated slideInUpTiny animation-duration-3">
          <ContainerHeader
            title={<IntlMessages id="changepassword" />}
            match={match}
          />

          <div className="row">
            <CardBox styleName="col-lg-4">
              <form
                className="row"
                noValidate
                autoComplete="off"
                id="customer-create"
              >
                <div className="col-md-12 col-xs-12">
                  <TextField
                    error={passwordError ? true : false}
                    helperText={passwordError}
                    type={showOldPassword ? "text" : "password"}
                    name="password"
                    label="Old Password"
                    id="password"
                    fullWidth
                    placeholder="Add Old Password"
                    InputLabelProps={{
                      shrink: true
                    }}
                    margin="normal"
                    onChange={event => {
                      this.setState({
                        password: event.target.value,
                        passwordError: "",
                        error: ""
                      });
                    }}
                    disabled={loaderButton ? true : false}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <IconButton
                            className="passwordIcon"
                            aria-label="toggle password visibility"
                            onClick={this.handleClickShowOldPassword}
                          >
                            {showOldPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </div>
                <div className="col-md-12 col-xs-12">
                  <TextField
                    error={newPasswordError ? true : false}
                    helperText={newPasswordError}
                    type={showNewPassword ? "text" : "password"}
                    name="newPassword"
                    label="New Password"
                    id="newPassword"
                    fullWidth
                    placeholder="Add New Password"
                    InputLabelProps={{
                      shrink: true
                    }}
                    onChange={event => {
                      this.setState({
                        newPassword: event.target.value,
                        newPasswordError: "",
                        error: ""
                      });
                    }}
                    margin="normal"
                    disabled={loaderButton ? true : false}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <IconButton
                            className="passwordIcon"
                            aria-label="toggle password visibility"
                            onClick={this.handleClickShowNewPassword}
                          >
                            {showNewPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </div>
                <div className="col-md-12 col-xs-12">
                  <TextField
                    error={confirmPasswordError ? true : false}
                    helperText={confirmPasswordError}
                    type={showConfirmPassword ? "text" : "password"}
                    name="confirmPassword"
                    label="Confirm Password"
                    id="confirmPassword"
                    fullWidth
                    placeholder="Add Confirm Password"
                    InputLabelProps={{
                      shrink: true
                    }}
                    onChange={event => {
                      this.setState({
                        confirmPassword: event.target.value,
                        confirmPasswordError: "",
                        error: ""
                      });
                    }}
                    margin="normal"
                    disabled={loaderButton ? true : false}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment>
                          <IconButton
                            className="passwordIcon"
                            aria-label="toggle password visibility"
                            onClick={this.handleClickShowConfirmPassword}
                          >
                            {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      )
                    }}
                  />
                </div>
                <div className="col-md-12 col-xs-12">
                  <div className="mt-3" />
                  {this.renderButton()}
                </div>
              </form>
            </CardBox>
          </div>
        </div>
      </HOCLoader>
    );
  }
}

export default connect(
  null,
  actions
)(ChangePassword);

import { getList, getAllTransactions } from "../apiHelpers/transaction/list";

export const customerTransactionListAction = (
  params,
  done
) => async dispatch => {
  const list = await getList(params);
  if (list.status == "OK") {
    done(true, list.data);
  } else {
    done(false, list.message);
  }
};

export const transactionListAction = done => async dispatch => {
  const list = await getAllTransactions();
  if (list.status == "OK") {
    done(true, list.data);
  } else {
    done(false, list.message);
  }
};
import React, { Fragment } from "react";
import * as actions from "../../actions";
import { connect } from "react-redux";
import { compose } from "redux";
import { withStyles } from "@material-ui/core/styles";
import { injectIntl } from "react-intl";
import { isMobile } from "react-device-detect";
import ReactPlayer from "react-player";
import { Formik } from "formik";
import { NotificationManager } from "react-notifications";
import Select from "../Select/Select";
import Option from "../Select/Option";
import axios from "axios";
import PortfolioGallery from "./PortfolioGallery";
import { getCookies } from "../../util/getToken";

import "../../styles/customStyle.scss";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: "white",
  },
});

class ProviderPortfolio extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      genersData: [],
      showList: true,
      slectGener: "",
      selectedGenere: 0,
      selectedGenereName: "",
      selectedGenereObj: null,
      selectedGenereCategoryId: "",
      isEditable: false,
      portfolios: [{}],
      categoryIds: [],
    };
  }

  async componentDidMount() {
    const { providerDetail } = this.props;
    const providerTypeId =
      providerDetail &&
      providerDetail.providerTypes &&
      providerDetail.providerTypes[0] &&
      providerDetail.providerTypes[0].providerType &&
      providerDetail.providerTypes[0].providerType.id;
    this.getGeneresData(providerTypeId);
    this.setState({
      selectedGenereCategoryId: providerTypeId,
    });
  }

  getGeneresData = (categoryId) => {
    const { providerDetail } = this.props;
    axios({
      method: "GET",
      data: {
        providerType: categoryId,
        createdBy: providerDetail && providerDetail.id,
      },
      url: `${SERVER_URL}/provider/list-genere?providerType=${categoryId}&createdBy=${
        providerDetail && providerDetail.id
      }`,
      headers: { "x-auth-token": getCookies() },
    })
      .then(
        (response) => {
          if (response.data && response.data.status === "OK") {
            if (response.data.data && response.data.data.length > 0) {
              this.setState({
                genersData: response.data.data,
                selectedGenereObj: response.data.data[0],
                selectedGenere: response.data.data[0].id,
                selectedGenereName: response.data.data[0].generName,
              });
            } else {
              this.setState({
                genersData: [],
                selectedGenereObj: null,
                selectedGenere: 0,
                selectedGenereName: "",
              });
            }
          }
        },
        function (err) {
          console.log("axios error", err);
        }
      )
      .catch((err) => {
        console.log("axios error catch", err);
      });
  };

  addGenereAction = (value, id) => {
    const { providerDetail } = this.props;
    if (!value) {
      NotificationManager.error("Select another gener to add", "Error");
      return false;
    }
    axios({
      method: "POST",
      data: {
        providerType: this.state.selectedGenereCategoryId,
        portfolioGenreName: value,
        providerId: providerDetail && providerDetail.id,
      },
      url: `${SERVER_URL}/admin/provider/add-genere`,
      headers: { "x-auth-token": getCookies() },
    }).then((response) => {
      console.log(response, "response");
      NotificationManager.success(response.data.message, "Success");
      if (response.data && response.data.status === "OK") {
        this.setState({
          genersData: response.data.data,
          showList: true,
          selectedGenereObj: response.data.data[response.data.data.length - 1],
          selectedGenere: response.data.data[response.data.data.length - 1].id,
          selectedGenereName:
            response.data.data[response.data.data.length - 1].generName,
        });
      } else if (response.data && response.data.status === "NOK") {
        this.setState({
          showList: true,
          // selectedGenereObj: null,
          // selectedGenere: id,
          // selectedGenereName: value,
        });
      } else {
        this.setState({
          selectedGenereObj: null,
          selectedGenere: 0,
          selectedGenereName: "",
        });
      }
    });
  };

  removeGenere = (id, generName) => {
    const { categoryIds } = this.state;
    const { providerDetail } = this.props;
    axios({
      method: "POST",
      data: {
        providerType: this.state.selectedGenereCategoryId,
        genereId: id,
        providerId: providerDetail && providerDetail.id,
      },
      url: `${SERVER_URL}/admin/provider/delete-genere?providerType=${
        this.state.selectedGenereCategoryId
      }&genereId=${id}?providerId=${providerDetail && providerDetail.id}`,
      headers: { "x-auth-token": getCookies() },
    }).then((response) => {
      NotificationManager.success(response.data.message, "Success");
      if (response.data && response.data.status === "OK") {
        if (response.data.data && response.data.data.length > 0) {
          this.setState(
            {
              [`genersData`]: response.data.data,
              [`selectedGenereObj`]: response.data.data[
                response.data.data.length - 1
              ],
              [`selectedGenere`]: response.data.data[
                response.data.data.length - 1
              ].id,
              [`selectedGenereName`]: response.data.data[
                response.data.data.length - 1
              ].generName,
              [`allSelectedGenre`]:
                this.state[`allSelectedGenre`] &&
                this.state[`allSelectedGenre`].filter(
                  (grn) => grn.genreName !== generName
                ),
            },
            () => {
              console.log(this.state[`genersData`], "YAY");
              if (!this.state[`genersData`].length) {
                this.state[`portfolios`].pop();
                this.setState({
                  portfolios: this.state["portfolios"],
                  categoryIds:
                    categoryIds &&
                    categoryIds.filter(
                      (cat) => cat !== this.state[`selectedGenereCategoryId`]
                    ),
                });
              }
            }
          );
        } else {
          this.setState(
            {
              [`selectedGenereObj`]: null,
              [`selectedGenere`]: 0,
              [`selectedGenereName`]: "",
              [`allSelectedGenre`]:
                this.state[`allSelectedGenre`] &&
                this.state[`allSelectedGenre`].filter(
                  (grn) => grn.genreName !== generName
                ),
            },
            () => {
              console.log(this.state[`genersData`], "YAY");
              if (
                this.state[`genersData`] &&
                !this.state[`genersData`].length
              ) {
                this.state[`portfolios`].pop();
                this.setState({
                  portfolios: this.state["portfolios"],
                  categoryIds:
                    categoryIds &&
                    categoryIds.filter(
                      (cat) => cat !== this.state[`selectedGenereCategoryId`]
                    ),
                });
              }
            }
          );
        }
      }
    });
  };

  getSpecificGeneresData = (id) => {
    const { providerDetail } = this.props;
    axios({
      method: "GET",
      data: { providerType: this.state.selectedGenereCategoryId },
      url: `${SERVER_URL}/provider/list-genere?providerType=${
        this.state.selectedGenereCategoryId
      }&genereId=${id}&createdBy=${providerDetail && providerDetail.id}`,
      headers: { "x-auth-token": getCookies() },
    })
      .then(
        (response) => {
          if (response.data && response.data.status === "OK") {
            if (response.data.data && response.data.data.length > 0) {
              this.setState({
                selectedGenereObj: response.data.data[0],
                selectedGenere: response.data.data[0].id,
                selectedGenereName: response.data.data[0].generName,
              });
            } else {
              this.setState({
                selectedGenereObj: null,
              });
            }
          }
        },
        function (err) {
          console.log("axios error", err);
        }
      )
      .catch((err) => {
        console.log("axios error catch", err);
      });
  };

  render() {
    const { providerDetail, editable, allSelectedCategory } = this.props;
    const {
      genersData,
      selectedCategory,
      selectedGenere,
      selectedGenereName,
      selectedGenereCategoryId,
    } = this.state;
    const filterVideoLinks = genersData.filter(
      (item) => item.id === selectedGenere
    );
    return (
      <Formik
        initialValues={{
          // ...rest
          profession:
            providerDetail &&
            providerDetail.providerTypes &&
            providerDetail.providerTypes[0] &&
            providerDetail.providerTypes[0].providerType &&
            providerDetail.providerTypes[0].providerType.id,
          genere: "",
        }}
        enableReinitialize
        onSubmit={(values) => {}}
      >
        {(props) => (
          <form className="dialog-form dialog-form-new" autoComplete="off">
            <div className="row col-md-12 p-0">
              {providerDetail &&
              providerDetail.providerTypes &&
              providerDetail.providerTypes.length ? (
                <div
                  className={`col-md-4 col-xs-12 mb-3 mt-2 ${
                    !isMobile && "pr-0 pl-30"
                  }`}
                >
                  <span className="w-100 profile-header-label mb-0 pl-0">
                    Select Profession & Genre for portfolio images & videos
                  </span>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                    }}
                  >
                    <Select
                      name="profession"
                      id="profession"
                      className="form-control form-control-select w-100 mt-20"
                      onChange={(e) => {
                        this.getGeneresData(e.target.value);
                        props.setFieldValue("profession", e.target.value);
                        this.setState({
                          selectedGenereCategoryId: e.target.value,
                        });
                      }}
                      value={props.values.profession}
                      disabled={false}
                    >
                      {providerDetail &&
                        providerDetail.providerTypes &&
                        providerDetail.providerTypes.map((item) => {
                          const { providerType } = item;
                          return (
                            providerType && (
                              <Option value={providerType.id}>
                                {providerType.name}
                              </Option>
                            )
                          );
                        })}
                    </Select>
                    &nbsp;&nbsp;
                    <Select
                      name="genere"
                      id="genere"
                      className="form-control form-control-select w-100 mt-20"
                      onChange={(e) => {
                        console.log(e.target.value, "e.target.value");
                        props.setFieldValue("genere", e.target.value);
                        this.addGenereAction(
                          e.target.value.split("-")[0],
                          e.target.value.split("-")[1]
                        );
                      }}
                      value={props.values.genere}
                      disabled={false}
                    >
                      {providerDetail &&
                        providerDetail.providerTypes &&
                        providerDetail.providerTypes.length &&
                        providerDetail.providerTypes
                          .filter(
                            (item) =>
                              item.providerType.id === selectedGenereCategoryId
                          )
                          .map((item) => {
                            const { subcategories } = item;
                            return (
                              subcategories &&
                              subcategories.length &&
                              subcategories.map((genre) => {
                                return (
                                  genre && (
                                    <Option value={`${genre.name}-${genre.id}`}>
                                      {genre.name}
                                    </Option>
                                  )
                                );
                              })
                            );
                          })}
                    </Select>
                  </div>
                </div>
              ) : (
                <h2 className="ml-10 mt-30">No portfolio found</h2>
              )}
              <span className="w-100"></span>

              {console.log(selectedGenere, "selectedGenere")}
              {genersData &&
                genersData.map((selectGenere) => {
                  return (
                    <div className="col-md-2 pb-2 mt-20" key={selectGenere.id}>
                      {console.log(selectGenere, "selectGenere")}
                      <div
                        className={`genere-btn ${
                          selectedGenere === selectGenere.id ? "active" : ""
                        }`}
                        onClick={() => {
                          this.getSpecificGeneresData(selectGenere.id);
                          this.setState({
                            selectedGenere: selectGenere.id,
                            selectedGenereName: selectGenere.generName,
                            selectedGenereObj: selectGenere,
                          });
                        }}
                      >
                        {selectGenere.generName}
                        <div
                          class={`${isMobile ? "cross2-mobile" : "cross"} ${
                            selectGenere.id === this.state[`selectedGenere`]
                              ? "active"
                              : ""
                          }`}
                          onClick={() => {
                            this.removeGenere(
                              selectGenere.id,
                              selectGenere.generName
                            );
                            this.state[`genersData`].length === 1 &&
                              this.setState({
                                [`genersData`]: [],
                              });
                          }}
                        >
                          X
                        </div>
                      </div>
                    </div>
                  );
                })}
              <span className="w-100"></span>

              {filterVideoLinks &&
              filterVideoLinks[0]?.images &&
              filterVideoLinks[0]?.images.length &&
              !editable ? (
                <span className="w-100 profile-header-label mb-0 pl-0">
                  Images
                </span>
              ) : (
                <></>
              )}

              <PortfolioGallery
                providerTypeId={this.state[`selectedGenereCategoryId`]}
                providerId={providerDetail && providerDetail.id}
                providerTypeProfileData={this.state[`selectedGenereObj`]}
                selectedGenereId={this.state[`selectedGenere`]}
                selectedGenereName={this.state[`selectedGenereName`]}
                isEditable={editable ? true : false}
              />

              {!editable && (
                <div style={{ display: "flex", flexDirection: "column" }}>
                  {filterVideoLinks &&
                  filterVideoLinks[0]?.videos &&
                  filterVideoLinks[0]?.videos.length &&
                  !editable ? (
                    <span className="w-100 profile-header-label mb-20 pl-0">
                      Videos
                    </span>
                  ) : (
                    <></>
                  )}

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: editable && 20,
                    }}
                  >
                    {filterVideoLinks &&
                    filterVideoLinks[0]?.videos &&
                    filterVideoLinks[0]?.videos.length ? (
                      filterVideoLinks[0]?.videos.map((link) => {
                        return (
                          <ReactPlayer
                            className="mr-2"
                            controls
                            width="250px"
                            height="180px"
                            url={link}
                          />
                        );
                      })
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              )}
            </div>
          </form>
        )}
      </Formik>
    );
  }
}

const mapStateToProps = ({ admin }) => {
  return {
    providerDetail: admin.providerDetail,
  };
};

const composedHOC = compose(
  withStyles(styles),
  connect(mapStateToProps, actions)
);

export default composedHOC(injectIntl(ProviderPortfolio));

import {
  createNotificationSettings,
} from "../apiHelpers/notification/create";

export const notificationSettingCreateAction = (
  params,
  done
) => async dispatch => {
  const list = await createNotificationSettings(params);
  if (list.status == "OK") {
    done(true, list.data, list.message);
  } else {
    done(false, list.message);
  }
};
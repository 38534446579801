import React, { lazy } from "react";

const SubcategoryTable = lazy(() => import("../../../components/MyComponents/SubcategoryTable"));

class SubcategoryList extends React.Component {
  render = () => {
    const { title = "subcategories", match } = this.props;

    return (
      <SubcategoryTable
        headings={[
          { name: "Image", id: "image" },
          { name: "Name", id: "heading" },
          { name: "Category", id: "categoryName" },
          { name: "Status", id: "status" },
          { name: "Deleted?", id: "deleted_at" },
          { name: "Actions", id: "actions" }
        ]}
        title={title}
        match={match}
      />
    );
  };
}

export default SubcategoryList;

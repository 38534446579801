import React, { lazy } from "react";

const HelpdeskTable = lazy(() => import("../../../components/MyComponents/HelpdeskTable"));

class HelpdeskList extends React.Component {
  render = () => {
    const { title = "Helpdesk", match } = this.props;

    return (
      <HelpdeskTable
        headings={[
          { name: "Category", id: "category" },
          { name: "Description", id: "description" },
          { name: "Status", id: "status" },
          { name: "Type", id: "type_id" },
          { name: "Created At", id: "created_at" },
          { name: "Actions", id: "actions" }
        ]}
        title={title}
        match={match}
      />
    );
  };
}

export default HelpdeskList;

import {
  customerList,
  getLocationUsers,
  getBusinessHour,
} from "../apiHelpers/user/list";
import { logoutApi } from "../apiHelpers/user/logout";
import { loginApi } from "../apiHelpers/user/login";
import {
  customerDetail,
  providerDetail,
  adminDetail,
  customerDashboard,
  providerDashboard,
  checkAuth,
  getDashboardData,
} from "../apiHelpers/user/detail";
import { getBookingList } from "../apiHelpers/booking/list";
import {
  custCreate,
  custUpdate,
  proUpdate,
  proAddPortfolio,
  proDeletePortfolio,
  adminUpdate,
  customerDelete,
  customerRestore,
  providerRestore,
  changeAdminPassword,
  payProvider,
  chargeList,
  addBankDetails,
  updateFullProviderProfile,
  getNationality,
  getLanguages,
  updateAddress,
  addService,
  deleteAddress,
  deleteServicePackage,
} from "../apiHelpers/user/create";

import {
  ADMIN_INFO,
  LOADING_ADMIN,
  PROVIDER_DETAILS,
  PROVIDER_DASHBOARD_DETAILS,
  GET_NATIONALITY_DATA,
  GET_LANGUAGES_DATA,
} from "../constants/ActionTypes";

export const customerListAction = (params, done) => async (dispatch) => {
  const list = await customerList(params);
  if (list.status == "OK") {
    done(true, list);
  } else {
    done(false, list.message);
  }
};

export const providerListAction = (params, done) => async (dispatch) => {
  const list = await customerList(params);
  // console.log('***list***', list);
  if (list.status == "OK") {
    done(true, list);
  } else {
    done(false, list.message);
  }
};

export const customerDetailAction = (params, done) => async (dispatch) => {
  const detail = await customerDetail(params);
  if (detail.status == "OK") {
    done(true, detail.data);
  } else {
    done(false, detail.message);
  }
};

export const providerDetailAction = (params, done) => async (dispatch) => {
  const detail = await providerDetail(params);
  if (detail.status == "OK") {
    done(true, detail.data);
    dispatch({ type: PROVIDER_DETAILS, payload: detail.data });
  } else {
    done(false, detail.message);
  }
};

export const customerBookingListAction = (params, done) => async (dispatch) => {
  const list = await getBookingList(params);
  if (list.status == "OK") {
    done(true, list.data);
  } else {
    done(false, list.message);
  }
};

export const customerCreateAction = (params, done) => async (dispatch) => {
  const list = await custCreate(params);
  if (list.status == "OK") {
    done(true, list.data);
  } else {
    done(false, list.message);
  }
};

export const customerUpdateAction = (params, done) => async (dispatch) => {
  const list = await custUpdate(params);
  if (list.status == "OK") {
    done(true, list.data);
  } else {
    done(false, list.message);
  }
};

export const providerUpdateAction = (params, done) => async (dispatch) => {
  const list = await proUpdate(params);
  if (list.status == "OK") {
    done(true, list.data);
  } else {
    done(false, list.message);
  }
};

export const providerAddPortfolioAction = (params, done) => async (
  dispatch
) => {
  const list = await proAddPortfolio(params);
  if (list.status == "OK") {
    done(true, list.data);
  } else {
    done(false, list.message);
  }
};

export const providerDeletePortfolioAction = (params, done) => async (
  dispatch
) => {
  const list = await proDeletePortfolio(params);
  if (list.status == "OK") {
    done(true, list.data);
  } else {
    done(false, list.message);
  }
};

export const locationUserList = (params, done) => async (dispatch) => {
  const list = await getLocationUsers(params);
  if (list.status == "OK") {
    done(true, list.data);
  } else {
    done(false, list.message);
  }
};

export const adminProfileAction = () => async (dispatch) => {
  dispatch({ type: LOADING_ADMIN, payload: true });
  const detail = await adminDetail();
  if (detail.status == "OK") {
    dispatch({ type: ADMIN_INFO, payload: detail.data });
    dispatch({ type: LOADING_ADMIN, payload: false });
  }
};

export const adminInfoAction = (done) => async (dispatch) => {
  const detail = await adminDetail();
  if (detail.status == "OK") {
    done(true, detail.data);
  } else {
    done(false, detail.message);
  }
};

export const logoutAction = (done) => async (dispatch) => {
  const detail = await logoutApi();
  if (detail.status == "OK") {
    done(true, detail.data);
  } else {
    done(false, detail.message);
  }
};

export const loginAction = (params, done) => async (dispatch) => {
  const detail = await loginApi(params);
  if (detail.status == "OK") {
    done(true, detail.data);
  } else {
    done(false, detail.message);
  }
};

export const adminUpdateAction = (params, done) => async (dispatch) => {
  const list = await adminUpdate(params);
  if (list.status == "OK") {
    setTimeout(() => {
      dispatch(adminProfileAction());
    }, 1000);
    done(true, list.data);
  } else {
    done(false, list.message);
  }
};

export const deleteCustomerAction = (params, done) => async (dispatch) => {
  const list = await customerDelete(params);
  if (list.status == "OK") {
    done(true, list.data);
  } else {
    done(false, list.message);
  }
};

export const restoreCustomerAction = (params, done) => async (dispatch) => {
  const list = await customerRestore(params);
  if (list.status == "OK") {
    done(true, list.data);
  } else {
    done(false, list.message);
  }
};

export const restoreProviderAction = (params, done) => async (dispatch) => {
  const list = await providerRestore(params);
  if (list.status == "OK") {
    done(true, list.data);
  } else {
    done(false, list.message);
  }
};

export const customerDashboardAction = (params, done) => async (dispatch) => {
  const list = await customerDashboard(params);
  if (list.status == "OK") {
    done(true, list.data);
  } else {
    done(false, list.message);
  }
};

export const providerDashboardAction = (params, done) => async (dispatch) => {
  const list = await providerDashboard(params);
  if (list.status == "OK") {
    done(true, list.data);
    dispatch({ type: PROVIDER_DASHBOARD_DETAILS, payload: list.data });
  } else {
    done(false, list.message);
  }
};

export const businessHourAction = (params, done) => async (dispatch) => {
  const list = await getBusinessHour(params);
  if (list.status == "OK") {
    done(true, list.data);
  } else {
    done(false, list.message);
  }
};

export const adminChangePasswordAction = (params, done) => async (dispatch) => {
  const list = await changeAdminPassword(params);
  if (list.status == "OK") {
    done(true, list.data);
  } else {
    done(false, list.message);
  }
};

export const checkAuthAction = (done) => async (dispatch) => {
  const data = await checkAuth();
  done(data.status, data);
};

export const getDashboardDataAction = (done) => async (dispatch) => {
  const response = await getDashboardData();
  if (response.status == "OK") {
    done(true, response.data);
  } else {
    done(false, response.message);
  }
};

export const payProviderAction = (params, done) => async (dispatch) => {
  const response = await payProvider(params);
  if (response.status == "OK") {
    done(true, response.data);
  } else {
    done(false, response.message);
  }
};

export const chargeListAction = (params, done) => async (dispatch) => {
  const response = await chargeList(params);
  if (response.status == "OK") {
    done(true, response.data);
  } else {
    done(false, response.message);
  }
};

export const addBankDetailsAction = (params, done) => async (dispatch) => {
  const response = await addBankDetails(params);
  if (response.status == "OK") {
    done(true, response.data);
  } else {
    done(false, response.message);
  }
};

export const updateFullProviderProfileAction = (params, done) => async (
  dispatch
) => {
  const response = await updateFullProviderProfile(params);
  if (response && response[0]) {
    done(true);
  } else {
    done(false);
  }
};

export const getNationalityAction = () => async (dispatch) => {
  const response = await getNationality();
  if (response) {
    dispatch({
      type: GET_NATIONALITY_DATA,
      payload: response && response.data,
    });
  }
};

export const getLanguagesAction = () => async (dispatch) => {
  const response = await getLanguages();
  if (response) {
    dispatch({ type: GET_LANGUAGES_DATA, payload: response && response.data });
  }
};
export const updateAddressAction = (params, done) => async (dispatch) => {
  const response = await updateAddress(params);
  if (response.status === "OK") {
    done(true, response);
  } else {
    done(false, response.message);
  }
};
export const addServiceAction = (params, done) => async (dispatch) => {
  try {
    const response = await addService(params);
    if (response.status === "OK") {
      done(true, response);
    } else {
      done(false, response);
    }
  } catch (e) {
    done(false, "Something error");
  }
};
export const deleteAddressAction = (params, done) => async (dispatch) => {
  const response = await deleteAddress(params);
  if (response.status === "OK") {
    // dispatch(getLoggedInUserAction());
    done(true, response.data);
  } else {
    done(false, response.message);
  }
};
export const deleteServicePackageAction = (params, done) => async (
  dispatch
) => {
  try {
    const response = await deleteServicePackage(params);
    if (response.status === "OK") {
      // dispatch(getProviderServicesAction());
      done(true, response);
    } else {
      done(false, response);
    }
  } catch (e) {
    done(false, "Unknown Error Occurred");
  }
};

import React, { lazy } from "react";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import IntlMessages from '../../../util/IntlMessages';
import CircularProgress from "@material-ui/core/CircularProgress";
import { custCreate } from "../../../apiHelpers/user/create";
import { validateCustomerForm } from "../../../util/validators/validateForm";
import { NotificationManager } from "react-notifications";

const ContainerHeader = lazy(() => import("../../../components/ContainerHeader/index"));
const CardBox = lazy(() => import("../../../components/CardBox/index"));
const SettingPanel = lazy(() => import("../../../components/MyComponents/SettingPanel"));

class BasicSettings extends React.Component {
  constructor() {
    super();
    this.state = {
      error: "",
      appName: "",
      appNameError: "",
      emailError: "",
      email: "",

      custCreate,
      loader: false,
      pass: false
    };
  }

  submit = params => {
    const { name, email } = this.state;
    if (validateCustomerForm({ name, email }, this.handleError)) {
      custCreate(
        {
          name,
          email
        },
        this.onDone
      );
    } else {
      NotificationManager.error("Validation Error", "Oops!");
    }
  };
  handleError = params => {
    this.setState(params);
  };
  onDone = (success, message) => {
    this.setState({ loader: false });
    if (success) {
      return this.props.history.push("/app/user/customers");
    } else {
      //   this.setState({ error: message });
    }
  };
  renderButton = () => {
    // if (this.state.loader) {
    //   return <CircularProgress />;
    // }
    return (
      <Button
        variant="raised"
        color="primary"
        className="jr-btn"
        onClick={this.submit}
      >
        Add
      </Button>
    );
  };
  render() {
    const { match } = this.props;
    const { email, name, loader, emailError, appNameError, error } = this.state;

    return (
      <div>
        <ContainerHeader title={<IntlMessages id="settings" />} match={match} />

        <div className="row col-lg-12">
          <SettingPanel />
        </div>
      </div>
    );
  }
}

export default BasicSettings;

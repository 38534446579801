import React, { lazy } from "react";
import Table from "../table/index";
import * as actions from "../../../actions";
import { connect } from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Collections from "@material-ui/icons/Collections";
import SwitchVideo from "@material-ui/icons/SwitchVideo";

const HOCLoader = lazy(() => import("../../../components/MyComponents/HOCLoader"));
const GalleryVideoTable = lazy(() => import("../../../components/MyComponents/GalleryVideoTable"));
const GalleryTable = lazy(() => import("../../../components/MyComponents/GalleryTable"));

function TabContainer(props) {
  return <div style={{ padding: 50 }}>{props.children}</div>;
}

class GalleryList extends React.Component {
  constructor() {
    super();
    this.state = {
      error: "",
      list: [],
      noResponse: false,
      errorMessage: "",
      value: 0,
      redirect: false
    };
  }

  handleChange = (event, value) => {
    this.setState({ value });
  };
  render() {
    const { title, match } = this.props;
    const { list, loader, noResponse, errorMessage, value } = this.state;
    return (
      <div className="w-100">
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={this.handleChange}
            scrollable
            scrollButtons="on"
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab label="Images" icon={<Collections />} />
            <Tab label="Videos" icon={<SwitchVideo />} />
          </Tabs>
        </AppBar>
        {value === 0 && (
          <TabContainer key={0}>
            <GalleryTable
              headings={[
                { name: "File", id: "proImage" },
                { name: "Provider Service", id: "service" },
                { name: "Provider", id: "created_name" },
                { name: "Status", id: "status" }
              ]}
              title={title}
              match={match}
            />
          </TabContainer>
        )}
        {value === 1 && (
          <TabContainer key={1}>
            <GalleryVideoTable
              headings={[
                { name: "File", id: "proImage" },
                { name: "Provider Service", id: "service" },
                { name: "Provider", id: "created_name" },
                { name: "Status", id: "status" }
              ]}
              title={title}
              match={match}
            />
          </TabContainer>
        )}
      </div>
    );
  }
}

export default connect(
  null,
  actions
)(GalleryList);

import React from 'react';
import PropTypes from "prop-types";

import { TextField } from '@material-ui/core';

const WSTextField = props => {
    const {
        autoFocus,
        autoComplete,
        color,
        defaultValue,
        disabled,
        fullWidth,
        helperText,
        id,
        name,
        label,
        margin,
        multiline,
        onChange,
        placeholder,
        required,
        rows,
        rowsMax,
        size,
        type,
        value,
        variant,
        error,
        InputLabelProps,
        InputProps,
        FormHelperTextProps,
        inputProps,
        inputRef,
        select,
        SelectProps,
        className,
        style,
    } = props;

    return (
        <TextField
            autoFocus={autoFocus}
            autoComplete={autoComplete}
            color={color}
            defaultValue={defaultValue}
            disabled={disabled}
            fullWidth={fullWidth}
            helperText={helperText}
            id={id}
            name={name}
            label={label}
            margin={margin}
            multiline={multiline}
            onChange={onChange}
            placeholder={placeholder}
            required={required}
            rows={rows}
            rowsMax={rowsMax}
            size={size}
            type={type}
            value={value}
            variant={variant}
            error={error}
            InputLabelProps={InputLabelProps}
            InputProps={InputProps}
            FormHelperTextProps={FormHelperTextProps}
            inputProps={inputProps}
            inputRef={inputRef}
            select={select}
            SelectProps={SelectProps}
            className={className}
            style={style}
        />
    );
};

WSTextField.defaultProps = {
    color: 'primary',
    disabled: false,
    multiline: false,
    fullWidth: true,
    type: 'text',
    size: 'small',
    variant: 'standard',
    margin: 'normal',
};

WSTextField.propTypes = {
    autoFocus: PropTypes.bool,
    autoComplete: PropTypes.string,
    color: PropTypes.string,
    defaultValue: PropTypes.string,
    disabled: PropTypes.bool,
    fullWidth: PropTypes.bool,
    helperText: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    margin: PropTypes.string,
    multiline: PropTypes.bool,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    rows: PropTypes.number,
    rowsMax: PropTypes.number,
    size: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.string,
    variant: PropTypes.string,
    error: PropTypes.bool,
    InputLabelProps: PropTypes.object,
    InputProps: PropTypes.object,
    FormHelperTextProps: PropTypes.object,
    inputProps: PropTypes.object,
    select: PropTypes.bool,
    SelectProps: PropTypes.object,
    className: PropTypes.string,
    style: PropTypes.object,
};

export default WSTextField;
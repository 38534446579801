import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";

import Settings from "./Settings";
import ChatData from "./Chat";
import Contact from "./Contact";
import Mail from "./Mail";
import ToDo from "./ToDo";
import Auth from "./Auth";
import Customer from "./Customer";
import Admin from "./Admin";
import Booking from "./Booking";

const reducers = combineReducers({
  routing: routerReducer,
  settings: Settings,
  chatData: ChatData,
  contacts: Contact,
  mail: Mail,
  toDo: ToDo,
  auth: Auth,
  customer: Customer,
  admin: Admin,
  bookings: Booking
});

export default reducers;
import {
  CUSTOMER_CREATE,
  CUSTOMER_CREATE_SUCCESS,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  HIDE_MESSAGE,
  SHOW_MESSAGE,
  SORT_CUSTOMER,
  FETCH_SORT_CUSTOMER,
  LIST_USERS,
  GET_USERS,
  CUSTOMER_DETAIL,
  FETCH_DETAIL
} from "../constants/ActionTypes";

const INIT_STATE = {
  loader: false,
  alertMessage: "",
  createdUser: false,
  showMessage: false,
  allUsers: [],
  customerDetail: {},
  sortParams: {}
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CUSTOMER_CREATE_SUCCESS: {
      return {
        ...state,
        loader: false,
        alertMessage: true,
        createdUser: action.payload
      };
    }
    case CUSTOMER_CREATE: {
      return {
        ...state,
        createdUser: null,
        loader: true
      };
    }
    case LIST_USERS: {
      return {
        ...state,
        loader: false,
        allUsers: action.payload
      };
    }
    case GET_USERS: {
      return {
        ...state,
        allUsers: [],
        loader: true
      };
    }
    case CUSTOMER_DETAIL: {
      return {
        ...state,
        customerDetail: {}
      };
    }
    case FETCH_DETAIL: {
      return {
        ...state,
        mainDetail: action.payload
      };
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: "",
        loader: false
      };
    }
    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false
      };
    }
    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true
      };
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false
      };
    }
    case SORT_CUSTOMER: {
      return {
        ...state,
        sortParams: {}
      };
    }
    case FETCH_SORT_CUSTOMER: {
      return {
        ...state,
        fetchSortCustomer: action.payload
      };
    }
    default:
      return state;
  }
};
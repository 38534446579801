import React, { lazy } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import IntlMessages from '../../../util/IntlMessages';
import CircularProgress from "@material-ui/core/CircularProgress";
import { custCreate } from "../../../apiHelpers/user/create";
import { validateCustomerForm } from "../../../util/validators/validateForm";
import { NotificationManager } from "react-notifications";
import * as actions from "../../../actions";
import { connect } from "react-redux";

const ContainerHeader = lazy(() => import("../../../components/ContainerHeader/index"));
const CardBox = lazy(() => import("../../../components/CardBox/index"));
const HOCLoader = lazy(() => import("../../../components/MyComponents/HOCLoader"));
const CustomError = lazy(() => import("../../../components/MyComponents/common/CustomError"));

class CustomerCreate extends React.Component {
  constructor() {
    super();
    this.state = {
      error: "",
      nameError: "",
      emailError: "",
      email: "",
      name: "",
      custCreate,
      loader: false,
      pass: false,
      noResponse: false,
      errorMessage: ""
    };
  }

  submit = params => {
    const { name, email } = this.state;
    if (validateCustomerForm({ name, email }, this.handleError)) {
      this.setState({ loader: true });
      this.props.customerCreateAction(
        {
          name,
          email
        },
        this.onDone
      );
    } else {
      NotificationManager.error("Validation Error", "Oops!");
    }
  };
  handleError = params => {
    this.setState(params);
  };

  onDone = (success, data) => {
    this.setState({ loader: false });
    if (success) {
      return this.props.history.push("/app/user/customers");
    }
  };
  renderButton = () => {
    if (this.state.loader) {
      return <CircularProgress />;
    }
    return (
      <Button
        variant="raised"
        color="primary"
        className="jr-btn"
        onClick={this.submit}
      >
        Add
      </Button>
    );
  };

  render() {
    const { match } = this.props;
    const { email, name, loader, emailError, nameError, error } = this.state;

    return (
      <div className="animated slideInUpTiny animation-duration-3">
        <ContainerHeader
          title={<IntlMessages id="add-customer" />}
          match={match}
        />

        <div className="row">
          <CardBox styleName="col-lg-12">
            <form
              className="row"
              noValidate
              autoComplete="off"
              id="customer-create"
            >
              <div className="col-md-6 col-xs-12">
                <TextField
                  error={nameError ? true : false}
                  helperText={nameError}
                  type="text"
                  name="name"
                  label="Name"
                  id="name"
                  fullWidth
                  placeholder="Add Name"
                  InputLabelProps={{
                    shrink: true
                  }}
                  margin="normal"
                  onChange={event => {
                    this.setState({
                      name: event.target.value,
                      nameError: "",
                      error: ""
                    });
                  }}
                  defaultValue={name}
                  disabled={loader ? true : false}
                />
              </div>
              <div className="col-md-6 col-xs-12">
                <TextField
                  error={emailError ? true : false}
                  helperText={emailError}
                  type="email"
                  name="email"
                  label="Email"
                  id="email"
                  fullWidth
                  placeholder="Add Email"
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={event => {
                    this.setState({
                      email: event.target.value,
                      emailError: "",
                      error: ""
                    });
                  }}
                  margin="normal"
                  defaultValue={email}
                  disabled={loader ? true : false}
                />
              </div>
              <CustomError error={error} />
              <div className="col-md-12 col-xs-12">
                <div className="mt-3" />
                {this.renderButton()}
              </div>
            </form>
          </CardBox>
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  actions
)(CustomerCreate);

import React, { lazy } from "react";
import { connect } from "react-redux";
import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML
} from "draft-js";
import draftToHtml from "draftjs-to-html";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import IntlMessages from '../../../util/IntlMessages';
import { validateCMSForm } from "../../../util/validators/validateForm";

import * as actions from "../../../actions";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-image-crop/dist/ReactCrop.css";

const ContainerHeader = lazy(() => import("../../../components/ContainerHeader/index"));
const CardBox = lazy(() => import("../../../components/CardBox/index"));
const SimpleSelect = lazy(() => import("../components/routes/selects/simple/SimpleSelect"));
const HOCLoader = lazy(() => import("../../../components/MyComponents/HOCLoader"));

class CMSUpdate extends React.Component {
  constructor(props) {
    super();
    this.state = {
      error: "",
      titleError: "",
      metaTitleError: "",
      metaTitle: "",
      metaKeyword: "",
      metaKeywordError: "",
      title: "",
      description: "",
      status: 1,
      showingon: 0,
      loader: true,
      loaderButton: false,
      noResponse: false,
      errorMessage: "",
      metaDescription: "",
      metaDescriptionError: "",
      editorState: EditorState.createEmpty(),
      itemId: props.location.state ? props.location.state.itemId : ""
    };
  }

  onEditorStateChange = editorState => {
    this.setState({
      editorState,
      description: draftToHtml(convertToRaw(editorState.getCurrentContent()))
    });
  };

  componentDidMount() {
    if (!this.props.location.state) {
      return this.props.history.push("/app/cms/list");
    } else {
      this.getData(this.props.location.state.itemId);
    }
  }

  getData = async itemId => {
    this.props.cmsDetailAction(
      {
        id: itemId
      },
      this.onDoneDetail
    );
  };

  strip_html_tags = (str) => {
    if ((str === null) || (str === ''))
      return false;
    else
      str = str.toString();
    str.replace(/<[^>]*>/g, '');
    if (str) {
      str = str.trim();
    }
    return (!str || (str === null) || (str === '')) ? false : true;
  }

  onDoneDetail = (success, data) => {
    console.log('this.strip_html_tags(data.description)-', this.strip_html_tags(data.description));
    if (success) {
      this.setState(
        {
          title: data.title,
          metaTitle: data.meta_title,
          metaKeyword: data.meta_keywords,
          metaDescription: data.meta_description,
          status: data.status,
          showingon: data.showingon,
          editorState: this.strip_html_tags(data.description) ? EditorState.createWithContent(
            ContentState.createFromBlockArray(convertFromHTML(data.description))
          ) : EditorState.createEmpty()
        },
        () => {
          this.setState({ loader: false });
        }
      );
    } else {
      this.setState({ loader: false, noResponse: true, error: data });
    }
  };

  submit = () => {
    const {
      title,
      description,
      status,
      showingon,
      itemId,
      metaDescription,
      metaKeyword,
      metaTitle,
      editorState
    } = this.state;
    if (
      validateCMSForm(
        { title, metaTitle, metaKeyword, metaDescription },
        this.handleError
      )
    ) {
      this.setState({ loaderButton: true });
      this.props.cmsUpdateAction(
        {
          id: itemId,
          title,
          metaTitle,
          metaKeywords: metaKeyword,
          status,
          showingon,
          metaDescription,
          description
        },
        this.onDone
      );
    }
  };

  handleError = params => {
    this.setState(params);
    this.setState({ loaderButton: false });
  };

  onDone = (success, message) => {
    this.setState({ loaderButton: false });
    if (success) {
      return this.props.history.push("/app/cms/list");
    }
  };

  renderButton = () => {
    if (this.state.loaderButton) {
      return <CircularProgress />;
    }
    return (
      <Button
        variant="raised"
        color="primary"
        className="jr-btn"
        onClick={this.submit}
      >
        Update
      </Button>
    );
  };

  render() {
    const { match } = this.props;
    const {
      metaTitle,
      title,
      description,
      loader,
      metaTitleError,
      titleError,
      error,
      editorState,
      metaKeywordError,
      metaKeyword,
      metaDescription,
      metaDescriptionError,
      status,
      showingon,
      loaderButton,
      noResponse
    } = this.state;
    return (
      <HOCLoader
        loading={loader}
        noResponse={noResponse}
        errorShow={error}
        retryLink={"/app/category/list"}
      >
        <div className="animated slideInUpTiny animation-duration-3">
          <ContainerHeader
            title={<IntlMessages id="update-category" />}
            match={match}
          />
          <div className="row">
            <CardBox styleName="col-lg-12">
              <form
                className="row"
                noValidate
                autoComplete="off"
                id="customer-create"
              >
                <div className="col-md-6 col-xs-12">
                  <TextField
                    error={titleError ? true : false}
                    helperText={titleError}
                    type="text"
                    name="title"
                    label="Title"
                    id="title"
                    fullWidth
                    placeholder="Add Title"
                    InputLabelProps={{
                      shrink: true
                    }}
                    margin="normal"
                    onChange={event => {
                      this.setState({
                        title: event.target.value,
                        titleError: "",
                        error: ""
                      });
                    }}
                    defaultValue={title}
                    disabled={loaderButton ? true : false}
                  />
                </div>
                <div className="col-md-6 col-xs-12">
                  <TextField
                    error={metaTitleError ? true : false}
                    helperText={metaTitleError}
                    type="text"
                    name="metaTitle"
                    label="Meta Title"
                    id="metaTitle"
                    fullWidth
                    placeholder="Add Meta Title"
                    InputLabelProps={{
                      shrink: true
                    }}
                    onChange={event => {
                      this.setState({
                        metaTitle: event.target.value,
                        metaTitleError: "",
                        error: ""
                      });
                    }}
                    margin="normal"
                    defaultValue={metaTitle}
                    disabled={loaderButton ? true : false}
                  />
                </div>
                <div className="col-md-6 col-xs-12">
                  <TextField
                    error={metaKeywordError ? true : false}
                    helperText={metaKeywordError}
                    type="text"
                    name="metaKeyword"
                    label="Meta Keywords"
                    id="metaKeyword"
                    fullWidth
                    placeholder="Add Meta Keywords"
                    InputLabelProps={{
                      shrink: true
                    }}
                    onChange={event => {
                      this.setState({
                        metaKeyword: event.target.value,
                        metaKeywordError: "",
                        error: ""
                      });
                    }}
                    margin="normal"
                    defaultValue={metaKeyword}
                    disabled={loaderButton ? true : false}
                  />
                </div>
                <div className="col-md-6 col-xs-12">
                  <TextField
                    error={metaDescriptionError ? true : false}
                    helperText={metaDescriptionError}
                    type="text"
                    name="metaDescription"
                    label="Meta Description"
                    id="metaDescription"
                    fullWidth
                    placeholder="Add Meta Description"
                    InputLabelProps={{
                      shrink: true
                    }}
                    onChange={event => {
                      this.setState({
                        metaDescription: event.target.value,
                        metaDescriptionError: "",
                        error: ""
                      });
                    }}
                    margin="normal"
                    defaultValue={metaDescription}
                    disabled={loaderButton ? true : false}
                  />
                </div>
                <div className="col-md-6 col-xs-12">
                  <SimpleSelect
                    fullWidth
                    label="Status"
                    name="status"
                    id="status"
                    onChange={status => {
                      this.setState({ status });
                    }}
                    value={status}
                    disabled={loaderButton ? true : false}
                  />
                </div>
                <div className="col-md-6 col-xs-12">
                  <FormControl className="w-100 mt-3" >
                    <InputLabel htmlFor="showingon">Showing On</InputLabel>
                    <Select
                      value={showingon ? showingon : 0}
                      onChange={({ target: { value } }) => this.setState({ showingon: value })}
                      input={<Input id="showingon" />}
                    >
                      <MenuItem value={0}>
                        <em>Both (Header/Footer)</em>
                      </MenuItem>
                      <MenuItem value={1}>
                        <em>Header</em>
                      </MenuItem>
                      <MenuItem value={2}>Footer</MenuItem>
                    </Select>
                  </FormControl>
                </div>
                <div className="col-md-12 mt-4">
                  <Editor
                    editorState={editorState}
                    value={description}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor"
                    onEditorStateChange={this.onEditorStateChange}
                    toolbar={{
                      inline: { inDropdown: true },
                      list: { inDropdown: true },
                      textAlign: { inDropdown: true },
                      link: { inDropdown: true },
                      history: { inDropdown: true },
                      image: {
                        uploadCallback: this.uploadImageCallBack,
                        alt: { present: true, mandatory: false }
                      }
                    }}
                  />
                </div>
                <div className="col-md-12 col-xs-12">
                  <div className="mt-3" />
                  {this.renderButton()}
                </div>
              </form>
            </CardBox>
          </div>
        </div>
      </HOCLoader>
    );
  }
}

export default connect(
  null,
  actions
)(CMSUpdate);
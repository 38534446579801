export const validateApiData = data => {
  if (data.status == "OK") {
    return true;
  } else if (data.status == "NOK") {
    return true;
  } else if (data.status == "ADMINAUTH") {
    return "AUTH";
  }
  return false;
};

export const errorResponse = {
  status: "NOK",
  message: "Unknown Error Occurred"
};
import { getProviderServices } from "../apiHelpers/providerService/list";
import { proServiceDetail } from "../apiHelpers/providerService/detail";

export const providerServiceListAction = (params, done) => async dispatch => {
  const apiData = await getProviderServices(params, done);
  if (apiData.status == "OK") {
    done(true, apiData.data);
  } else {
    done(false, apiData.message);
  }
};

export const proServiceDetailAction = (params, done) => async dispatch => {
  const apiData = await proServiceDetail(params, done);
  if (apiData.status == "OK") {
    done(true, apiData.data);
  } else {
    done(false, apiData.message);
  }
};
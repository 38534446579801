import React, { lazy } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import IntlMessages from '../../../util/IntlMessages';
import CircularProgress from "@material-ui/core/CircularProgress";
import * as actions from "../../../actions";
import { connect } from "react-redux";
import { validateBannerForm } from "../../../util/validators/validateForm";
import ReactCrop from "react-image-crop";
import Dropzone from "react-dropzone";
import "react-image-crop/dist/ReactCrop.css";
import { NotificationManager } from "react-notifications";

const ContainerHeader = lazy(() => import("../../../components/ContainerHeader/index"));
const CardBox = lazy(() => import("../../../components/CardBox/index"));
const SimpleSelect = lazy(() => import("../components/routes/selects/simple/SimpleSelect"));
const HOCLoader = lazy(() => import("../../../components/MyComponents/HOCLoader"));

const handleDropRejected = (...args) => console.log("reject", args);

class BannerCreate extends React.Component {
  constructor() {
    super();

    this.state = {
      error: "",
      titleError: "",
      descriptionError: "",
      description: "",
      image: "",
      title: "",
      loader: false,
      pass: false,
      noResponse: false,
      errorMessage: "",
      preview: null,
      generalPhotos: "",
      status: 1,
      crop: {
        x: 40,
        y: 40,
        width: 100,
        height: 100,
      },
      croppedImageUrl: ""
    };

    this.handleDrop = this.handleDrop.bind(this);
  }
  handleDrop(dropImages) {
    console.log('***dropImages***', dropImages);
    this.setState({ preview: dropImages[0].preview });
  }

  onImageLoaded = (image, pixelCrop) => {
    this.imageRef = image;
  };
  onCropComplete = async (crop) => {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ croppedImageUrl });
    }
  };

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    let originWidth = crop.width * scaleX;
    let originHeight = crop.height * scaleY;
    // maximum width/height
    let maxWidth = 2000, maxHeight = 2000 / (16 / 9);
    let targetWidth = originWidth,
      targetHeight = originHeight;
    if (originWidth > maxWidth || originHeight > maxHeight) {
      if (originWidth / originHeight > maxWidth / maxHeight) {
        targetWidth = maxWidth;
        targetHeight = Math.round(maxWidth * (originHeight / originWidth));
      } else {
        targetHeight = maxHeight;
        targetWidth = Math.round(maxHeight * (originWidth / originHeight));
      }
    }
    // set canvas size
    canvas.width = targetWidth;
    canvas.height = targetHeight;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      targetWidth,
      targetHeight
    );

    // return new Promise((resolve, reject) => {
    //   canvas.toBlob(
    //     blob => {
    //       if (!blob) {
    //         console.error("Canvas is empty");
    //         return;
    //       }
    //       blob.name = fileName;
    //       window.URL.revokeObjectURL(this.fileUrl);
    //       this.fileUrl = window.URL.createObjectURL(blob);
    //       resolve(this.fileUrl);
    //     },
    //     "image/png",
    //     1
    //   );
    // });


    // const scaleX = image.naturalWidth / image.width;
    // const scaleY = image.naturalHeight / image.height;
    // canvas.width = Math.ceil(pixelCrop.width*scaleX);
    // canvas.height = Math.ceil(pixelCrop.height*scaleY);
    // const ctx = canvas.getContext("2d");

    // ctx.drawImage(
    //   image,
    //   pixelCrop.x  * scaleX,
    //   pixelCrop.y * scaleY,
    //   pixelCrop.width * scaleX,
    //   pixelCrop.height  * scaleY,
    //   0,
    //   0,
    //   pixelCrop.width*scaleX,
    //   pixelCrop.height*scaleY
    // );

    return new Promise((resolve, reject) => {
      this.fileUrl = canvas.toDataURL();
      resolve(this.fileUrl);
    });
  }

  submit = () => {
    this.setState({ loader: true });
    const { title, description, status } = this.state;

    if (validateBannerForm({}, this.handleError)) {
      this.props.bannerCreateAction(
        {
          title,
          image: this.state.croppedImageUrl,
          status,
          description
        },
        this.onDone
      );
    } else {
      NotificationManager.error("Validation Error", "Oops!");
    }
  };

  handleError = params => {
    this.setState(params);
    this.setState({ loader: false });
  };
  onDone = (success, message) => {
    this.setState({ loader: false });
    if (success) {
      return this.props.history.push("/app/banner/list");
    } else {
      //   this.setState({ error: message });
    }
  };

  renderButton = () => {
    if (this.state.loader) {
      return <CircularProgress />;
    }
    return (
      <Button
        variant="raised"
        color="primary"
        className="jr-btn"
        onClick={this.submit}
      >
        Add
      </Button>
    );
  };

  render() {
    const { match } = this.props;
    const {
      email,
      title,
      image,
      status,
      loader,
      description,
      descriptionError,
      titleError,
      error,
      preview,
      croppedImageUrl,
      noResponse,
      errorMessage,
      generalPhotos
    } = this.state;
    return (
      <HOCLoader
        loading={false}
        noResponse={noResponse}
        errorShow={errorMessage}
        retryLink={"/app/banner/list"}
      >
        <div className="animated slideInUpTiny animation-duration-3">
          <ContainerHeader
            title={<IntlMessages id="add-banner" />}
            match={match}
          />
          <div className="row">
            <CardBox styleName="col-lg-12">
              <form
                className="row"
                noValidate
                autoComplete="off"
                id="customer-create"
              >
                <div className="col-md-12 col-xs-12">
                  <div className="row">
                    <div className="col-md-2">
                      <h3>Drop Image here to Upload</h3>
                      <Dropzone
                        onDrop={this.handleDrop}
                        accept="image/jpeg,image/jpg,image/png"
                        multiple={false}
                        onDropRejected={handleDropRejected}
                      >
                        <p> Drag a file here or click to upload.</p>
                      </Dropzone>
                    </div>
                    <div className="col-md-6">
                      {preview && (
                        <div>
                          <h3>Original Image</h3>
                          <ReactCrop
                            src={preview}
                            imageStyle={{
                              width: "100%",
                              maxHeight: "none"
                            }}
                            onImageLoaded={this.onImageLoaded}
                            onComplete={this.onCropComplete}
                            crop={this.state.crop}
                            onChange={crop => {
                              this.setState({ crop });
                            }}
                          />
                        </div>
                      )}
                    </div>
                    <div className="col-md-4">
                      {croppedImageUrl && (
                        <div>
                          <h3>Final Image</h3>
                          <img
                            width={"100%"}
                            height={"100%"}
                            alt="Crop"
                            src={croppedImageUrl}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-xs-12">
                  <TextField
                    error={titleError ? true : false}
                    helperText={titleError}
                    type="text"
                    name="title"
                    label="Title"
                    id="title"
                    fullWidth
                    placeholder="Add Title"
                    InputLabelProps={{
                      shrink: true
                    }}
                    margin="normal"
                    onChange={event => {
                      this.setState({
                        title: event.target.value,
                        titleError: ""
                      });
                    }}
                    defaultValue={title}
                    disabled={loader ? true : false}
                  />
                </div>

                <div className="col-md-6 col-xs-12">
                  <TextField
                    error={descriptionError ? true : false}
                    helperText={descriptionError}
                    type="text"
                    name="description"
                    label="Description"
                    id="description"
                    fullWidth
                    placeholder="Add Description"
                    InputLabelProps={{
                      shrink: true
                    }}
                    onChange={event => {
                      this.setState({
                        description: event.target.value,
                        descriptionError: "",
                        error: ""
                      });
                    }}
                    margin="normal"
                    defaultValue={description}
                    disabled={loader ? true : false}
                  />
                </div>

                <div className="col-md-12 col-xs-12">
                  <div className="mt-3" />
                  {this.renderButton()}
                </div>
              </form>
            </CardBox>
          </div>
        </div>
      </HOCLoader>
    );
  }
}

export default connect(
  null,
  actions
)(BannerCreate);

import React, { Component, lazy } from "react";
import { compose } from "redux";
import { FormattedMessage } from "react-intl";
import { isMobile } from "react-device-detect";
import IntlMessages from "../../../util/IntlMessages";

import Card from "@material-ui/core/Card";
import Toolbar from "@material-ui/core/Toolbar";
import { withStyles } from "@material-ui/core/styles";
import ProviderAddressAdd from "../../../components/MyComponents/ProviderAddressAdd";
import { providerDetail } from "../../../apiHelpers/user/detail";
const ContainerHeader = lazy(() =>
  import("../../../components/ContainerHeader/index")
);

const styles = (theme) => ({
  card: {
    color: theme.palette.secondary.main,
  },
});

export class AddressUpdate extends Component {
  render() {
    const { classes, data, location } = this.props;
    const { state } = location;
    const { providerDetails } = state;
    return (
      <div>
        <div className={isMobile ? "row mb-2" : "row mb-4"}>
          <div className="col-md-12">
            <ContainerHeader
              match={this.props.match}
              title={<IntlMessages id="add Address" />}
              isPath
              pagePath={`/app/provider/update/${providerDetails.id}`}
            />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <ProviderAddressAdd {...this.props} address={data} />
          </div>
        </div>
      </div>
    );
  }
}

const composedAddress = compose(withStyles(styles));

export default composedAddress(AddressUpdate);

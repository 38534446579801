import React, { lazy } from "react";

const PromoTable = lazy(() => import("../../../components/MyComponents/PromoTable"));

const PromoCodeList = () => {
  return (
    <PromoTable
      headings={[
        { name: "Promo Code", id: "code" },
        { name: "Discount Percentage", id: "amountPercentage" },
        { name: "Booking Count", id: "booking_count" },
        { name: "Start Date", id: "start_date" },
        { name: "End Date", id: "end_date" },
        { name: "Status", id: "status" },
        { name: "Deleted?", id: "deleted_at" },
        { name: "Actions", id: "actions" }
      ]}
    />
  );
};

export default PromoCodeList;

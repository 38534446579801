import Cookies from "universal-cookie";

export const getCookies = () => {
  const cookies = new Cookies();

  let authToken;
  authToken = cookies.get("x-auth-token");
  if (authToken) {
    return authToken;
  }
  return null;
};
import {
  galleryList,
  proGalleryList,
  galleryVideoList
} from "../apiHelpers/gallery/list";
import { deleteGalleryImage, deleteGalleryVideo } from "../apiHelpers/gallery/list";

export const galleryListAction = done => async dispatch => {
  const list = await galleryList();
  if (list.status == "OK") {
    done(true, list.data);
  } else {
    done(false, list.message);
  }
};

export const galleryVideoListAction = done => async dispatch => {
  const list = await galleryVideoList();
  if (list.status == "OK") {
    done(true, list.data);
  } else {
    done(false, list.message);
  }
};

export const providerGalleryListAction = (params, done) => async dispatch => {
  const list = await proGalleryList(params);
  if (list.status == "OK") {
    done(true, list.data);
  } else {
    done(false, list.message);
  }
};

export const deleteGalleryAction = (params, done) => async dispatch => {
  const list = await deleteGalleryImage(params);
  if (list.status == "OK") {
    done(true, list.data);
  } else {
    done(false, list.message);
  }
};

export const deleteVideoGalleryAction = (params, done) => async dispatch => {
  const list = await deleteGalleryVideo(params);
  if (list.status == "OK") {
    done(true, list.data);
  } else {
    done(false, list.message);
  }
};
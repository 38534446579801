import axios from "axios";
import { NotificationManager } from "react-notifications";

import { errorResponse, validateApiData } from "../../util/validators/validators";
import { getCookies } from "../../util/getToken";

const SERVER_URL = process.env.REACT_APP_SERVER_URL

export const customerDetail = async params => {
  let authToken = getCookies();
  try {
    const { data } = await axios({
      method: "post",
      url: `${SERVER_URL}/admin/user/detail`,
      data: params,
      headers: { "x-auth-token": authToken }
    });
    return validateApiData(data) ? data : errorResponse;
  } catch (e) {
    console.log("Error in user detail", e);
    NotificationManager.error(errorResponse.message, "Oops!");
    return errorResponse;
  }
};

export const providerDetail = async params => {
  let authToken = getCookies();
  try {
    const { data } = await axios({
      method: "post",
      url: `${SERVER_URL}/admin/provider/detail`,
      data: params,
      headers: { "x-auth-token": authToken }
    });
    return validateApiData(data) ? data : errorResponse;
  } catch (e) {
    console.log("Error in user detail", e);
    NotificationManager.error(errorResponse.message, "Oops!");
    return errorResponse;
  }
};

export const adminDetail = async () => {
  let authToken = getCookies();
  try {
    const { data } = await axios({
      method: "get",
      url: `${SERVER_URL}/admin/my/detail`,

      headers: { "x-auth-token": authToken }
    });
    return validateApiData(data) ? data : errorResponse;
  } catch (e) {
    console.log("Error in user detail", e);
    NotificationManager.error(errorResponse.message, "Oops!");
    return errorResponse;
  }
};

export const customerDashboard = async params => {
  let authToken = getCookies();
  try {
    const { data } = await axios({
      method: "post",
      url: `${SERVER_URL}/admin/customer/dashboard`,
      data: params,
      headers: { "x-auth-token": authToken }
    });
    return validateApiData(data) ? data : errorResponse;
  } catch (e) {
    console.log("Error in user detail", e);
    NotificationManager.error(errorResponse.message, "Oops!");
    return errorResponse;
  }
};

export const providerDashboard = async params => {
  let authToken = getCookies();
  try {
    const { data } = await axios({
      method: "post",
      url: `${SERVER_URL}/admin/provider/dashboard`,
      data: params,
      headers: { "x-auth-token": authToken }
    });
    return validateApiData(data) ? data : errorResponse;
  } catch (e) {
    console.log("Error in user detail", e);
    NotificationManager.error(errorResponse.message, "Oops!");
    return errorResponse;
  }
};

export const checkAuth = async params => {
  let authToken = getCookies();
  try {
    const { data } = await axios({
      method: "get",
      url: `${SERVER_URL}/admin/my/check`,
      data: params,
      headers: { "x-auth-token": authToken }
    });
    return data;
  } catch (e) {
    console.log("Error in user detail", e);
    return errorResponse;
  }
};

export const getDashboardData = async params => {
  let authToken = getCookies();
  try {
    const { data } = await axios({
      method: "get",
      url: `${SERVER_URL}/admin/dashboard/index`,
      data: params,
      headers: { "x-auth-token": authToken }
    });
    return data;
  } catch (e) {
    console.log("Error in user detail", e);
    return errorResponse;
  }
};
import axios from "axios";
import { NotificationManager } from "react-notifications";

import { errorResponse, validateApiData } from "../../util/validators/validators";
import { getCookies } from "../../util/getToken";

const SERVER_URL = process.env.REACT_APP_SERVER_URL

export const catCreate = async params => {
  try {
    let authToken = getCookies();
    const { data } = await axios({
      method: "post",
      url: `${SERVER_URL}/admin/category/create`,
      data: params,
      headers: {
        "x-auth-token": authToken
      }
    });
    return validateApiData(data) ? data : errorResponse;
  } catch (e) {
    console.log("Error in category create", e);
    NotificationManager.error(errorResponse.message, "Oops!");
    return errorResponse;
  }
};

export const categoryUpdate = async params => {
  let authToken = getCookies();
  try {
    const { data } = await axios({
      method: "post",
      url: `${SERVER_URL}/admin/category/update`,
      data: params,
      headers: { "x-auth-token": authToken }
    });
    return validateApiData(data) ? data : errorResponse;
  } catch (e) {
    console.log("Error in update category", e);
    NotificationManager.error(errorResponse.message, "Oops!");
    return errorResponse;
  }
};

export const categoryDelete = async params => {
  let authToken = getCookies();
  try {
    const { data } = await axios({
      method: "delete",
      url: `${SERVER_URL}/admin/category/deleted`,
      data: params,
      headers: { "x-auth-token": authToken }
    });
    if (data.status == "OK") {
      NotificationManager.success(data.message, "Success!");
    } else if (data.status == "NOK") {
      NotificationManager.error(data.message, "Oops!");
    }
    return validateApiData(data) ? data : errorResponse;
  } catch (e) {
    console.log("Error in delete category", e);
    NotificationManager.error(errorResponse.message, "Oops!");
    return errorResponse;
  }
};

export const categoryRestore = async params => {
  let authToken = getCookies();
  try {
    const { data } = await axios({
      method: "post",
      url: `${SERVER_URL}/admin/category/restore`,
      data: params,
      headers: { "x-auth-token": authToken }
    });
    if (data.status == "OK") {
      NotificationManager.success(data.message, "Success!");
    } else if (data.status == "NOK") {
      NotificationManager.error(data.message, "Oops!");
    }
    return validateApiData(data) ? data : errorResponse;
  } catch (e) {
    console.log("Error in delete category", e);
    NotificationManager.error(errorResponse.message, "Oops!");
    return errorResponse;
  }
};
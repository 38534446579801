import { promoList } from "../apiHelpers/promoCode/list";
import {
  promoCreate,
  promoUpdate,
  promoDelete,
  promoRestore
} from "../apiHelpers/promoCode/create";
import { promoDetail } from "../apiHelpers/promoCode/detail";

export const promoCodeListAction = done => async dispatch => {
  const list = await promoList();
  if (list.status == "OK") {
    done(true, list);
  } else {
    done(false, list.message);
  }
};

export const promoCreateAction = (params, done) => async dispatch => {
  const list = await promoCreate(params);
  if (list.status == "OK") {
    done(true, list);
  } else {
    done(false, list.message);
  }
};

export const promoDetailAction = (params, done) => async dispatch => {
  const list = await promoDetail(params);
  if (list.status == "OK") {
    done(true, list);
  } else {
    done(false, list.message);
  }
};

export const promoUpdateAction = (params, done) => async dispatch => {
  const list = await promoUpdate(params);
  if (list.status == "OK") {
    done(true, list);
  } else {
    done(false, list.message);
  }
};

export const deletePromoAction = (params, done) => async dispatch => {
  const apiData = await promoDelete(params);
  if (apiData.status == "OK") {
    done(true, apiData.data);
  } else {
    done(false, apiData.message);
  }
};

export const restorePromoAction = (params, done) => async dispatch => {
  const apiData = await promoRestore(params);
  if (apiData.status == "OK") {
    done(true, apiData.data);
  } else {
    done(false, apiData.message);
  }
};
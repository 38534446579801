import React, { lazy } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import IntlMessages from '../../../util/IntlMessages';
import CircularProgress from "@material-ui/core/CircularProgress";
import { validateServiceForm } from "../../../util/validators/validateForm";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import * as actions from "../../../actions";
import { connect } from "react-redux";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { NotificationManager } from "react-notifications";
import InputLabel from "@material-ui/core/InputLabel";
import ReactCrop from "react-image-crop";
import Dropzone from "react-dropzone";
import "react-image-crop/dist/ReactCrop.css";

const ContainerHeader = lazy(() => import("../../../components/ContainerHeader/index"));
const CardBox = lazy(() => import("../../../components/CardBox/index"));
const SimpleSelect = lazy(() => import("../components/routes/selects/simple/SimpleSelect"));
const HOCLoader = lazy(() => import("../../../components/MyComponents/HOCLoader"));

const handleDropRejected = (...args) =>
  NotificationManager.error("Image cant be uploaded, try another", "Error");

class SubcategoryCreate extends React.Component {
  constructor() {
    super();
    this.state = {
      error: "",
      descriptionError: "",
      description: "",
      name: "",
      status: "1",
      categoryId: "",
      categoryError: "",
      loader: true,
      loadingButton: false,
      nameError: "",
      redirect: false,
      type: "",
      noResponse: false,
      errorMessage: "",
      categoryData: [],
      croppedImageUrl: "",
      preview: null,
      generalPhotos: "",
      crop: {
        x: 40,
        y: 40,
        width: 100,
        height: 100,
      },


    };
    this.handleDrop = this.handleDrop.bind(this);
  }

  handleDrop([{ preview }]) {
    console.log({ preview });
    this.setState({ preview });
  }

  onImageLoaded = (image, pixelCrop) => {
    console.log(image);
    this.imageRef = image;
  };
  onCropComplete = async (crop) => {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ croppedImageUrl });
    }
  };

  getCroppedImg(image, pixelCrop, fileName) {
    const canvas = document.createElement("canvas");

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      pixelCrop.x * scaleX,
      pixelCrop.y * scaleY,
      pixelCrop.width * scaleX,
      pixelCrop.height * scaleY,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );

    return new Promise((resolve, reject) => {
      this.fileUrl = canvas.toDataURL();
      resolve(this.fileUrl);
    });
  }


  submit = params => {
    this.setState({ loadingButton: true });
    const { name, description, status, categoryId } = this.state;

    if (validateServiceForm({ name, categoryId }, this.handleError)) {
      this.props.subcategoryCreateAction(
        {
          name,
          image: this.state.croppedImageUrl,
          description,
          status,
          categoryId
        },
        this.onDone
      );
    } else {
      NotificationManager.error("Validation Error", "Oops!");
    }
  };
  handleError = params => {
    this.setState(params);
    this.setState({ loadingButton: false });
  };
  onDone = (success, data) => {
    if (success) {
      this.setState({ loadingButton: false });
      NotificationManager.success("Subcategory added successfully", "Success");
      return this.props.history.push("/app/subcategory/list");
    } else {
      this.setState({ loadingButton: false });
    }
  };

  componentDidMount() {
    this.setState({ loader: true });
    this.props.categoryListAction(this.onCategoryDone);
  }
  onCategoryDone = (success, data) => {
    if (success) {
      this.setState({ categoryData: data });
    }
    this.setState({ loader: false });
  };
  renderButton = () => {
    if (this.state.loadingButton) {
      return <CircularProgress />;
    }
    return (
      <Button
        variant="raised"
        color="primary"
        className="jr-btn"
        onClick={this.submit}
      >
        Add
      </Button>
    );
  };
  handleChange = categoryId => event => {
    this.setState({ [categoryId]: event.target.value, categoryError: "" });
  };

  render() {
    const { match } = this.props;

    const {
      status,
      loader,
      categoryData,
      categoryError,
      nameError,
      description,
      descriptionError,
      preview,
      croppedImageUrl
    } = this.state;
    if (loader) {
      return (
        <div className="loader-view loader-center">
          <CircularProgress />
        </div>
      );
    }
    return (
      <div className="animated slideInUpTiny animation-duration-3">
        <ContainerHeader
          title={<IntlMessages id="add-subcategory" />}
          match={match}
        />

        <CardBox styleName="col-lg-12">
          <form
            className="row"
            noValidate
            autoComplete="off"
            id="subcategory-create"
            encType="multipart/form-data"
          >

            <div className="col-md-12 col-xs-12">
              <div className="row">
                <div className="col-md-2">
                  <h3>Drop Image here to Upload</h3>
                  <Dropzone
                    onDrop={this.handleDrop}
                    accept="image/jpeg,image/jpg,image/png"
                    multiple={false}
                    onDropRejected={handleDropRejected}
                  >
                    <p> Drag a file here or click to upload.</p>
                  </Dropzone>
                </div>
                <div className="col-md-6">
                  {preview && (
                    <div>
                      <h3>Original Image</h3>
                      <ReactCrop
                        src={preview}
                        imageStyle={{
                          width: "100%",
                          height: "100%"
                        }}
                        onImageLoaded={this.onImageLoaded}
                        onComplete={this.onCropComplete}
                        crop={this.state.crop}
                        onChange={crop => {
                          this.setState({ crop });
                        }}
                      />
                    </div>
                  )}
                </div>
                <div className="col-md-4">
                  {croppedImageUrl && (
                    <div>
                      <h3>Final Image</h3>
                      <img
                        width={"100%"}
                        height={"100%"}
                        alt="Crop"
                        src={croppedImageUrl}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-md-4 col-12">
                  <TextField
                    error={nameError ? true : false}
                    helperText={nameError}
                    id="name"
                    label="Name"
                    name="name"
                    InputLabelProps={{
                      shrink: true
                    }}
                    placeholder="Add Name"
                    fullWidth
                    onChange={event => {
                      this.setState({
                        name: event.target.value,
                        nameError: ""
                      });
                    }}
                    disabled={loader ? true : false}
                    margin="normal"
                  />
                </div>
                <div className="col-md-4 col-xs-12">
                  <FormControl
                    className="w-100 mt-3"
                    error={categoryError ? true : false}
                  >
                    <InputLabel htmlFor="categoryId">Category</InputLabel>
                    <Select
                      fullWidth
                      name="categoryId"
                      value={this.state.categoryId}
                      onChange={this.handleChange("categoryId")}
                      input={<Input id="categoryId" />}
                    >
                      {categoryData &&
                        categoryData.map((item, index) => {
                          return (
                            <MenuItem value={item.key} key={index}>
                              {item.heading}
                            </MenuItem>
                          );
                        })}
                    </Select>
                    <FormHelperText>{categoryError}</FormHelperText>
                  </FormControl>
                </div>
                <div className="col-md-4 col-xs-12">
                  <SimpleSelect
                    fullWidth
                    label="Status"
                    name="status"
                    id="status"
                    value={status}
                    onChange={status => {
                      this.setState({ status });
                    }}
                    disabled={loader ? true : false}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-xs-12">
                  <TextField
                    error={descriptionError ? true : false}
                    helperText={descriptionError}
                    type="text"
                    name="description"
                    label="Description"
                    id="description"
                    fullWidth
                    placeholder="Add Description"
                    InputLabelProps={{
                      shrink: true
                    }}
                    onChange={event => {
                      this.setState({
                        description: event.target.value,
                        descriptionError: "",
                        error: ""
                      });
                    }}
                    margin="normal"
                    defaultValue={description}
                    disabled={loader ? true : false}
                  />
                </div>
              </div>
            </div>
            <div className="col-md-12 col-xs-12">
              <div className="mt-3" />
              {this.renderButton()}
            </div>
          </form>
        </CardBox>
      </div>
    );
  }
}

export default connect(
  null,
  actions
)(SubcategoryCreate);

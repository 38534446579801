import {
  PROVIDER_DETAILS,
  PROVIDER_DASHBOARD_DETAILS,
  ADMIN_INFO,
  LOADING_ADMIN,
  ERROR_ADMIN,
  GET_NATIONALITY_DATA,
  GET_LANGUAGES_DATA,
} from "../constants/ActionTypes";

const initialSettings = {
  adminDetail: {},
  loader: true,
  error: "",
  nationalityData: [],
  languagesData: [],
};

const adminInfo = (state = initialSettings, action) => {
  switch (action.type) {
    case ADMIN_INFO:
      return {
        ...state,
        adminDetail: action.payload,
      };
    case PROVIDER_DETAILS:
      return {
        ...state,
        loader: action.payload,
        providerDetail: action.payload.detail,
      };
    case PROVIDER_DASHBOARD_DETAILS:
      return {
        ...state,
        loader: action.payload,
        providerDashboardDetail: action.payload,
      };
    case GET_NATIONALITY_DATA:
      return { ...state, nationalityData: action.payload };
    case GET_LANGUAGES_DATA:
      return { ...state, languagesData: action.payload };
    case LOADING_ADMIN:
      return { ...state, loader: action.payload };
    case ERROR_ADMIN:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

export default adminInfo;

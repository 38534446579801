import { NotificationManager } from "react-notifications";

import {
  getAddressList,
  getProAddressList,
  changeAddressStatus
} from "../apiHelpers/address/list";

export const customerAddressListAction = (params, done) => async dispatch => {
  const list = await getAddressList(params);
  if (list.status == "OK") {
    done(true, list.data);
  } else {
    done(false, list.message);
  }
};

export const providerAddressListAction = (params, done) => async dispatch => {
  const list = await getProAddressList(params);
  if (list.status == "OK") {
    done(true, list.data);
  } else {
    done(false, list.message);
  }
};

export const changeAddressStatusAction = (params, done) => async dispatch => {
  const list = await changeAddressStatus(params);
  if (list.status == "OK") {
    NotificationManager.success(list.message, "Status Changed!");
  }
  if (list.status == "OK") {
    done(true, list.data);
  } else {
    done(false, list.message);
  }
};
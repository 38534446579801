import React, { lazy } from "react";
import { connect } from "react-redux";
import ReactCrop from "react-image-crop";
import Dropzone from "react-dropzone";
import { NotificationManager } from "react-notifications";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";

import * as actions from "../../../actions";

import { validateCategoryForm } from "../../../util/validators/validateForm";
import IntlMessages from '../../../util/IntlMessages';

import "react-image-crop/dist/ReactCrop.css";

const ContainerHeader = lazy(() => import("../../../components/ContainerHeader/index"));
const CardBox = lazy(() => import("../../../components/CardBox/index"));
const SimpleSelect = lazy(() => import("../components/routes/selects/simple/SimpleSelect"));
const HOCLoader = lazy(() => import("../../../components/MyComponents/HOCLoader"));

const handleDropRejected = (...args) =>
  NotificationManager.error("Image cant be uploaded, try another", "Error");

class CategoryCreate extends React.Component {
  constructor() {
    super();
    this.state = {
      error: "",
      nameError: "",
      descriptionError: "",
      description: "",
      image: "",
      name: "",
      loader: false,
      pass: false,
      noResponse: false,
      errorMessage: "",
      preview: null,
      generalPhotos: "",
      status: 1,
      crop: {
        x: 40,
        y: 40,
        width: 100,
        height: 100,
      },
      croppedImageUrl: "",
      howItWorks1Error: "",
      howItWorks2Error: "",
      howItWorks3Error: "",
      howItWorks1: "",
      howItWorks2: "",
      howItWorks3: ""
    };
    this.handleDrop = this.handleDrop.bind(this);
  }

  handleDrop([{ preview }]) {
    this.setState({ preview });
  }

  onImageLoaded = (image, pixelCrop) => {
    this.imageRef = image;
  };

  onCropComplete = async (crop) => {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ croppedImageUrl });
    }
  };

  getCroppedImg(image, pixelCrop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    const ctx = canvas.getContext("2d");
    ctx.drawImage(
      image,
      pixelCrop.x * scaleX,
      pixelCrop.y * scaleY,
      pixelCrop.width * scaleX,
      pixelCrop.height * scaleY,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );
    return new Promise((resolve, reject) => {
      this.fileUrl = canvas.toDataURL();
      resolve(this.fileUrl);
    });
  }

  submit = params => {
    this.setState({ loader: true });
    const {
      name,
      description,
      status,
      howItWorks1,
      howItWorks2,
      howItWorks3
    } = this.state;
    if (
      validateCategoryForm(
        { name, howItWorks1, howItWorks2, howItWorks3 },
        this.handleError
      )
    ) {
      this.props.categoryCreateAction(
        {
          name,
          image: this.state.croppedImageUrl,
          status,
          description,
          howItWorks1,
          howItWorks2,
          howItWorks3
        },
        this.onDone
      );
    } else {
      NotificationManager.error("Validation Error", "Oops!");
    }
  };

  handleError = params => {
    this.setState(params);
    this.setState({ loader: false });
  };

  onDone = (success, message) => {
    this.setState({ loader: false });
    if (success) {
      return this.props.history.push("/app/category/list");
    } else {
      //   this.setState({ error: message });
    }
  };

  renderButton = () => {
    if (this.state.loader) {
      return <CircularProgress />;
    }
    return (
      <Button
        variant="raised"
        color="primary"
        className="jr-btn"
        onClick={this.submit}
      >
        Add
      </Button>
    );
  };

  render() {
    const { match } = this.props;
    const {
      name,
      status,
      loader,
      description,
      descriptionError,
      nameError,
      preview,
      croppedImageUrl,
      noResponse,
      errorMessage,
      howItWorks1Error,
      howItWorks2Error,
      howItWorks3Error
    } = this.state;
    return (
      <HOCLoader
        loading={false}
        noResponse={noResponse}
        errorShow={errorMessage}
        retryLink={"/app/category/list"}
      >
        <div className="animated slideInUpTiny animation-duration-3">
          <ContainerHeader
            title={<IntlMessages id="add-category" />}
            match={match}
          />
          <div className="row">
            <CardBox styleName="col-lg-12">
              <form
                className="row"
                noValidate
                autoComplete="off"
                id="customer-create"
              >
                <div className="col-md-12 col-xs-12">
                  <div className="row">
                    <div className="col-md-2">
                      <h3>Drop Image here to Upload</h3>
                      <Dropzone
                        onDrop={this.handleDrop}
                        accept="image/jpeg,image/jpg,image/png"
                        multiple={false}
                        onDropRejected={handleDropRejected}
                      >
                        <p> Drag a file here or click to upload.</p>
                      </Dropzone>
                    </div>
                    <div className="col-md-6">
                      {preview && (
                        <div>
                          <h3>Original Image</h3>
                          <ReactCrop
                            src={preview}
                            imageStyle={{
                              width: "100%",
                              height: "100%"
                            }}
                            onImageLoaded={this.onImageLoaded}
                            onComplete={this.onCropComplete}
                            crop={this.state.crop}
                            onChange={crop => {
                              this.setState({ crop });
                            }}
                          />
                        </div>
                      )}
                    </div>
                    <div className="col-md-4">
                      {croppedImageUrl && (
                        <div>
                          <h3>Final Image</h3>
                          <img
                            width={"100%"}
                            height={"100%"}
                            alt="Crop"
                            src={croppedImageUrl}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-xs-12">
                  <TextField
                    error={nameError ? true : false}
                    helperText={nameError}
                    type="text"
                    name="name"
                    label="Name"
                    id="name"
                    fullWidth
                    placeholder="Add Name"
                    InputLabelProps={{
                      shrink: true
                    }}
                    margin="normal"
                    onChange={event => {
                      this.setState({
                        name: event.target.value,
                        nameError: "",
                        error: ""
                      });
                    }}
                    defaultValue={name}
                    disabled={loader ? true : false}
                  />
                </div>
                <div className="col-md-6 col-xs-12">
                  <SimpleSelect
                    fullWidth
                    label="Status"
                    name="status"
                    id="status"
                    value={status}
                    onChange={status => {
                      this.setState({ status });
                    }}
                    disabled={loader ? true : false}
                  />
                </div>
                <div className="col-md-6 col-xs-12">
                  <TextField
                    error={descriptionError ? true : false}
                    helperText={descriptionError}
                    type="text"
                    name="description"
                    label="Description"
                    id="description"
                    fullWidth
                    placeholder="Add Description"
                    InputLabelProps={{
                      shrink: true
                    }}
                    onChange={event => {
                      this.setState({
                        description: event.target.value,
                        descriptionError: "",
                        error: ""
                      });
                    }}
                    margin="normal"
                    defaultValue={description}
                    disabled={loader ? true : false}
                  />
                </div>
                <div className="col-md-12">
                  <br />
                  <h4>How it Works Section</h4>
                  <div className="row">
                    <div className="col-md-6 col-xs-12">
                      <TextField
                        error={howItWorks1Error ? true : false}
                        helperText={howItWorks1Error}
                        id="howItWorks1"
                        label="How it Works Section 1"
                        InputLabelProps={{
                          shrink: true
                        }}
                        name="howItWorks1"
                        placeholder="Add Section 1"
                        fullWidth
                        margin="normal"
                        disabled={loader ? true : false}
                        onChange={event => {
                          this.setState({
                            howItWorks1: event.target.value,
                            howItWorks1Error: ""
                          });
                        }}
                      />
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <TextField
                        error={howItWorks2Error ? true : false}
                        helperText={howItWorks2Error}
                        id="howItWorks2"
                        label="How it Works Section 2"
                        InputLabelProps={{
                          shrink: true
                        }}
                        name="howItWorks2"
                        placeholder="Add Section 2"
                        fullWidth
                        margin="normal"
                        disabled={loader ? true : false}
                        onChange={event => {
                          this.setState({
                            howItWorks2: event.target.value,
                            howItWorks2Error: ""
                          });
                        }}
                      />
                    </div>
                    <div className="col-md-6 col-xs-12">
                      <TextField
                        error={howItWorks3Error ? true : false}
                        helperText={howItWorks3Error}
                        id="howItWorks3"
                        label="How it Works Section 3"
                        InputLabelProps={{
                          shrink: true
                        }}
                        name="howItWorks3"
                        placeholder="Add Section 3"
                        fullWidth
                        margin="normal"
                        disabled={loader ? true : false}
                        onChange={event => {
                          this.setState({
                            howItWorks3: event.target.value,
                            howItWorks3Error: ""
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-12 col-xs-12">
                  <div className="mt-3" />
                  {this.renderButton()}
                </div>
              </form>
            </CardBox>
          </div>
        </div>
      </HOCLoader>
    );
  }
}

export default connect(
  null,
  actions
)(CategoryCreate);
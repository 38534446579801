import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import Gallery from "react-grid-gallery";
import PropTypes from "prop-types";
import Dropzone from "react-dropzone";
import axios from "axios";
import classNames from "classnames";
import { FormattedMessage } from "react-intl";
import ReactCrop from 'react-image-crop';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { NotificationManager } from "react-notifications";
import {
  Toolbar,
  Typography,
  Tooltip,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import { CloudUpload } from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import { AddToQueue } from '@material-ui/icons';
import { lighten } from "@material-ui/core/styles/colorManipulator";
import { Paper, Grid } from '@material-ui/core';
import "react-image-crop/dist/ReactCrop.css";
import * as actions from "../../actions";
import { connect } from "react-redux";
import { compose } from "redux";
import { isMobile } from "react-device-detect";
import WSButton from "../Button/WSButton";
import DeleteAlert from "../Alert/DeleteAlert";
import { getCookies } from "../../util/getToken";
import WSTextField from "../TextField/WSTextField";
import ReactPlayer from "react-player";
import { errorResponse, validateApiData } from "../../util/validators/validators";
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const handleDropRejected = (...args) =>
  NotificationManager.error(
    <FormattedMessage
      id="errorUploading25mb"
      defaultMessage="Error uploading Image, try another image with maximum size 25MB"
    />,
    "Error"
  );

const toolbarStyles = (theme) => ({
  root: {
    //paddingRight: theme.spacing.unit
  },
  highlight:
    theme.palette.type === "light"
      ? {
        color: theme.palette.secondary.main,
        backgroundColor: lighten(theme.palette.secondary.light, 0.85),
      }
      : {
        color: theme.palette.text.primary,
        backgroundColor: theme.palette.secondary.dark,
      },
  spacer: {
    flex: "1 1 100%",
  },
  actions: {
    color: theme.palette.secondary.main,
  },
  title: {
    flex: "0 0 auto",
  },
});

let GalleryListToolbar = (props) => {
  const {
    numSelected,
    classes,
    deleteAction,
    markDefault,
    creategallery,
    createVideoGallery,
    showUploadImageForm,
    showUploadVideoForm,
    totalCount,
    providerTypeId,
    selectedGenereName,
    isEditable,
  } = props;
  return (
    <div>
      {isEditable && totalCount < 10 && !showUploadImageForm && (
        <WSButton
          variant="outlined"
          onClick={creategallery}
          style={{ marginRight: 20 }}
        >
          + Upload Images
        </WSButton>
      )}
      {isEditable && totalCount < 10 && !showUploadVideoForm && (
        <WSButton variant="outlined" onClick={createVideoGallery}>
          + Add Video Link
        </WSButton>
      )}
      <Toolbar
        className={classNames(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
        style={{ padding: 0 }}
      >
        <div className={classes.title}>
          {numSelected > 0 && (
            <Typography
              variant="subtitle1"
              style={{ color: "grey", marginLeft: 20 }}
            >
              {numSelected} selected
            </Typography>
          )}
        </div>
        <div className={classes.spacer} />
        <div className={classes.actions}>
          {numSelected === 1 ? (
            <Tooltip title="Make Default">
              <IconButton aria-label="Make Default">
                <AddToQueue onClick={markDefault} />
              </IconButton>
            </Tooltip>
          )
            : (
              <div></div>
            )}
          {numSelected > 0 ? (
            <Tooltip title="Delete">
              <IconButton aria-label="Delete">
                <DeleteIcon onClick={deleteAction} />
              </IconButton>
            </Tooltip>
          ) : (
            <div></div>
          )}
        </div>
      </Toolbar>
    </div>
  );
};

GalleryListToolbar.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
};

GalleryListToolbar = withStyles(toolbarStyles)(GalleryListToolbar);

class PortfolioGallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteAlert: false,
      images: [],
      files: [],
      selected: [],
      imgIndex: 0,
      showUploadImageForm:
        props?.selectedGenereId &&
          props?.providerTypeProfileData?.images?.length == 0
          ? true
          : false,
      showUploadVideoForm:
        props?.selectedGenereId &&
          props?.providerTypeProfileData?.videos?.length == 0
          ? true
          : false,
      providerTypeProfileData: props?.providerTypeProfileData,
      selectedGenereId: props?.selectedGenereId,
      selectedGenereName: props?.selectedGenereName,
      providerTypeId: props?.providerTypeId,
      providerId: props?.providerId,
      totalUploadedPhotos: 0,
      totalPhotos: 0,
      totalUploadedPercentage: 0,
      videoLinks: [Math.random()],
      videoLinkUrls: [],
      imageIndx: 0,
      crop: {
        x: 40,
        y: 40,
        width: 100,
        height: 100,
      },
      croppedImageUrl: '',
      cropUrl: '',
    };
  }

  componentDidMount() {
    let providerTypeProfileData = this.state.providerTypeProfileData;
    let images = [];
    if (
      providerTypeProfileData &&
      providerTypeProfileData.images &&
      providerTypeProfileData.images.length > 0
    ) {
      images = providerTypeProfileData.images.map((image, index) => {
        return {
          id: image.id,
          src: image.filename,
          thumbnail: image.filename,
          //thumbnailWidth: 320,
          //thumbnailHeight: 212,
          // caption:
          //     this.props.providerTypeId === 1
          //         ? 'Photographer'
          //         : this.props.providerTypeId === 2
          //             ? 'Videographer'
          //             : 'Model'
        };
      });
    }
    this.setState({
      images,
    });
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.selectedGenereId !== this.props.selectedGenereId ||
      prevProps.providerTypeProfileData !== this.props.providerTypeProfileData
    ) {
      let images = [];
      if (
        this.props.providerTypeProfileData &&
        this.props.providerTypeProfileData.images &&
        this.props.providerTypeProfileData.images.length > 0
      ) {
        images = this.props.providerTypeProfileData.images.map(
          (image, index) => {
            return {
              id: image.id,
              src: image.filename,
              thumbnail: image.filename,
              //thumbnailWidth: 320,
              //thumbnailHeight: 212,
              // caption:
              //     this.props.providerTypeId === 1
              //         ? 'Photographer'
              //         : this.props.providerTypeId === 2
              //             ? 'Videographer'
              //             : 'Model'
            };
          }
        );
      }

      this.setState({
        images: images || [],
        videoLinkUrls: this.props?.providerTypeProfileData?.videos?.length
          ? this.props.providerTypeProfileData.videos
          : [""],
        videoLinks: this.props?.providerTypeProfileData?.videos?.length
          ? Array(this.props?.providerTypeProfileData?.videos?.length).fill(
            Math.random()
          )
          : [Math.random()],
        selectedGenereId: this.props.selectedGenereId,
        providerTypeId: this.props.providerTypeId,
        selectedGenereName: this.props.selectedGenereName,
        showUploadVideoForm:
          this.props?.providerTypeProfileData?.videos?.length !== 0
            ? true
            : false,
      });
    }
  }

  selectedImages = (index, image) => {
    let images = this.state.images.slice();
    let img = images[index];
    if (img.hasOwnProperty("isSelected")) img.isSelected = !img.isSelected;
    else img.isSelected = true;

    let selected = images.filter((image) => {
      return image.isSelected;
    });
    this.setState({
      images: images,
      selected,
      imageIndx: index,
    });
  };

  deleteRecord = (id) => {
    const { selected } = this.state;
    let ids = selected.map((selectedImg) => {
      return selectedImg.id;
    });
    this.setState({ deleteAlert: true, deleteSelected: ids });
  };
  markDefault = async () => {
    let success = false;
    let response = await this.markDefaultImage();
    const { images, imageIndx } = this.state;
    let Images = images.slice();
    let img = Images[imageIndx];
    if (img.hasOwnProperty("isSelected")) img.isSelected = !img.isSelected;
    this.setState({
      selected: [],
      imageIndx: 0,
    });
    if (response.status === "OK") {
      success = true;
    }
    if (success) {
      NotificationManager.success(
        `Default Image Selected Successfully.`,
        "Success"
      );
    }
  }
  markDefaultImage = () => {
    const { providerTypeId, selectedGenereId, selected } = this.state;
    return new Promise((resolve, reject) => {
      let providerType = '';
      let imageId = '';
      let genereId = '';
      let providerId = '';
      let is_default = false;
      let formData = {};
      let authToken = getCookies();
      providerId = this.state.providerId;
      providerType = providerTypeId;
      imageId = selected.map(item => item.id);
      genereId = selectedGenereId;
      is_default = true;
      formData = new FormData();
      formData.append('providerType', providerType);
      formData.append('imageId', imageId);
      formData.append('providerId', providerId);
      formData.append('genereId', genereId);
      formData.append('is_default', is_default);
      return axios({
        method: "post",
        data: formData,
        url: `${SERVER_URL}/admin/provider/set-default-image`,
        headers: { "x-auth-token": authToken },
      })
        .then(
          (response) => {
            resolve(response.data);
          },
          function (err) {
            resolve({ status: "NOK" });
          }
        )
        .catch((err) => {
          resolve({ status: "NOK" });
        });
    });
  };
  deleteGallery = () => {
    const {
      deleteSelected,
      providerTypeId,
      providerId,
      selectedGenereId,
    } = this.state;
    const { providerDeletePortfolioAction } = this.props;
    providerDeletePortfolioAction(
      {
        id: deleteSelected,
        providerType: providerTypeId,
        providerId: providerId,
        genereId: selectedGenereId,
      },
      this.onDeleteDone
    );
  };

  onDeleteDone = (success, data) => {
    let { deleteSelected, images } = this.state;
    if (success) {
      images = images.filter((image) => {
        return deleteSelected.indexOf(image.id) > -1 ? false : true;
      });
      this.setState({
        deleteAlert: false,
        images,
        selected: [],
        showUploadImageForm: images?.length > 0 ? false : true,
      });
      //   NotificationManager.success(data.message, "Success");
    } else {
      this.setState({
        deleteAlert: false,
        showUploadImageForm: images?.length > 0 ? false : true,
      });
      //   NotificationManager.error(data.message, "Error");
    }
  };

  handleCloseDelete = () => {
    this.setState({ deleteAlert: false });
  };

  showImageUploadForm = () => {
    this.setState({
      showUploadImageForm: true,
    });
  };

  showVideoLinkForm = () => {
    this.setState({
      showUploadVideoForm: true,
    });
  };
  hideVideoLinkForm = () => {
    this.setState({
      showUploadVideoForm: false,
    });
  };

  handleDrop = async (files) => {
    this.setState({
      files: files.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      ),
      showOld: false,
    });
  };
  addDropedImage = async () => {
    const { images, totalUploadedPhotos, croppedImageUrl, files } = this.state;
    this.setState({ loader: true });
    let success = false;
    let uploadedImages = [...images];
    let uploadedCount = 0;
    if (files?.length > 0) {
      for (let file of files) {
        this.setState({
          totalPhotos: files?.length,
          totalUploadedPercentage: 0,
        });
        let response = await this.addGalleryImage(croppedImageUrl);
        if (response?.status === 'OK') {
          ++uploadedCount;
          success = true;
          if (
            response?.data?.images?.length
          ) {
            let streamImages = response?.data?.images.map(
              (image, index) => {
                return {
                  id: image?.id,
                  src: image?.filename,
                  thumbnail: image?.filename,
                };
              }
            );
            uploadedImages = streamImages;
          }
          this.setState({
            totalUploadedPhotos: totalUploadedPhotos + 1,
          });
        } else {
          this.setState({
            totalUploadedPercentage: 0,
          });
        }
      }
    }

    if (success) {
      NotificationManager.success(
        `${uploadedCount} Images uploaded Successfully.`,
        'Success'
      );
      this.setState({
        loader: false,
        showUploadImageForm: false,
        images: uploadedImages,
        totalUploadedPhotos: 0,
        totalPhotos: 0,
        totalUploadedPercentage: 0,
      });
    } else {
      this.setState({
        loader: false,
        totalUploadedPhotos: 0,
        totalPhotos: 0,
        totalUploadedPercentage: 0,
      });
    }
  }
  onImageLoaded = (image, pixelCrop) => {
    this.imageRef = image;
  };

  onCropComplete = async (crop) => {
    if (this.imageRef && crop?.width && crop?.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg'
      );
      this.setState({ croppedImageUrl });
    }
  };

  getCroppedImg = (image, pixelCrop, fileName) => {
    const canvas = document.createElement('canvas');
    const scaleX = image?.naturalWidth / image?.width;
    const scaleY = image?.naturalHeight / image?.height;
    canvas.width = pixelCrop?.width;
    canvas.height = pixelCrop?.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(
      image,
      pixelCrop?.x * scaleX,
      pixelCrop?.y * scaleY,
      pixelCrop?.width * scaleX,
      pixelCrop?.height * scaleY,
      0,
      0,
      pixelCrop?.width,
      pixelCrop?.height
    );
    return new Promise((resolve, reject) => {
      const fileUrl = canvas.toDataURL();
      console.log('Koca: fileUrl ', fileUrl);
      this.setState({ cropUrl: fileUrl });
      canvas.toBlob(file => {
        file.name = fileName;
        resolve(file);
      }, 'image/jpeg');
    });
  };

  addGalleryImage = (file) => {
    const { providerTypeId, selectedGenereId, imgIndex } = this.state;
    return new Promise((resolve, reject) => {
      let providerType = "";
      let providerId = "";
      let genereId = "";
      let formData = {};
      providerType = providerTypeId;
      providerId = this.state.providerId;
      genereId = selectedGenereId;
      formData = new FormData();
      formData.append("index", imgIndex);
      formData.append("providerType", providerType);
      formData.append("providerId", providerId);
      formData.append("genereId", genereId);
      formData.append("file", file, "hello.jpg");
      return axios({
        method: "post",
        data: formData,
        url: `${SERVER_URL}/admin/provider/add-portfolio`,
        headers: { "x-auth-token": getCookies() },
        onUploadProgress: (progressEvent) => {
          const totalLength = progressEvent.lengthComputable
            ? progressEvent.total
            : progressEvent.target.getResponseHeader("content-length") ||
            progressEvent.target.getResponseHeader(
              "x-decompressed-content-length"
            );
          if (totalLength !== null) {
            let progress = Math.round(
              (progressEvent.loaded * 100) / totalLength
            );
            this.setState({
              totalUploadedPercentage: progress,
            });
          }
        },
      })
        .then(
          (response) => {
            resolve(response.data);
          },
          function (err) {
            resolve({ status: "NOK" });
          }
        )
        .catch((err) => {
          resolve({ status: "NOK" });
        });
    });
  };

  addVideoLinks = () => {
    const {
      providerTypeId,
      selectedGenereId,
      imgIndex,
      videoLinkUrls,
    } = this.state;
    return new Promise((resolve, reject) => {
      const finalData = {
        providerType: providerTypeId,
        providerId: this.state.providerId,
        genereId: selectedGenereId,
        videos: videoLinkUrls,
      };
      return axios({
        method: "post",
        data: finalData,
        url: `${SERVER_URL}/admin/provider/add-portfolio`,
        headers: { "x-auth-token": getCookies() },
      })
        .then(
          (response) => {
            if (response.data.status === "OK") {
              NotificationManager.success("Video Links Saved", null, 2000);
            }
            resolve(response.data);
          },
          function (err) {
            resolve({ status: "NOK" });
          }
        )
        .catch((err) => {
          resolve({ status: "NOK" });
        });
    });
  };

  addField = () => {
    const { videoLinks } = this.state;
    videoLinks.push(Math.random());
    this.setState({
      videoLinks,
    });
  };

  removeField = (item, index) => {
    const { videoLinks, videoLinkUrls } = this.state;
    videoLinks.splice(videoLinks.indexOf(item), 1);
    videoLinkUrls.splice(index, 1);
    this.setState({
      videoLinks,
      videoLinkUrls,
    });
  };

  renderButton = () => {
    const {
      loader,
      totalUploadedPercentage,
      totalUploadedPhotos,
      totalPhotos,
    } = this.state;
    if (loader) {
      return (
        <div
          style={{
            color: "green",
            fontSize: "14px",
            fontWeight: 600,
          }}
        >
          <div>
            {totalUploadedPhotos} of {totalPhotos} Photos
          </div>
          <CircularProgress style={{ margin: "15px" }} />
          <div>{totalUploadedPercentage} % Progress</div>
        </div>
      );
    }
  };

  render() {
    const {
      images,
      selected,
      showUploadImageForm,
      showUploadVideoForm,
      providerTypeId,
      selectedGenereName,
      deleteAlert,
      videoLinks,
      videoLinkUrls,
      files,
      crop,
      croppedImageUrl,
      cropUrl,
    } = this.state;
    const { isEditable, match } = this.props;
    const baseStyle = {
      width: 200,
      height: 200,
      borderWidth: 2,
      borderColor: "#666",
      borderStyle: "dashed",
      borderRadius: 5,
    };
    const activeStyle = {
      borderStyle: "solid",
      borderColor: "#6c6",
      backgroundColor: "#eee",
    };
    const rejectStyle = {
      borderStyle: "solid",
      borderColor: "#c66",
      backgroundColor: "#eee",
    };
    const thumbs = files.map((file, index) => (
      <div key={index}>
        <h6 style={{ textTransform: 'capitalize' }}>
          <FormattedMessage
            id="customer.originalimage"
            defaultMessage="Original Image"
          />
        </h6>
        <ReactCrop
          src={file?.preview}
          imageStyle={{
            width: '100%',
            height: '100%',
            maxHeight: 'none',
          }}
          onImageLoaded={this.onImageLoaded}
          onComplete={this.onCropComplete}
          crop={crop}
          onChange={(crop) => {
            this.setState({ crop });
          }}
        />
      </div>
    ));
    return (
      <div className="col-md-12 col-xs-12 mt-3">
        {isEditable && (
          <GalleryListToolbar
            isEditable={isEditable}
            numSelected={selected?.length}
            markDefault={this.markDefault}
            deleteAction={this.deleteRecord}
            match={match}
            serviceId={1}
            creategallery={this.showImageUploadForm}
            createVideoGallery={this.showVideoLinkForm}
            showUploadImageForm={showUploadImageForm}
            showUploadVideoForm={showUploadVideoForm}
            totalCount={images?.length}
            providerTypeId={providerTypeId}
            selectedGenereName={selectedGenereName}
          />
        )}
        {((showUploadImageForm && isEditable) ||
          (images?.length === 0 && isEditable)) && (
            <div>
              <div className="row">
                <div className="col-md-12 col-xs-12 mt-3">
                  {this.renderButton()}
                </div>
              </div>
              <div className="row col-md-12">
                <div className="col-md-6 m-auto">
                  <h5 className={isMobile && "font-14"}>
                    <FormattedMessage
                      id="customer.drop"
                      defaultMessage="Drop Multiple Image here to Upload"
                    />
                  </h5>
                  <Dropzone
                    onDrop={this.handleDrop}
                    accept="image/jpeg,image/jpg,image/png"
                    multiple={true}
                    maxSize={25000000}
                    onDropRejected={handleDropRejected}
                  >
                    {({
                      getRootProps,
                      getInputProps,
                      isDragActive,
                      isDragAccept,
                      isDragReject,
                      acceptedFiles,
                      rejectedFiles,
                    }) => {
                      let styles = { ...baseStyle };
                      styles = isDragActive
                        ? { ...styles, ...activeStyle }
                        : styles;
                      styles = isDragReject
                        ? { ...styles, ...rejectStyle }
                        : styles;
                      {
                        return (
                          <div style={styles} className="dropbox">
                            <div className="inner">
                              <CloudUpload />
                              <p>
                                <FormattedMessage
                                  id="customer.dropfile"
                                  defaultMessage="Drop multiple files here, or click to select files"
                                />
                              </p>
                            </div>
                          </div>
                        );
                      }
                    }}
                  </Dropzone>
                </div>
              </div>
              {thumbs.length > 0 && (
                <div className="col-md-4 ml-15 mb-20">
                  {thumbs}
                </div>
              )}
              <div className="col-md-5 mb-20">
                {croppedImageUrl && (
                  <Fragment>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={4}
                      lg={4}
                    >
                      <h6 className="text-capitalize">
                        <FormattedMessage
                          id="customer.finalimage"
                          defaultMessage="Final Image"
                        />
                      </h6>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          alignItems: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <LazyLoadImage
                          width={'200px'}
                          height={'200px'}
                          alt="Crop"
                          src={cropUrl}
                        />
                        <WSButton
                          onClick={
                            this.addDropedImage
                          }
                        >
                          Update
                                                    </WSButton>
                      </div>
                    </Grid>
                  </Fragment>
                )}
              </div>
            </div>
          )}

        {((showUploadVideoForm && isEditable) ||
          (videoLinks?.length === 1 && images?.length === 0 && isEditable)) && (
            <div>
              <form className="dialog-form dialog-form-new" autoComplete="off">
                <div className="col-md-4 col-xs-12">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop: 20,
                      marginBottom: 20,
                    }}
                  >
                    <span>Video Links</span>
                    <WSButton
                      style={{
                        color: "#ffffff",
                        borderRadius: 10,
                        width: 130,
                        height: 40,
                        marginLeft: 10,
                        padding: 0,
                      }}
                      onClick={this.addField}
                    >
                      Add New Link
                  </WSButton>
                  </div>
                  {this.state.videoLinks &&
                    this.state.videoLinks.length &&
                    this.state.videoLinks.map((item, index) => {
                      return (
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <WSTextField
                            className="login-form-textinput mt-0"
                            id="videoLinks"
                            name="videoLinks"
                            placeholder={"Video Link"}
                            onChange={(event) => {
                              let tempLinks = this.state.videoLinkUrls || [];
                              tempLinks[index] = event.target.value;
                              this.setState({
                                videoLinkUrls: tempLinks,
                              });
                            }}
                            value={
                              this.state?.videoLinkUrls &&
                              this.state?.videoLinkUrls[index]
                            }
                          />
                          <WSButton
                            style={{
                              color: "#ffffff",
                              borderRadius: 10,
                              width: 170,
                              height: 40,
                              marginLeft: 10,
                              padding: 0,
                            }}
                            onClick={() => this.removeField(item, index)}
                          >
                            Remove Link
                        </WSButton>
                        </div>
                      );
                    })}
                </div>
                <div className="flex-justify-spacebetween mt-2">
                  <div>
                    <WSButton
                      style={{
                        color: "#ffffff",
                        margin: !isMobile && 20,
                        borderRadius: 30,
                      }}
                      onClick={this.hideVideoLinkForm}
                    >
                      Cancel
                  </WSButton>
                    <WSButton
                      style={{
                        color: "#ffffff",
                        margin: !isMobile && 20,
                        borderRadius: 30,
                      }}
                      onClick={this.addVideoLinks}
                    >
                      Save
                  </WSButton>
                  </div>
                </div>
              </form>
            </div>
          )}

        <Gallery
          images={images}
          showImageCount={true}
          enableImageSelection={isEditable ? true : false}
          onSelectImage={(selectedImages, image) => {
            this.selectedImages(selectedImages);
          }}
        />
        <DeleteAlert
          open={deleteAlert}
          close={deleteAlert}
          handleNo={this.handleCloseDelete}
          handleYes={this.deleteGallery}
        />

        {isEditable && (
          <div style={{ display: "flex", flexDirection: "row" }}>
            {videoLinkUrls && videoLinkUrls.length ? (
              videoLinkUrls.map((link) => {
                return (
                  <ReactPlayer
                    className="mr-2"
                    controls
                    width="300px"
                    height="200px"
                    url={link}
                  />
                );
              })
            ) : (
              <></>
            )}
          </div>
        )}
      </div>
    );
  }
}

const composedComponent = compose(
  withStyles(toolbarStyles),
  connect(null, actions)
);

export default composedComponent(PortfolioGallery);

import React, { lazy } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import keycode from "keycode";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableFooter from "@material-ui/core/TableFooter";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import AddIcon from "@material-ui/icons/Add";
import { Add } from "@material-ui/icons";
import { FormattedMessage } from "react-intl";
import WSButton from "../Button/WSButton";
import IntlMessages from "../../util/IntlMessages";
import { Link, Redirect } from "react-router-dom";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import MenuButton from "./MenuButton";
import { DebounceInput } from "react-debounce-input";
import SearchBox from "../SearchBox/index";
const ProviderServiceCreate = lazy(() =>
  import("../MyComponents/ProviderServiceCreate")
);

class DataTableHead extends React.Component {
  static propTypes = {
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.string.isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };
  state = {
    clickValue: null,
    itemId: null,
    type: "",
    addBtn: null,
  };

  createSortHandler = (property) => (event) => {
    this.props.onRequestSort(event, property);
  };

  handleOnChangeDebounce = ({ target: { value } }) => {
    this.setState({ value });

    const filteredArray = this.props.actualData.filter((dataItem) => {
      let nameMatch = dataItem.name.toLowerCase();
      if (dataItem.price != null) {
        var priceMatch = dataItem.price;
      }
      let valueMatch = value.toLowerCase();

      if (nameMatch.includes(valueMatch) || priceMatch == valueMatch) {
        return true;
      }
      return false;
    });
    this.props.changeData(filteredArray);
  };
  render() {
    const {
      onSelectAllClick,
      order,
      orderBy,
      numSelected,
      rowCount,
      headingList,
      match,
      title,
    } = this.props;
    return (
      <TableHead>
        <TableRow>
          <TableCell
            colSpan={7}
            key={1}
            // numeric={column.numeric}
            padding="default"
          >
            <DebounceInput
              element={SearchBox}
              minLength={2}
              debounceTimeout={300}
              onChange={this.handleOnChangeDebounce}
            />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell padding="checkbox"></TableCell>
          {headingList.map((column) => {
            return (
              <TableCell key={column.id} padding="default">
                <Tooltip
                  title="Sort"
                  placement={column.numeric ? "bottom-end" : "bottom-start"}
                  enterDelay={300}
                >
                  <div>
                    <TableSortLabel
                      active={orderBy === column.id}
                      direction={order}
                      onClick={this.createSortHandler(column.id)}
                    >
                      {column.name}

                      {column.label}
                    </TableSortLabel>
                  </div>
                </Tooltip>
              </TableCell>
            );
          }, this)}
        </TableRow>
      </TableHead>
    );
  }
}

let DataTableToolbar = (props) => {
  const { numSelected, match, title } = props;

  const styles = (theme) => ({
    button: {
      margin: theme.spacing.unit,
    },
  });

  return (
    <Toolbar
      className={classNames("table-header serviceHeader", {
        ["highlight-light"]: numSelected > 0,
      })}
    >
      <div className="title col-md-6 text-left">
        <h2>
          <IntlMessages id={title} />
        </h2>
      </div>
    </Toolbar>
  );
};

DataTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

class CategoryTable extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      anchorEl: undefined,
      menuState: false,
      order: "asc",
      orderBy: "name",
      selected: [],
      data: this.props.actualData,
      page: 0,
      rowsPerPage: 10,
    };
  }

  onOptionMenuSelect = (event) => {
    this.setState({ menuState: true, anchorEl: event.currentTarget });
  };

  handleRequestClose = (id, clickedValue) => {
    if (clickedValue == "view") {
      this.setState({ clickValue: "view", itemId: id, menuState: false });
    } else if (clickedValue == "update") {
      this.setState({ clickValue: "updates", itemId: id, menuState: false });
    }
    this.setState({ menuState: false });
  };

  handleRequestSort = (event, property) => {
    const orderBy = property;
    let order = "desc";
    const { actualData } = this.props;

    if (this.state.orderBy === property && this.state.order === "desc") {
      order = "asc";
    }
    const Services = this.state.data === actualData ? this.state.data : actualData;
    const data =
      order === "desc"
        ? Services.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
        : Services.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));

    this.setState({ data, order, orderBy });
  };
  handleSelectAllClick = (event, checked) => {
    if (checked) {
      this.setState({ selected: this.props.actualData.map((n) => n.key) });
      return;
    }
    this.setState({ selected: [] });
  };
  handleKeyDown = (event, id) => {
    if (keycode(event) === "space") {
      this.handleClick(event, id);
    }
  };
  handleClick = (event, id) => {
    const { selected } = this.state;
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    this.setState({ selected: newSelected });
  };
  handleChangePage = (event, page) => {
    this.setState({ page });
  };
  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };
  actionClick(event, id) { }
  isSelected = (id) => this.state.selected.indexOf(id) !== -1;

  render() {
    const { match, actualData, title, type, providerId } = this.props;

    const {
      data,
      order,
      orderBy,
      selected,
      rowsPerPage,
      page,
      anchorEl,
      menuState,
      detail = {},
      loader = true,
    } = this.state;
    const styles = (theme) => ({
      button: {
        margin: theme.spacing.unit,
      },
      extendedIcon: {
        marginRight: theme.spacing.unit,
      },
    });
    const serviceData = data === actualData ? data : actualData;
    return (
      <Paper>
        <DataTableToolbar
          match={match}
          numSelected={selected.length}
          title={title}
        />
        <WSButton
          rowData={actualData}
          className="addServicesBtn"
          variant="outlined"
        >
          <Link
            style={{
              textDecoration: "none",
              color: "#e91e63",
              fontSize: "14px",
            }}
            to={{
              pathname: `/app/providerService/create`,
              state: {
                // userId: actualData.length ? actualData[0].createdBy : "",
                userId: providerId,
              },
            }}
          >
            Add Service
          </Link>
        </WSButton>
        <div className="flex-auto">
          <div className="table-responsive-material">
            <Table className="">
              <DataTableHead
                changeData={(data) => this.setState({ data })}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                // onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={this.handleRequestSort}
                rowCount={actualData.length}
                headingList={this.props.headings}
                match={this.props.match}
                title={this.props.title}
                actualData={actualData}
              />
              <TableBody>
                {serviceData !== "No service found" ? (
                  serviceData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((n) => {
                      const isSelected = this.isSelected(n.key);
                      return (
                        <TableRow
                          hover
                          onKeyDown={(event) =>
                            this.handleKeyDown(event, n.key)
                          }
                          // role="checkbox"
                          aria-checked={isSelected}
                          tabIndex={-1}
                          key={n.key}
                          selected={isSelected}
                        >
                          <TableCell
                          // padding="checkbox"
                          // onClick={(event) => this.handleClick(event, n.key)}
                          >
                            {/* <Checkbox color="primary" checked={isSelected} /> */}
                          </TableCell>

                          <TableCell>{n.name}</TableCell>
                          <TableCell>{n.price}</TableCell>
                          <TableCell>{n.packageCount}</TableCell>
                          <TableCell className="status-cell">
                            <div
                              className={
                                n.status == "1"
                                  ? `badge text-uppercase text-white bg-success`
                                  : `badge text-uppercase text-white bg-warning`
                              }
                            >
                              {n.status == "1" ? "Active" : "Inactive"}
                            </div>
                          </TableCell>
                          <TableCell>
                            <WSButton className="mr-10" variant="outlined">
                              <Link
                                style={{
                                  textDecoration: "none",
                                  color: "#e91e63",
                                  fontSize: "14px",
                                }}
                                to={{
                                  pathname: `/app/providerService/view`,
                                  state: { itemId: n.key },
                                }}
                              >
                                View
                              </Link>
                            </WSButton>
                            <WSButton className="mr-10 ml-5" variant="outlined">
                              <Link
                                style={{
                                  textDecoration: "none",
                                  color: "#e91e63",
                                  fontSize: "14px",
                                }}
                                to={{
                                  pathname: `/app/providerService/update`,
                                  state: {
                                    itemId: n.key,
                                    // userId: n.createdBy,
                                    userId: providerId,
                                  },
                                }}
                              >
                                Update
                              </Link>
                            </WSButton>
                          </TableCell>
                        </TableRow>
                      );
                    })
                ) : (
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell className="noDataFound">
                      No Service Found
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    count={serviceData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={this.handleChangePage}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </div>
        </div>
      </Paper>
    );
  }
}

export default CategoryTable;

import React, { lazy } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import IntlMessages from '../../../util/IntlMessages';
import CircularProgress from "@material-ui/core/CircularProgress";
import { custCreate } from "../../../apiHelpers/user/create";
import { validateCustomerForm } from "../../../util/validators/validateForm";
import { NotificationManager } from "react-notifications";
import * as actions from "../../../actions";
import { connect } from "react-redux";
import ReactCrop from "react-image-crop";
import Dropzone from "react-dropzone";
import "react-image-crop/dist/ReactCrop.css";

const ContainerHeader = lazy(() => import("../../../components/ContainerHeader/index"));
const CardBox = lazy(() => import("../../../components/CardBox/index"));
const CustomError = lazy(() => import("../../../components/MyComponents/common/CustomError"));
const HOCLoader = lazy(() => import("../../../components/MyComponents/HOCLoader"));
const SimpleSelect = lazy(() => import("../components/routes/selects/simple/SimpleSelect"));
const MySwitch = lazy(() => import("../../../components/MyComponents/MySwitch"));

const handleDropRejected = (...args) => console.log("reject", args);
class CustomerUpdate extends React.Component {
  constructor(props) {
    super();
    this.state = {
      error: "",
      nameError: "",
      emailError: "",
      email: "",
      name: "",
      phone: "",
      phoneError: "",
      loader: true,
      loaderButton: false,
      pass: false,
      noResponse: false,
      errorMessage: "",
      preview: null,
      generalPhotos: "",
      status: 1,
      crop: {
        x: 40,
        y: 40,
        width: 100,
        height: 100,
      },
      is_blocked: false,
      is_deleted: false,
      userId: props.location.state ? props.location.state.userId : "",
      customerDetail: "",
      existingImage: ""
    };

    this.handleDrop = this.handleDrop.bind(this);
  }
  componentDidMount() {
    if (!this.props.location.state) {
      return this.props.history.push("/app/user/customers");
    } else {
      this.getData(this.props.location.state.userId);
    }
  }
  //apicall
  getData = async userId => {
    this.props.customerDetailAction(
      {
        id: userId
      },
      this.onDoneDetail
    );
  };

  onDoneDetail = (success, data) => {
    if (success) {
      this.setState(
        {
          customerDetail: data.detail,
          is_deleted: data.detail.is_deleted == 1,
          is_blocked: data.detail.is_blocked == 1,
          name: data.detail.name,
          email: data.detail.email,
          existingImage: data.detail.image.medium
        },
        () => {
          this.setState({ loader: false });
        }
      );
    } else {
      this.setState({ loader: false, noResponse: true, error: data });
    }
  };
  //apicall

  submit = params => {
    const { name, email, phone, is_blocked, is_deleted } = this.state;
    if (validateCustomerForm({ name, email }, this.handleError)) {
      this.setState({ loaderButton: true });
      this.props.customerUpdateAction(
        {
          customerId: this.state.userId,
          name,
          email,
          phone,
          is_blocked: is_blocked ? 1 : 2,
          is_deleted: is_deleted ? 1 : 2,
          image: this.state.croppedImageUrl
        },
        this.onDone
      );
    } else {
      NotificationManager.error("Validation Error", "Oops!");
    }
  };
  handleError = params => {
    this.setState(params);
  };

  onDone = (success, data) => {
    setTimeout(() => {
      this.setState({ loaderButton: false });
      if (success) {
        return this.props.history.push("/app/user/customers");
      }
    }, 4000);
  };
  renderButton = () => {
    if (this.state.loaderButton) {
      return <CircularProgress />;
    }
    return (
      <Button
        variant="raised"
        color="primary"
        className="jr-btn"
        onClick={this.submit}
      >
        Update
      </Button>
    );
  };
  handleDrop([{ preview }]) {
    this.setState({ preview });
  }

  onImageLoaded = (image, pixelCrop) => {
    this.imageRef = image;
  };
  onCropComplete = async (crop) => {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ croppedImageUrl });
    }
  };

  getCroppedImg(image, pixelCrop, fileName) {
    const canvas = document.createElement("canvas");

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      pixelCrop.x * scaleX,
      pixelCrop.y * scaleY,
      pixelCrop.width * scaleX,
      pixelCrop.height * scaleY,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );

    return new Promise((resolve, reject) => {
      this.fileUrl = canvas.toDataURL();
      resolve(this.fileUrl);
    });
  }

  render() {
    const { match } = this.props;
    const {
      error,
      emailError,
      nameError,
      phoneError,
      preview,
      croppedImageUrl,
      is_blocked,
      is_deleted,
      customerDetail,
      loaderButton,
      existingImage
    } = this.state;
    return (
      <HOCLoader
        loading={this.state.loader}
        noResponse={this.state.noResponse}
        redirect={this.state.redirect}
        redirectTo={`/app/user/customers`}
        errorShow={error}
      >
        <div className="animated slideInUpTiny animation-duration-3">
          <ContainerHeader
            title={<IntlMessages id="update-customer" />}
            match={match}
          />

          <div className="row">
            <CardBox styleName="col-lg-12">
              <form
                className="row"
                noValidate
                autoComplete="off"
                id="customer-create"
              >
                <div className="row col-md-12">
                  {!preview && (
                    <div className="col-md-2 mr-4">
                      {existingImage && (
                        <div>
                          <h3>Customer Existing Image</h3>
                          <img
                            width={"200px"}
                            height={"200px"}
                            alt="existingImage"
                            src={existingImage}
                          />
                        </div>
                      )}
                    </div>
                  )}
                  <div className="col-md-2">
                    <h3>Drop Image here to Upload</h3>
                    <Dropzone
                      onDrop={this.handleDrop}
                      accept="image/jpeg,image/jpg,image/png"
                      multiple={false}
                      onDropRejected={handleDropRejected}
                    >
                      <p> Drag a file here or click to upload.</p>
                    </Dropzone>
                  </div>
                  <div className="col-md-5">
                    {preview && (
                      <div>
                        <h3>Original Image</h3>
                        <ReactCrop
                          src={preview}
                          imageStyle={{
                            width: "100%",
                            height: "100%"
                          }}
                          onImageLoaded={this.onImageLoaded}
                          onComplete={this.onCropComplete}
                          crop={this.state.crop}
                          onChange={crop => {
                            this.setState({ crop });
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div className="col-md-5">
                    {croppedImageUrl && (
                      <div>
                        <h3>Final Image</h3>
                        <img
                          width={"200px"}
                          height={"200px"}
                          alt="Crop"
                          src={croppedImageUrl}
                        />
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-md-4 col-xs-12">
                  <TextField
                    error={nameError ? true : false}
                    helperText={nameError}
                    type="text"
                    name="name"
                    label="Name"
                    id="name"
                    fullWidth
                    placeholder="Add Name"
                    InputLabelProps={{
                      shrink: true
                    }}
                    margin="normal"
                    onChange={event => {
                      this.setState({
                        name: event.target.value,
                        nameError: "",
                        error: ""
                      });
                    }}
                    defaultValue={customerDetail.name}
                    disabled={loaderButton ? true : false}
                  />
                </div>
                <div className="col-md-4 col-xs-12">
                  <TextField
                    error={emailError ? true : false}
                    helperText={emailError}
                    type="email"
                    name="email"
                    label="Email"
                    id="email"
                    fullWidth
                    placeholder="Add Email"
                    InputLabelProps={{
                      shrink: true
                    }}
                    onChange={event => {
                      this.setState({
                        email: event.target.value,
                        emailError: "",
                        error: ""
                      });
                    }}
                    margin="normal"
                    defaultValue={customerDetail.email}
                    disabled={loaderButton ? true : false}
                  />
                </div>
                <div className="col-md-4 col-xs-12">
                  <TextField
                    error={phoneError ? true : false}
                    helperText={phoneError}
                    type="phone"
                    name="phone"
                    label="Phone"
                    id="phone"
                    fullWidth
                    placeholder="Add Phone"
                    InputLabelProps={{
                      shrink: true
                    }}
                    onChange={event => {
                      this.setState({
                        phone: event.target.value,
                        phoneError: "",
                        error: ""
                      });
                    }}
                    margin="normal"
                    defaultValue={customerDetail.phone}
                    disabled={loaderButton ? true : false}
                  />
                </div>
                <div className="col-md-3">
                  <MySwitch
                    onChange={() =>
                      this.setState(prevState => ({
                        is_blocked: !prevState.is_blocked
                      }))
                    }
                    label="Blocked User"
                    name="is_blocked"
                    checked={is_blocked}
                  />
                </div>

                <div className="col-md-3">
                  <MySwitch
                    onChange={() =>
                      this.setState(prevState => ({
                        is_deleted: !prevState.is_deleted
                      }))
                    }
                    label="Deactivate User"
                    name="is_deleted"
                    checked={is_deleted}
                  />
                </div>
                <div className="col-md-12 col-xs-12">
                  <div className="mt-3" />
                  {this.renderButton()}
                </div>
              </form>
            </CardBox>
          </div>
        </div>
      </HOCLoader>
    );
  }
}

export default connect(
  null,
  actions
)(CustomerUpdate);

import React, { lazy } from "react";
import IntlMessages from "../../../util/IntlMessages";
import Table from "../table/index";
import * as actions from "../../../actions";
import { connect } from "react-redux";

const ContainerHeader = lazy(() =>
  import("../../../components/ContainerHeader/index")
);
const ProviderServiceView = lazy(() =>
  import("../../../components/MyComponents/ProviderServiceView")
);
const ProviderServiceUpdate = lazy(() =>
  import("../../../components/MyComponents/ProviderServiceUpdate")
);
const HOCLoader = lazy(() =>
  import("../../../components/MyComponents/HOCLoader")
);

class ProviderService extends React.Component {
  constructor(props) {
    super();
    this.state = {
      error: "",
      mainDetail: {},
      redirect: false,
      loader: true,
      itemId: props.location.state ? props.location.state.itemId : props.itemId,
      userId: props.location.state ? props.location.state.userId : props.userId,
    };
  }
  //apiCall

  getData = async (itemId) => {
    this.props.proServiceDetailAction(
      {
        providerServiceId: itemId,
      },
      this.onDone
    );
  };

  onDone = (success, data) => {
    if (success) {
      this.setState({ mainDetail: data, loader: false });
    } else {
      this.setState({ loader: false, noResponse: true });
    }
  };

  //apiCall

  componentDidMount() {
    if (this.state.itemId) {
      this.getData(this.state.itemId);
    } else {
      this.setState({ loader: false, redirect: true });
    }
  }

  render() {
    const { mainDetail, userId } = this.state;
    return (
      <HOCLoader
        loading={this.state.loader}
        noResponse={this.state.noResponse}
        redirect={this.state.redirect}
        redirectTo={`/app/user/providers`}
      >
        <div className="dashboard animated slideInUpTiny animation-duration-3">
          <ContainerHeader
            match={this.props.match}
            title={<IntlMessages id="providerservicedetail" />}
            isPath
            pagePath={`/app/provider/update/${userId}`}
          />
          <div className="row">
            <div className="col-lg-12 col-sm-12 col-12">
              <div className="jr-card">
                <div className="jr-card-body">
                  <ProviderServiceUpdate detail={mainDetail} {...this.props} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </HOCLoader>
    );
  }
}

export default connect(null, actions)(ProviderService);

import React, { lazy } from "react";
import { connect } from "react-redux";

import IntlMessages from '../../../util/IntlMessages';

import * as actions from "../../../actions";

const ContainerHeader = lazy(() => import("../../../components/ContainerHeader/index"));
const ServiceTable = lazy(() => import("../../../components/MyComponents/ServiceTable"));
const CategoryView = lazy(() => import("../../../components/MyComponents/CategoryView"));
const HOCLoader = lazy(() => import("../../../components/MyComponents/HOCLoader"));

class CategoryDetail extends React.Component {
  constructor(props) {
    super();
    this.state = {
      error: "",
      mainDetail: {},
      redirect: false,
      loader: true,
      itemId: props.location.state ? props.location.state.itemId : ""
    };
  }

  getData = async itemId => {
    this.props.categoryDetailAction(
      {
        id: itemId
      },
      this.onDone
    );
  };

  onDone = (success, data) => {
    if (success) {
      this.setState({ mainDetail: data, loader: false });
    } else {
      this.setState({ loader: false, noResponse: true });
    }
  };

  componentDidMount() {
    if (this.state.itemId) {
      this.getData(this.state.itemId);
    } else {
      this.setState({ loader: false, redirect: true });
    }
  }

  render() {
    const { mainDetail } = this.state;
    const { title = "Services", match } = this.props;
    return (
      <HOCLoader
        loading={this.state.loader}
        noResponse={this.state.noResponse}
        redirect={this.state.redirect}
        redirectTo={`/app/category/list`}
      >
        <div className="dashboard animated slideInUpTiny animation-duration-3">
          <ContainerHeader
            match={this.props.match}
            title={<IntlMessages id="categorydetail" />}
          />
          <div className="row">
            <div className="col-lg-12 col-sm-12 col-12">
              <div className="jr-card">
                <div className="jr-card-body">
                  <div className="row">
                    <CategoryView detail={mainDetail} />
                    <div className="col-lg-12 col-sm-12 col-12">
                      {mainDetail.services && (
                        <ServiceTable
                          headings={[
                            { name: "Name", id: "name" },
                            { name: "Category", id: "categoryName" },
                            { name: "Status", id: "status" },
                            { name: "Actions", id: "actions" }
                          ]}
                          title={title}
                          match={match}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </HOCLoader>
    );
  }
}

export default connect(
  null,
  actions
)(CategoryDetail);
import React from "react";
import { TextField } from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';

import IntlMessages from "../../util/IntlMessages";

const SearchBox = ({ styleName, placeholder, onChange, value }) => {
  return (
    <div className={`search-bar right-side-icon bg-transparent ${styleName}`} style={{display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', marginBottom: 20}}>
      <div className="form-group" style={{ marginLeft: 20, width: '25%', borderRadius: 50, height: 35, borderBottom: 0 }} >
        <TextField
          type="search"
          label={<IntlMessages id={placeholder} />}
          fullWidth
          onChange={onChange}
          value={value}
          margin="normal"
          className="mt-1"
        />

        {/* <input
          className="form-control border-0"
          type="search"
          placeholder={placeholder}
          onChange={onChange}
        /> */}
        <div style={{ position: 'absolute', top: 21, right: 20, color: '#3333' }}>
          <SearchIcon />
        </div>
        {/* <button className="search-icon">
        </button> */}
      </div>
    </div>
  );
};
export default SearchBox;

SearchBox.defaultProps = {
  styleName: "",
  value: "",
  placeholder: "Search"
};

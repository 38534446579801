import axios from "axios";
import { NotificationManager } from "react-notifications";

import { errorResponse, validateApiData } from "../../util/validators/validators";
import { getCookies } from "../../util/getToken";

const SERVER_URL = process.env.REACT_APP_SERVER_URL

export const createBasicSettings = async params => {
  let authToken = getCookies();
  try {
    const { data } = await axios({
      method: "post",
      url: `${SERVER_URL}/admin/settings/update-basic`,
      data: params,
      headers: { "x-auth-token": authToken }
    });
    if (data.status == "OK") {
      NotificationManager.success(data.message, "Success!");
    } else if (data.status == "NOK") {
      NotificationManager.error(data.message, "Oops!");
    }
    return validateApiData(data) ? data : errorResponse;
  } catch (e) {
    console.log("Error in user detail", e);
    NotificationManager.error(errorResponse.message, "Oops!");
    return errorResponse;
  }
};

export const createWebsiteSettings = async params => {
  let authToken = getCookies();
  try {
    const { data } = await axios({
      method: "post",
      url: `${SERVER_URL}/admin/settings/update-website`,
      data: params,
      headers: { "x-auth-token": authToken }
    });
    if (data.status == "OK") {
      NotificationManager.success(data.message, "Success!");
    } else if (data.status == "NOK") {
      NotificationManager.error(data.message, "Oops!");
    }
    return validateApiData(data) ? data : errorResponse;
  } catch (e) {
    console.log("Error in user detail", e);
    NotificationManager.error(errorResponse.message, "Oops!");
    return errorResponse;
  }
};

export const createPaymentSettings = async params => {
  let authToken = getCookies();
  try {
    const { data } = await axios({
      method: "post",
      url: `${SERVER_URL}/admin/settings/update-thirdparty`,
      data: params,
      headers: { "x-auth-token": authToken }
    });
    if (data.status == "OK") {
      NotificationManager.success(data.message, "Success!");
    } else if (data.status == "NOK") {
      NotificationManager.error(data.message, "Oops!");
    }
    return validateApiData(data) ? data : errorResponse;
  } catch (e) {
    console.log("Error in user detail", e);
    NotificationManager.error(errorResponse.message, "Oops!");
    return errorResponse;
  }
};

export const createColorSettings = async params => {
  let authToken = getCookies();
  try {
    const { data } = await axios({
      method: "post",
      url: `${SERVER_URL}/admin/settings/update-color`,
      data: params,
      headers: { "x-auth-token": authToken }
    });
    if (data.status == "OK") {
      NotificationManager.success(data.message, "Success!");
    } else if (data.status == "NOK") {
      NotificationManager.error(data.message, "Oops!");
    }
    return validateApiData(data) ? data : errorResponse;
  } catch (e) {
    console.log("Error in user detail", e);
    NotificationManager.error(errorResponse.message, "Oops!");
    return errorResponse;
  }
};

export const setAdminThemeValues = async params => {
  let authToken = getCookies();
  try {
    const { data } = await axios({
      method: "post",
      url: `${SERVER_URL}/admin/admin-settings/update`,
      data: params,
      headers: { "x-auth-token": authToken }
    });
    if (data.status == "OK") {
      NotificationManager.success(data.message, "Success!");
    } else if (data.status == "NOK") {
      NotificationManager.error(data.message, "Oops!");
    }
    return validateApiData(data) ? data : errorResponse;
  } catch (e) {
    console.log("Error in user detail", e);
    NotificationManager.error(errorResponse.message, "Oops!");
    return errorResponse;
  }
};

export const bannerCreate = async params => {
  let authToken = getCookies();
  try {
    const { data } = await axios({
      method: "post",
      url: `${SERVER_URL}/admin/banners/create`,
      data: params,
      headers: { "x-auth-token": authToken }
    });
    if (data.status == "OK") {
      NotificationManager.success(data.message, "Success!");
    } else if (data.status == "NOK") {
      NotificationManager.error(data.message, "Oops!");
    }
    return validateApiData(data) ? data : errorResponse;
  } catch (e) {
    console.log("Error in banner create", e);
    NotificationManager.error(errorResponse.message, "Oops!");
    return errorResponse;
  }
};

export const bannerUpdate = async params => {
  let authToken = getCookies();
  try {
    const { data } = await axios({
      method: "post",
      url: `${SERVER_URL}/admin/banners/update`,
      data: params,
      headers: { "x-auth-token": authToken }
    });
    if (data.status == "OK") {
      NotificationManager.success(data.message, "Success!");
    } else if (data.status == "NOK") {
      NotificationManager.error(data.message, "Oops!");
    }
    return validateApiData(data) ? data : errorResponse;
  } catch (e) {
    console.log("Error in banner create", e);
    NotificationManager.error(errorResponse.message, "Oops!");
    return errorResponse;
  }
};
import React, { lazy } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import IntlMessages from '../../../util/IntlMessages';
import CircularProgress from "@material-ui/core/CircularProgress";
import { validatePromoForm } from "../../../util/validators/validateForm";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import * as actions from "../../../actions";
import { connect } from "react-redux";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import { NotificationManager } from "react-notifications";
import InputLabel from "@material-ui/core/InputLabel";
import { DatePicker } from "@material-ui/pickers";
import moment from "moment";
import ReactCrop from "react-image-crop";
import Dropzone from "react-dropzone";
const handleDropRejected = (...args) => console.log("reject", args);

const ContainerHeader = lazy(() => import("../../../components/ContainerHeader/index"));
const CardBox = lazy(() => import("../../../components/CardBox/index"));
const SimpleSelect = lazy(() => import("../components/routes/selects/simple/SimpleSelect"));
const MySwitch = lazy(() => import("../../../components/MyComponents/MySwitch"));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

class PromoCreate extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			error: "",
			description: "",
			code: "",
			status: "1",
			categoryId: "",
			bookingCountError: "",
			bookingCount: 1,
			offerType: 1,
			offerTypeError: "",
			timesType: 1,
			timesTypeError: "",
			typeId: "",
			typeIdError: "",
			categoryError: "",
			loader: true,
			loadingButton: false,
			codeError: "",
			redirect: false,
			type: "",
			noResponse: false,
			errorMessage: "",
			preview: null,
			categoryData: [],
			startDate: moment(),
			endDate: moment().add(1, "days"),
			advertise: false,
			generalPhotos: "",
			status: 1,
			crop: {
				x: 40,
				y: 40,
				width: 100,
				height: 100,
			},
			uptoAmount: "",
			uptoAmountError: "",
			percentAmountError: "",
			percentAmount: "",
			heading: "",
			headingError: "",
			advertiseOn: moment(),
			selectedProviders: [],
			providerListing: []
		};
		this.handleDrop = this.handleDrop.bind(this);
	}

	componentDidMount() {
		this.setState({ loader: true });
		this.getProviders();
	}


	//apiCall
	getProviders = async () => {
		this.props.providerListAction(
			{
				role: 840
			},
			this.onProviderDone
		);
	};
	onProviderDone = (success, data) => {
		if (success) {
			let providerData = data.data.map((provider, index) => {
				return {
					providerName: `${provider.name} - (${provider.businessName})`,
					providerId: provider.key,
					businessName: provider.businessName
				}
			}).filter(provider => provider.businessName ? true : false)
			this.setState({
				loader: false,
				providerListing: [{
					providerName: "Select All",
					providerId: 'SELECT_ALL_PROVIDER'
				}, ...providerData
				]
			});
		} else {
			this.setState({
				loader: false,
				error: data
			});
		}
	};

	handleStartDateChange = date => {
		this.setState({
			startDate: date
		});
	};
	handleEndDateChange = date => {
		this.setState({
			endDate: date
		});
	};
	handleAdDateChange = date => {
		this.setState({
			advertiseOn: date
		});
	};
	submit = params => {
		this.setState({ loadingButton: true });
		const {
			code,
			description,
			status,
			bookingCount,
			timesType,
			offerType,
			typeId,
			startDate,
			endDate,
			advertise,
			uptoAmount,
			percentAmount,
			heading,
			advertiseOn
		} = this.state;

		if (
			validatePromoForm(
				{
					code,
					description,
					bookingCount,
					timesType,
					offerType,
					typeId,
					startDate,
					endDate,
					percentAmount,
					heading,
					uptoAmount
				},
				this.handleError
			)
		) {
			if (this.state.selectedProviders.length === 0) {
				NotificationManager.error("Validation Error", "Oops!");
				this.setState({ loadingButton: false });
			} else {
				this.props.promoCreateAction(
					{
						code,
						description,
						bookingCount,
						status,
						timesType,
						offerType,
						typeId,
						startDate,
						endDate,
						advertise,
						uptoAmount,
						percentAmount,
						heading,
						advertiseOn,
						image: this.state.croppedImageUrl,
						selectedProviders: this.state.selectedProviders
					},
					this.onDone
				);
			}
		} else {
			NotificationManager.error("Validation Error", "Oops!");
		}
	};
	handleError = params => {
		this.setState(params);
		this.setState({ loadingButton: false });
	};
	onDone = (success, message) => {
		if (success) {
			this.setState({ loadingButton: false });
			return this.props.history.push("/app/promoCode/list");
		} else {
			this.setState({ loadingButton: false });
		}
	};

	onCategoryDone = (success, data) => {
		if (success) {
			this.setState({ categoryData: data });
		}
		this.setState({ loader: false });
	};
	renderButton = () => {
		if (this.state.loadingButton) {
			return <CircularProgress />;
		}
		return (
			<Button
				variant="raised"
				color="primary"
				className="jr-btn"
				onClick={this.submit}
			>
				Add
			</Button>
		);
	};
	handleDrop([{ preview }]) {
		this.setState({ preview });
	}

	onImageLoaded = (image, pixelCrop) => {
		this.imageRef = image;
	};
	onCropComplete = async (crop) => {
		if (this.imageRef && crop.width && crop.height) {
			const croppedImageUrl = await this.getCroppedImg(
				this.imageRef,
				crop,
				"newFile.jpeg"
			);
			this.setState({ croppedImageUrl });
		}
	};

	getCroppedImg(image, pixelCrop, fileName) {
		const canvas = document.createElement("canvas");

		const scaleX = image.naturalWidth / image.width;
		const scaleY = image.naturalHeight / image.height;
		canvas.width = pixelCrop.width;
		canvas.height = pixelCrop.height;
		const ctx = canvas.getContext("2d");

		ctx.drawImage(
			image,
			pixelCrop.x * scaleX,
			pixelCrop.y * scaleY,
			pixelCrop.width * scaleX,
			pixelCrop.height * scaleY,
			0,
			0,
			pixelCrop.width,
			pixelCrop.height
		);

		return new Promise((resolve, reject) => {
			this.fileUrl = canvas.toDataURL();
			resolve(this.fileUrl);
		});
	}
	handleChange = categoryId => event => {
		this.setState({ [categoryId]: event.target.value, categoryError: "" });
	};

	render() {
		const { match } = this.props;
		const { providerListing } = this.state;

		const {
			status,
			timesTypeError,
			offerTypeError,
			bookingCountError,
			typeIdError,
			timesType,
			loader,
			codeError,
			descriptionError,
			startDate,
			endDate,
			advertise,
			preview,
			croppedImageUrl,
			loadingButton,
			percentAmountError,
			uptoAmountError,
			headingError,
			advertiseOnError,
			advertiseOn
		} = this.state;
		if (loader) {
			return (
				<div className="loader-view loader-center">
					<CircularProgress />
				</div>
			);
		}
		return (
			<div className="animated slideInUpTiny animation-duration-3">
				<ContainerHeader
					title={<IntlMessages id="add-promo" />}
					match={match}
				/>

				<CardBox styleName="col-lg-12">
					<form
						className="row"
						noValidate
						autoComplete="off"
						id="category-create"
						encType="multipart/form-data"
					>
						<div className="col-12">
							<div className="row col-md-12">
								<div className="col-md-2">
									<h3>Drop Image here to Upload</h3>
									<Dropzone
										onDrop={this.handleDrop}
										accept="image/jpeg,image/jpg,image/png"
										multiple={false}
										onDropRejected={handleDropRejected}
									>
										<p> Drag a file here or click to upload.</p>
									</Dropzone>
								</div>
								<div className="col-md-5">
									{preview && (
										<div>
											<h3>Original Image</h3>
											<ReactCrop
												src={preview}
												imageStyle={{
													width: "100%",
													maxHeight: "none"
												}}
												onImageLoaded={this.onImageLoaded}
												onComplete={this.onCropComplete}
												crop={this.state.crop}
												onChange={crop => {
													this.setState({ crop });
												}}
											/>
										</div>
									)}
								</div>
								<div className="col-md-5">
									{croppedImageUrl && (
										<div>
											<h3>Final Image</h3>
											<img
												width={"200px"}
												height={"200px"}
												alt="Crop"
												src={croppedImageUrl}
											/>
										</div>
									)}
								</div>
							</div>
							<div className="row">
								<div className="col-md-6 col-12">
									<TextField
										error={codeError ? true : false}
										helperText={codeError}
										id="code"
										label="Promo Code"
										name="code"
										InputLabelProps={{
											shrink: true
										}}
										placeholder="Add Promo Code"
										fullWidth
										onChange={event => {
											this.setState({
												code: event.target.value,
												codeError: ""
											});
										}}
										disabled={loadingButton ? true : false}
										margin="normal"
									/>
								</div>

								<div className="col-md-6 col-xs-12">
									<TextField
										error={headingError ? true : false}
										helperText={headingError}
										id="heading"
										label="Heading"
										InputLabelProps={{
											shrink: true
										}}
										name="heading"
										placeholder="Add Heading"
										fullWidth
										margin="normal"
										disabled={loadingButton ? true : false}
										onChange={event => {
											this.setState({
												heading: event.target.value,
												headingError: ""
											});
										}}
									/>
								</div>

								<div className="col-md-6 col-12">
									<TextField
										error={percentAmountError ? true : false}
										helperText={percentAmountError}
										id="percentAmount"
										label="Discount Percentage"
										name="percentAmount"
										InputLabelProps={{
											shrink: true
										}}
										placeholder="Add Discount Percentage"
										fullWidth
										onChange={event => {
											this.setState({
												percentAmount: event.target.value,
												percentAmountError: ""
											});
										}}
										disabled={loadingButton ? true : false}
										margin="normal"
									/>
								</div>

								<div className="col-md-6 col-12">
									<TextField
										error={uptoAmountError ? true : false}
										helperText={uptoAmountError}
										id="uptoAmount"
										label="Valid Upto Amount"
										name="uptoAmount"
										InputLabelProps={{
											shrink: true
										}}
										placeholder="Add Valid Upto Amount"
										fullWidth
										onChange={event => {
											this.setState({
												uptoAmount: event.target.value,
												uptoAmountError: ""
											});
										}}
										disabled={loadingButton ? true : false}
										margin="normal"
									/>
								</div>


								<div className="col-md-6 col-xs-12 ">
									<DatePicker
										className="mt-3"
										fullWidth
										name="startDate"
										placeholder="Start Date"
										label="Start Date"
										value={startDate}
										format={"DD-MMM-Y"}
										onChange={this.handleStartDateChange}
										animateYearScrolling={false}
										leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
										rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
										disablePast={true}
									/>
								</div>
								<div className="col-md-6 col-xs-12 ">
									<DatePicker
										className="mt-3"
										fullWidth
										name="endDate"
										placeholder="End Date"
										label="End Date"
										value={endDate}
										format={"DD-MMM-Y"}
										onChange={this.handleEndDateChange}
										animateYearScrolling={false}
										leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
										rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
										disablePast={true}
										disabled={loadingButton ? true : false}
									/>
								</div>

								<div className="col-md-6 col-xs-12">
									<FormControl
										className="w-100 mt-3"
										error={timesTypeError ? true : false}
									>
										<InputLabel htmlFor="timesType">
											How many times user can apply?
                    </InputLabel>
										<Select
											fullWidth
											name="timesType"
											value={this.state.timesType}
											onChange={this.handleChange("timesType")}
											input={<Input id="timesType" />}
											onChange={event => {
												this.setState({
													timesType: event.target.value,
													timesTypeError: ""
												});
											}}
										>
											<MenuItem value={1}>Single Booking</MenuItem>
											<MenuItem value={2}>Custom Usage Number</MenuItem>
										</Select>
										<FormHelperText>{timesTypeError}</FormHelperText>
									</FormControl>
								</div>

								<div className="col-md-6 col-12">
									{timesType == 2 && (
										<TextField
											error={bookingCountError ? true : false}
											helperText={bookingCountError}
											id="bookingCount"
											label="Applies on How many bookings?"
											name="bookingCount"
											InputLabelProps={{
												shrink: true
											}}
											placeholder="Add Booking Count"
											fullWidth
											onChange={event => {
												this.setState({
													bookingCount: event.target.value,
													bookingCountError: ""
												});
											}}
											disabled={loadingButton ? true : false}
											margin="normal"
										/>
									)}
								</div>
							</div>
							<div className="col-md-6 col-xs-12">
								<FormControl
									className="w-100 mt-3"
									error={offerTypeError ? true : false}
								>
									<InputLabel htmlFor="offerType">Offer Type?</InputLabel>
									<Select
										fullWidth
										name="offerType"
										value={this.state.offerType}
										onChange={this.handleChange("offerType")}
										input={<Input id="offerType" />}
										onChange={event => {
											this.setState({
												offerType: event.target.value,
												offerTypeError: ""
											});
										}}
									>
										<MenuItem value={1}>Promo Code App Offer App</MenuItem>
										<MenuItem value={2}>Promo Code Offer Promotion</MenuItem>
									</Select>
									<FormHelperText>{offerTypeError}</FormHelperText>
								</FormControl>
							</div>

							<div className="col-md-6 col-xs-12">
								<SimpleSelect
									fullWidth
									label="Status"
									name="status"
									id="status"
									value={status}
									onChange={status => {
										this.setState({ status });
									}}
									disabled={loadingButton ? true : false}
								/>
							</div>

							<div className="row">
								<div className="col-md-12 col-xs-12">
									<TextField
										error={descriptionError ? true : false}
										helperText={descriptionError}
										id="description"
										label="Description"
										InputLabelProps={{
											shrink: true
										}}
										name="description"
										placeholder="Add Description"
										fullWidth
										margin="normal"
										disabled={loadingButton ? true : false}
										onChange={event => {
											this.setState({
												description: event.target.value,
												descriptionError: ""
											});
										}}
									/>
								</div>
							</div>

							<div className="row">
								<div className="col-12">
									<FormControl className="w-100">
										<InputLabel htmlFor="name-multiple">Select Providers</InputLabel>
										<Select
											multiple
											error={this.state.selectedProviders.length === 0}
											value={this.state.selectedProviders}
											onChange={event => {
												//console.log('event.target.value', event.target.value)
												if (event.target.value.indexOf("SELECT_ALL_PROVIDER") > -1) {
													let providersListing = [...providerListing]
													providersListing.shift();
													this.setState({
														selectedProviders: providersListing.map(provider => provider.providerId),
													});
												} else {
													this.setState({
														selectedProviders: event.target.value,
													});
												}
											}}
											input={<Input id="name-multiple" />}
											MenuProps={{
												PaperProps: {
													style: {
														maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
														width: 200
													}
												}
											}}
										>
											{providerListing.map(provider => (
												<MenuItem
													key={provider.providerId}
													value={provider.providerId}
													style={{
														fontWeight:
															this.state.selectedProviders.indexOf(provider.providerName) !== -1 ? "500" : "400"
													}}
												>
													{provider.providerName}
												</MenuItem>
											))}
										</Select>
										{this.state.selectedProviders.length === 0 &&
											<span style={{ color: 'red' }}>Please select providers</span>
										}
									</FormControl>
								</div>
							</div>

							<div className="row">
								<div className="col-md-3 mt-3">
									<MySwitch
										onChange={() =>
											this.setState(prevState => ({
												advertise: !prevState.advertise
											}))
										}
										label="Want to Share this as Advertisement?"
										name="advertise"
										checked={advertise}
									/>
								</div>
								{advertise == true && (
									<div className="col-md-6 col-xs-12 ">
										<DatePicker
											className="mt-3"
											fullWidth
											name="advertiseOn"
											placeholder="Adverise On"
											label="Advertise On"
											value={advertiseOn}
											format={"DD-MMM-Y"}
											onChange={this.handleAdDateChange}
											animateYearScrolling={false}
											leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
											rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
											disablePast={true}
										/>
									</div>
								)}
							</div>
						</div>
						<div className="col-md-12 col-xs-12">
							<div className="mt-3" />
							{this.renderButton()}
						</div>
					</form>
				</CardBox>
			</div>
		);
	}
}

export default connect(
	null,
	actions
)(PromoCreate);

import React, { lazy } from "react";

const CategoryTable = lazy(() => import("../../../components/MyComponents/CategoryTable"));

class CategoryList extends React.Component {
  render = () => {
    const { title = "Categories", match } = this.props;
    return (
      <CategoryTable
        headings={[
          { name: "Image", id: "image" },
          { name: "Name", id: "heading" },
          { name: "Status", id: "status" },
          { name: "Deleted?", id: "deleted_at" },
          { name: "Actions", id: "actions" }
        ]}
        title={title}
        match={match}
      />
    );
  };
}

export default CategoryList;
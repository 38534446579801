import React, { Component } from "react";
import MomentUtils from '@date-io/moment';
import { IntlProvider } from "react-intl";
import {
  Redirect,
  Route,
  Switch
} from "react-router-dom";
import Cookies from "universal-cookie";
import { NotificationContainer } from "react-notifications";
import { connect } from "react-redux";

import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import * as actions from "../actions";

import {
  AMBER,
  BLUE,
  CYAN,
  DARK_AMBER,
  DARK_BLUE,
  DARK_CYAN,
  DARK_DEEP_ORANGE,
  DARK_DEEP_PURPLE,
  DARK_GREEN,
  DARK_INDIGO,
  DARK_PINK,
  DEEP_ORANGE,
  DEEP_PURPLE,
  GREEN,
  INDIGO,
  PINK
} from "../constants/ThemeColors";

import Error404 from "../app/routes/extraPages/routes/404"

import indigoTheme from "./themes/indigoTheme";
import cyanTheme from "./themes/cyanTheme";
import orangeTheme from "./themes/orangeTheme";
import amberTheme from "./themes/amberTheme";
import pinkTheme from "./themes/pinkTheme";
import blueTheme from "./themes/blueTheme";
import purpleTheme from "./themes/purpleTheme";
import greenTheme from "./themes/greenTheme";
import darkTheme from "./themes/darkTheme";
import AppLocale from "../lngProvider";
import AppRoute from "../app/index.js";
import SignIn from "./SignIn";
import RTL from "../util/RTL";

import "react-notifications/lib/notifications.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import "../styles/jumbo.css";

const cookies = new Cookies();
const RestrictedRoute = ({ component: Component, ...rest }) => {
  const authToken = cookies.get("x-auth-token");
  return (
    <Route
      {...rest}
      render={props =>
        authToken ? (
          <Component {...props} />
        ) : (
            <Redirect
              to={{
                pathname: "/signin",
                state: { from: props.location }
              }}
            />
          )
      }
    />
  );
};

class App extends Component {
  constructor() {
    super();
    this.state = {
      loader: true
    };
  }

  componentWillMount() {
    if (this.props.initURL === "") {
      this.props.setInitUrl(this.props.history.location.pathname);
    }

  }

  componentDidMount() {
    this.props.getNationalityAction()
    this.props.getLanguagesAction()
    const { location, authToken } = this.props
    if (location.pathname === "/") {
      if (authToken === null || typeof authToken === "undefined") {
        // log
        this.props.history.push("/signin");
        // return <SignIn />
      } else {
        // return <Redirect to={"/app/dashboard/"} />;

        this.props.history.push("/app/dashboard");
      }
    }
    // this.props.getAdminThemeColorAction(this.onDone);
  }

  onDone = (success, data) => {
    if (!success) {
      this.props.getAdminThemeColorAction(this.onDone);
    } else {
      this.setState({ loader: false });
    }
  };

  getColorTheme(themeColor, applyTheme) {
    switch (themeColor) {
      case INDIGO: {
        applyTheme = createMuiTheme(indigoTheme);
        break;
      }
      case CYAN: {
        applyTheme = createMuiTheme(cyanTheme);
        break;
      }
      case AMBER: {
        applyTheme = createMuiTheme(amberTheme);
        break;
      }
      case DEEP_ORANGE: {
        applyTheme = createMuiTheme(orangeTheme);
        break;
      }
      case PINK: {
        applyTheme = createMuiTheme(pinkTheme);
        break;
      }
      case BLUE: {
        applyTheme = createMuiTheme(blueTheme);
        break;
      }
      case DEEP_PURPLE: {
        applyTheme = createMuiTheme(purpleTheme);
        break;
      }
      case GREEN: {
        applyTheme = createMuiTheme(greenTheme);
        break;
      }
      case DARK_DEEP_PURPLE: {
        applyTheme = createMuiTheme(purpleTheme);
        break;
      }
      case DARK_DEEP_ORANGE: {
        applyTheme = createMuiTheme(orangeTheme);
        break;
      }
      case DARK_CYAN: {
        applyTheme = createMuiTheme(cyanTheme);
        break;
      }
      case DARK_BLUE: {
        applyTheme = createMuiTheme(blueTheme);
        break;
      }
      case DARK_AMBER: {
        applyTheme = createMuiTheme(amberTheme);
        break;
      }
      case DARK_GREEN: {
        applyTheme = createMuiTheme(greenTheme);
        break;
      }
      case DARK_PINK: {
        applyTheme = createMuiTheme(pinkTheme);
        break;
      }
      case DARK_INDIGO: {
        applyTheme = createMuiTheme(indigoTheme);
        break;
      }
    }
    return applyTheme;
  }

  render() {
    const {
      match,
      location,
      isDirectionRTL,
      authToken,
      themeColor,
      isDarkTheme
    } = this.props;
    let applyTheme = createMuiTheme(pinkTheme);
    if (isDarkTheme) {
      applyTheme = createMuiTheme(darkTheme);
    } else {
      applyTheme = this.getColorTheme(themeColor, applyTheme);
    }
    // if (location.pathname === "/") {
    //   // if (authToken === null || typeof authToken === "undefined") {
    //   //   // log
    //   //   // this.props.history.push("/signin");
    //   //   return <SignIn />
    //   // } else {
    //     // return <Redirect to={"/app/dashboard/"} />;
    //      this.props.history.push("/app/dashboard");
    //   // }
    // }
    if (isDirectionRTL) {
      applyTheme.direction = "rtl";
      document.body.classList.add("rtl");
    } else {
      document.body.classList.remove("rtl");
      applyTheme.direction = "ltr";
    }
    const currentAppLocale = AppLocale["en"];
    // if (this.state.loader) {
    //   return (
    //     <div className="loader-center">
    //       <CircularProgress />
    //     </div>
    //   );
    // }
    return (
      <MuiThemeProvider theme={applyTheme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}
          >
            <RTL>
              <div className="app-main">
                <Switch>
                  <RestrictedRoute
                    path={`${match.url}app`}
                    component={AppRoute}
                  />
                  <Route path="/signin" exact component={() => <SignIn />} />
                  <Route
                    component={Error404}
                  />
                </Switch>
                <NotificationContainer />
              </div>
            </RTL>
          </IntlProvider>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    );
    return <>Hello</>
  }
}

const mapStateToProps = ({ settings }) => {
  const {
    themeColor,
    sideNavColor,
    darkTheme,
    locale,
    isDirectionRTL
  } = settings;
  return {
    themeColor,
    sideNavColor,
    isDarkTheme: darkTheme,
    locale,
    isDirectionRTL
  };
};

export default connect(
  mapStateToProps,
  actions
)(App);
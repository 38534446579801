import React, { lazy } from "react";
import Table from "../table/index";
import * as actions from "../../../actions";
import { connect } from "react-redux";

const HOCLoader = lazy(() => import("../../../components/MyComponents/HOCLoader"));

class ProviderList extends React.Component {
  constructor() {
    super();
    this.state = {
      error: "",
      loader: true,
      list: []
    };
  }
  //   componentDidMount() {
  //     this.getData();
  //   }
  //   getData = async () => {
  //     const { role } = this.props;
  //     this.props.providerListAction(
  //       {
  //         role
  //       },
  //       this.onDone
  //     );
  //   };
  //   onDone = (success, data) => {
  //     if (success) {
  //       this.setState({ loader: false, list: data });
  //     } else {
  //       this.setState({ loader: false, noResponse: true });
  //     }
  //   };
  render() {
    const { role, title, match } = this.props;
    const { list, loader } = this.state;

    return (
      //   <HOCLoader
      //     loading={this.state.loader}
      //     noResponse={this.state.noResponse}
      //     create={list.length < 0 ? true : false}
      //     createLink={"/app/provider/create"}
      //   >
      <Table
        headings={[
          { name: "Avatar", id: "image" },
          { name: "Name", id: "name" },
          { name: "Email", id: "email" },
          { name: "Contact Number", id: "phone" },
          { name: "Due Amount", id: "due_amount" },
          { name: "Last Paid Time", id: "last_paid_time" },
          { name: "Approval", id: "approval" },
          { name: "Status", id: "status" },
          { name: "Deleted?", id: "deleted_at" },
          { name: "Actions", id: "action" }
        ]}
        mode={"provider"}
        title={title}
        match={match}
        //   actualData={list}
        role={role}
      />
      //   </HOCLoader>
    );
  }
}

export default connect(
  null,
  actions
)(ProviderList);

import React, { lazy } from "react";

const ServiceTable = lazy(() => import("../../../components/MyComponents/ServiceTable"));

class ServiceList extends React.Component {
  render = () => {
    const { title = "services", match } = this.props;

    return (
      <ServiceTable
        headings={[
          { name: "Name", id: "name" },
          { name: "Sub Category", id: "categoryName" },
          { name: "Status", id: "status" },
          { name: "Deleted?", id: "deleted_at" },
          { name: "Actions", id: "actions" }
        ]}
        title={title}
        match={match}
      />
    );
  };
}

export default ServiceList;

import appLocaleData from '@formatjs/intl-pluralrules/dist/locale-data/ar';

import saMessages from '../locales/ar_SA.json';

const saLang = {
    messages: {
        ...saMessages
    },
    locale: 'ar-SA',
    data: appLocaleData
};

export default saLang;
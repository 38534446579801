import React, { lazy } from "react";
import { NotificationManager } from "react-notifications";
import { connect } from "react-redux";
import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

import IntlMessages from '../../../util/IntlMessages';
import { validateCMSForm } from "../../../util/validators/validateForm";

import * as actions from "../../../actions";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const ContainerHeader = lazy(() => import("../../../components/ContainerHeader/index"));
const CardBox = lazy(() => import("../../../components/CardBox/index"));
const CustomError = lazy(() => import("../../../components/MyComponents/common/CustomError"));
const SimpleSelect = lazy(() => import("../components/routes/selects/simple/SimpleSelect"));

class CMSCreate extends React.Component {
  constructor() {
    super();
    this.state = {
      error: "",
      titleError: "",
      metaTitleError: "",
      metaTitle: "",
      metaKeyword: "",
      metaKeywordError: "",
      title: "",
      status: true,
      showingon: 0,
      loader: false,
      noResponse: false,
      errorMessage: "",
      metaDescription: "",
      metaDescriptionError: "",
      editorState: EditorState.createEmpty()
    };
  }

  submit = params => {
    const {
      title,
      metaTitle,
      metaKeyword,
      status,
      showingon,
      metaDescription,
      editorState
    } = this.state;
    if (
      validateCMSForm(
        { title, metaTitle, metaKeyword, metaDescription },
        this.handleError
      )
    ) {
      this.setState({ loader: true });
      this.props.cmsCreateAction(
        {
          title,
          metaTitle,
          metaKeywords: metaKeyword,
          status,
          showingon,
          metaDescription,
          description: draftToHtml(
            convertToRaw(editorState.getCurrentContent())
          )
        },
        this.onDone
      );
    } else {
      NotificationManager.error("Validation Error", "Oops!");
    }
  };

  handleError = params => {
    this.setState(params);
  };

  onEditorStateChange = editorState => {
    this.setState({
      editorState
    });
  };

  onDone = (success, data) => {
    this.setState({ loader: false });
    if (success) {
      return this.props.history.push("/app/cms/list");
    }
  };

  renderButton = () => {
    if (this.state.loader) {
      return <CircularProgress />;
    }
    return (
      <Button
        variant="raised"
        color="primary"
        className="jr-btn"
        onClick={this.submit}
      >
        Add
      </Button>
    );
  };

  uploadImageCallBack(file) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open("POST", "https://api.imgur.com/3/image");
      xhr.setRequestHeader("Authorization", `Client-ID ${process.env.REACT_APP_IMGGUR_KEY}`);
      const data = new FormData();
      data.append("image", file);
      xhr.send(data);
      xhr.addEventListener("load", () => {
        const response = JSON.parse(xhr.responseText);
        resolve(response);
      });
      xhr.addEventListener("error", () => {
        const error = JSON.parse(xhr.responseText);
        reject(error);
      });
    });
  }

  render() {
    const { match } = this.props;
    const {
      metaTitle,
      title,
      loader,
      metaTitleError,
      titleError,
      error,
      editorState,
      content,
      metaKeywordError,
      metaKeyword,
      metaDescription,
      metaDescriptionError,
      status,
      showingon
    } = this.state;

    return (
      <div className="animated slideInUpTiny animation-duration-3">
        <ContainerHeader title={<IntlMessages id="add-page" />} match={match} />
        <div className="row">
          <CardBox styleName="col-lg-12">
            <form
              className="row"
              noValidate
              autoComplete="off"
              id="customer-create"
            >
              <div className="col-md-6 col-xs-12">
                <TextField
                  error={titleError ? true : false}
                  helperText={titleError}
                  type="text"
                  name="title"
                  label="Title"
                  id="title"
                  fullWidth
                  placeholder="Add Title"
                  InputLabelProps={{
                    shrink: true
                  }}
                  margin="normal"
                  onChange={event => {
                    this.setState({
                      title: event.target.value,
                      titleError: "",
                      error: ""
                    });
                  }}
                  disabled={loader ? true : false}
                />
              </div>
              <div className="col-md-6 col-xs-12">
                <TextField
                  error={metaTitleError ? true : false}
                  helperText={metaTitleError}
                  type="text"
                  name="metaTitle"
                  label="Meta Title"
                  id="metaTitle"
                  fullWidth
                  placeholder="Add Meta Title"
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={event => {
                    this.setState({
                      metaTitle: event.target.value,
                      metaTitleError: "",
                      error: ""
                    });
                  }}
                  margin="normal"
                  defaultValue={metaTitle}
                  disabled={loader ? true : false}
                />
              </div>
              <div className="col-md-6 col-xs-12">
                <TextField
                  error={metaKeywordError ? true : false}
                  helperText={metaKeywordError}
                  type="text"
                  name="metaKeyword"
                  label="Meta Keywords"
                  id="metaKeyword"
                  fullWidth
                  placeholder="Add Meta Keywords"
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={event => {
                    this.setState({
                      metaKeyword: event.target.value,
                      metaKeywordError: "",
                      error: ""
                    });
                  }}
                  margin="normal"
                  defaultValue={metaKeyword}
                  disabled={loader ? true : false}
                />
              </div>
              <div className="col-md-6 col-xs-12">
                <TextField
                  error={metaDescriptionError ? true : false}
                  helperText={metaDescriptionError}
                  type="text"
                  name="metaDescription"
                  label="Meta Description"
                  id="metaDescription"
                  fullWidth
                  placeholder="Add Meta Description"
                  InputLabelProps={{
                    shrink: true
                  }}
                  onChange={event => {
                    this.setState({
                      metaDescription: event.target.value,
                      metaDescriptionError: "",
                      error: ""
                    });
                  }}
                  margin="normal"
                  defaultValue={metaDescription}
                  disabled={loader ? true : false}
                />
              </div>
              <div className="col-md-6 col-xs-12">
                <SimpleSelect
                  fullWidth
                  label="Status"
                  name="status"
                  id="status"
                  value={status}
                  onChange={status => {
                    this.setState({ status });
                  }}
                  disabled={loader ? true : false}
                />
              </div>
              <div className="col-md-6 col-xs-12">
                <FormControl className="w-100 mt-3" >
                  <InputLabel htmlFor="showingon">Showing On</InputLabel>
                  <Select
                    value={showingon}
                    onChange={({ target: { value } }) => this.setState({ showingon: value })}
                    input={<Input id="showingon" />}
                  >
                    <MenuItem value={0}>
                      <em>Both (Header/Footer)</em>
                    </MenuItem>
                    <MenuItem value={1}>
                      <em>Header</em>
                    </MenuItem>
                    <MenuItem value={2}>Footer</MenuItem>
                  </Select>
                </FormControl>
              </div>
              <div className="col-md-12 mt-4">
                <Editor
                  editorState={editorState}
                  wrapperClassName="demo-wrapper"
                  editorClassName="demo-editor"
                  onEditorStateChange={this.onEditorStateChange}
                  toolbar={{
                    inline: { inDropdown: true },
                    list: { inDropdown: true },
                    textAlign: { inDropdown: true },
                    link: { inDropdown: true },
                    history: { inDropdown: true },
                    image: {
                      uploadCallback: this.uploadImageCallBack,
                      alt: { present: true, mandatory: false }
                    }
                  }}
                />
              </div>
              <CustomError error={error} />
              <div className="col-md-12 col-xs-12">
                <div className="mt-3" />
                {this.renderButton()}
              </div>
            </form>
          </CardBox>
        </div>
      </div>
    );
  }
}

export default connect(
  null,
  actions
)(CMSCreate);

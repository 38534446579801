import React, { lazy } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Sidebar from "../containers/SideNav/index";
import Dashboard from "./routes/dashboard";
import Components from "./routes/components";
import Icons from "./routes/icons/index";
import Form from "./routes/form";
import Editors from "./routes/editors";
import Pickers from "./routes/pickers";
import Extensions from "./routes/extensions";
import Table from "./routes/table";
import Chart from "./routes/charts";
import Map from "./routes/map";
import Calendar from "./routes/calendar";
import TimeLine from "./routes/timeLine";
import CustomViews from "./routes/customViews";
import ExtraElements from "./routes/extraElements";
import eCommerce from "./routes/eCommerce";
import AppModule from "./routes/appModule";
import ExtraPages from "./routes/extraPages";
import CustomerList from "./routes/user/list";
import ProviderList from "./routes/user/providerList";
import CustomerView from "./routes/user/customerView";
import ProviderView from "./routes/user/providerView";
import CMSView from "./routes/cms/view";
import CategoryList from "./routes/category/list";
import ServiceList from "./routes/service/list";
import SubcategoryList from "./routes/subcategory/list";
import HelpdeskList from "./routes/helpdesk/list";
import Reply from "./routes/helpdesk/reply";
import BookingList from "./routes/booking/list";
import PromoCodeList from "./routes/promoCode/list";
import CMSList from "./routes/cms/list";
import TransactionList from "./routes/transaction/list";
import RatingList from "./routes/rating/list";
import CategoryView from "./routes/category/view";
import SubcategoryCreate from "./routes/subcategory/create";
import SubcategoryUpdate from "./routes/subcategory/update";
import CategoryCreate from "./routes/category/create";
import CategoryUpdate from "./routes/category/update";
import ServiceCreate from "./routes/service/create";
import ServiceUpdate from "./routes/service/update";
import CustomerCreate from "./routes/user/customerCreate";
import ProviderCreate from "./routes/user/providerCreate";
import CMSCreate from "./routes/cms/create";
import CMSUpdate from "./routes/cms/update";
import PromoCreate from "./routes/promoCode/create";
import PromoCodeView from "./routes/promoCode/view";
import PromoCodeUpdate from "./routes/promoCode/update";
import AddressMap from "./routes/address/map";
import Settings from "./routes/settings/index";
import GalleryTable from "./routes/gallery/list";
import CustomerUpdate from "./routes/user/customerUpdate";
import ProviderUpdate from "./routes/user/providerUpdate";
import BookingDetail from "./routes/booking/detail";
import ProviderServiceView from "./routes/providerService/detail";
import ProviderServiceUpdate from "./routes/providerService/detailsUpdate";
import ProviderServiceCreate from "./routes/providerService/detailsCreate";
import ProviderAddressUpdate from "./routes/address/detailUpdate";
import ProviderAddressAdd from "./routes/address/detailAdd";
import GodsView from "./routes/user/godsView";
import AdminProfile from "./routes/user/adminProfile";
import AdminChangePassword from "./routes/user/changePassword";
import BannerList from "./routes/settings/banners";
import BannerCreate from "./routes/settings/bannerCreate";
import {
  ABOVE_THE_HEADER,
  BELOW_THE_HEADER,
  COLLAPSED_DRAWER,
  FIXED_DRAWER,
  HORIZONTAL_NAVIGATION,
} from "../constants/ActionTypes";
import ColorOption from "../containers/Customizer/ColorOption";
import { isIOS, isMobile } from "react-device-detect";
import asyncComponent from "../util/asyncComponent";
import BannerUpdate from "./routes/settings/bannerUpdate";

const Header = lazy(() => import("../components/Header/index"));
const Footer = lazy(() => import("../components/Footer"));
const Tour = lazy(() => import("../components/Tour/index"));
const TopNav = lazy(() => import("../components/TopNav"));

class App extends React.Component {
  render() {
    const {
      match,
      drawerType,
      navigationStyle,
      horizontalNavPosition,
    } = this.props;
    const drawerStyle = drawerType.includes(FIXED_DRAWER)
      ? "fixed-drawer"
      : drawerType.includes(COLLAPSED_DRAWER)
      ? "collapsible-drawer"
      : "mini-drawer";

    //set default height and overflow for iOS mobile Safari 10+ support.
    if (isIOS && isMobile) {
      document.body.classList.add("ios-mobile-view-height");
    } else if (document.body.classList.contains("ios-mobile-view-height")) {
      document.body.classList.remove("ios-mobile-view-height");
    }

    return (
      <div className={`app-container ${drawerStyle}`}>
        {/* <Tour /> */}

        <Sidebar />
        <div className="app-main-container">
          {/* <div
            className={`app-header ${
              navigationStyle === HORIZONTAL_NAVIGATION
                ? "app-header-horizontal"
                : ""
            }`}
          >
            {navigationStyle === HORIZONTAL_NAVIGATION &&
              horizontalNavPosition === ABOVE_THE_HEADER && (
                <TopNav styleName="app-top-header" />
              )}
            <Header />
            {navigationStyle === HORIZONTAL_NAVIGATION &&
              horizontalNavPosition === BELOW_THE_HEADER && <TopNav />}
          </div> */}

          <main className="app-main-content-wrapper">
            <div className="app-main-content">
              <div className="app-wrapper" style={{ padding: "10px 24px" }}>
                <Switch>
                  <Route
                    path={`${match.url}/dashboard`}
                    component={Dashboard}
                  />
                  <Route
                    path={`${match.url}/user/adminprofile`}
                    render={(props) => (
                      <AdminProfile match={match.url} {...props} />
                    )}
                  />
                  <Route
                    path={`${match.url}/user/changePassword`}
                    render={(props) => <AdminChangePassword {...props} />}
                  />
                  <Route
                    path={`${match.url}/user/customers`}
                    render={(props) => (
                      <CustomerList
                        match={match.url}
                        headings={[
                          { name: "Avatar" },
                          { name: "Name" },
                          { name: "Email" },
                          { name: "Contact Number" },
                          { name: "Status" },
                          { name: "Actions" },
                        ]}
                        role={420}
                        title="table.customers"
                        mode={"user"}
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path={`${match.url}/user/providers`}
                    render={(props) => (
                      <ProviderList
                        match={match.url}
                        headings={[
                          { name: "Avatar" },
                          { name: "Name" },
                          { name: "Email" },
                          { name: "Contact Number" },
                          { name: "Status" },
                          { name: "Actions" },
                        ]}
                        mode={"user"}
                        role={840}
                        title="table.providers"
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path={`${match.url}/customer/create`}
                    render={(props) => <CustomerCreate {...props} />}
                  />
                  <Route
                    path={`${match.url}/provider/create`}
                    render={(props) => <ProviderCreate {...props} role={840} />}
                  />
                  <Route
                    path={`${match.url}/customer/update`}
                    render={(props) => <CustomerUpdate {...props} />}
                  />
                  <Route
                    path={`${match.url}/provider/update`}
                    render={(props) => <ProviderUpdate {...props} role={840} />}
                  />
                  <Route
                    path={`${match.url}/customer/view`}
                    render={(props) => <CustomerView {...props} />}
                  />
                  <Route
                    path={`${match.url}/provider/view`}
                    render={(props) => <ProviderView {...props} />}
                  />
                  <Route
                    path={`${match.url}/providerService/view`}
                    render={(props) => <ProviderServiceView {...props} />}
                  />
                  <Route
                    path={`${match.url}/providerService/update`}
                    render={(props) => <ProviderServiceUpdate {...props} />}
                  /> 
                  <Route
                  path={`${match.url}/providerService/create`}
                  render={(props) => <ProviderServiceCreate {...props} />}
                /><Route
                path={`${match.url}/provider/address/update`}
                render={(props) => <ProviderAddressUpdate {...props} />}
              /><Route
              path={`${match.url}/provider/address/add`}
              render={(props) => <ProviderAddressAdd {...props} />}
            />
                  <Route
                    path={`${match.url}/address/map`}
                    render={(props) => <AddressMap {...props} />}
                  />
                  <Route
                    path={`${match.url}/category/list`}
                    render={(props) => (
                      <CategoryList
                        match={match.url}
                        mode={"category"}
                        title="categories"
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path={`${match.url}/category/create`}
                    render={(props) => <CategoryCreate {...props} />}
                  />
                  <Route
                    path={`${match.url}/category/update`}
                    render={(props) => <CategoryUpdate {...props} />}
                  />
                  <Route
                    path={`${match.url}/category/view`}
                    render={(props) => <CategoryView {...props} />}
                  />
                  <Route
                    path={`${match.url}/service/list`}
                    render={(props) => (
                      <ServiceList
                        match={match.url}
                        mode={"service"}
                        title="services"
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path={`${match.url}/service/create`}
                    render={(props) => <ServiceCreate {...props} />}
                  />
                  <Route
                    path={`${match.url}/service/update`}
                    render={(props) => <ServiceUpdate {...props} />}
                  />
                  <Route
                    path={`${match.url}/subcategory/list`}
                    render={(props) => (
                      <SubcategoryList
                        match={match.url}
                        mode={"subcategory"}
                        title="subcategories"
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path={`${match.url}/subcategory/create`}
                    render={(props) => <SubcategoryCreate {...props} />}
                  />
                  <Route
                    path={`${match.url}/subcategory/update`}
                    render={(props) => <SubcategoryUpdate {...props} />}
                  />
                  <Route
                    path={`${match.url}/booking/list`}
                    render={(props) => (
                      <BookingList
                        match={match.url}
                        mode={"booking"}
                        title="bookings"
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path={`${match.url}/rating/list`}
                    render={(props) => (
                      <RatingList
                        match={match.url}
                        mode={"rating"}
                        title="ratings"
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path={`${match.url}/booking/view`}
                    render={(props) => <BookingDetail {...props} />}
                  />
                  <Route
                    path={`${match.url}/transaction/list`}
                    render={(props) => (
                      <TransactionList
                        match={match.url}
                        mode={"transaction"}
                        title="transactions"
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path={`${match.url}/settings`}
                    render={(props) => <Settings {...props} />}
                  />
                  <Route
                    path={`${match.url}/gallery/list`}
                    render={(props) => (
                      <GalleryTable
                        match={match.url}
                        mode={"category"}
                        title="categories"
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path={`${match.url}/promoCode/list`}
                    render={(props) => (
                      <PromoCodeList match={match.url} {...props} />
                    )}
                  />
                  <Route
                    path={`${match.url}/promo/create`}
                    render={(props) => (
                      <PromoCreate match={match.url} {...props} />
                    )}
                  />
                  <Route
                    path={`${match.url}/promo/view`}
                    render={(props) => (
                      <PromoCodeView match={match.url} {...props} />
                    )}
                  />
                  <Route
                    path={`${match.url}/promo/update`}
                    render={(props) => (
                      <PromoCodeUpdate match={match.url} {...props} />
                    )}
                  />
                  <Route
                    path={`${match.url}/helpdesk/list`}
                    render={(props) => <HelpdeskList {...props} />}
                  />
                  <Route
                    path={`${match.url}/helpdesk/reply`}
                    render={(props) => <Reply {...props} />}
                  />
                  <Route
                    path={`${match.url}/cms/list`}
                    render={(props) => (
                      <CMSList
                        match={match.url}
                        mode={"cms"}
                        title="cms"
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path={`${match.url}/cms/create`}
                    render={(props) => (
                      <CMSCreate match={match.url} title="cms" {...props} />
                    )}
                  />
                  <Route
                    path={`${match.url}/cms/update`}
                    render={(props) => (
                      <CMSUpdate match={match.url} title="cms" {...props} />
                    )}
                  />
                  <Route
                    path={`${match.url}/cms/view`}
                    render={(props) => <CMSView {...props} />}
                  />
                  <Route
                    path={`${match.url}/banner/list`}
                    render={(props) => <BannerList {...props} />}
                  />
                  <Route
                    path={`${match.url}/banner/create`}
                    render={(props) => (
                      <BannerCreate
                        match={match.url}
                        title="banner"
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path={`${match.url}/banner/update`}
                    render={(props) => (
                      <BannerUpdate
                        match={match.url}
                        title="banner"
                        {...props}
                      />
                    )}
                  />
                  <Route
                    path={`${match.url}/godsView`}
                    render={(props) => (
                      <GodsView
                        match={match.url}
                        mode={"category"}
                        title="categories"
                        {...props}
                      />
                    )}
                  />
                  <Route
                    component={asyncComponent(() =>
                      import("../app/routes/extraPages/routes/404")
                    )}
                  />
                </Switch>
              </div>
            </div>
            <Footer />
          </main>
        </div>
        <ColorOption />
      </div>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { drawerType, navigationStyle, horizontalNavPosition } = settings;

  return { drawerType, navigationStyle, horizontalNavPosition };
};
export default withRouter(connect(mapStateToProps)(App));

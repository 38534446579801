import React from "react";
import Table from "../table/index";
class CustomerList extends React.Component {
  render() {
    const { role, title, match } = this.props;
    return (
      <Table
        headings={[
          { name: "Avatar", id: "image" },
          { name: "Name", id: "name" },
          { name: "Email", id: "email" },
          { name: "Contact Number", id: "phone" },
          { name: "Status", id: "status" },
          { name: "Deleted?", id: "deleted_at" },
          { name: "Actions", id: "action" }
        ]}
        title={title}
        match={match}
        role={role}
        mode={"customer"}
      />
    );
  }
}

export default CustomerList;

import React, { Suspense } from "react";
import { createBrowserHistory } from 'history';
import { Provider } from "react-redux";
import {
  Router,
  Route,
  Switch
} from "react-router-dom";

import { CircularProgress } from "@material-ui/core";

import configureStore from "./store";
import "./helper/firebase";
import App from "./containers/App";

export const store = configureStore();

const MainApp = () => (
  <Provider store={store}>
    <Suspense fallback={<div style={{ display: 'flex', height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
      <CircularProgress style={{ color: '#e91e63' }} size={60} />
    </div>}>
      <Router history={createBrowserHistory()}>
        <Switch>
          <Route path="/" component={App} />
        </Switch>
      </Router>
    </Suspense>
  </Provider>
);

export default MainApp;

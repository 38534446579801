import axios from "axios";
import { NotificationManager } from "react-notifications";

import { errorResponse, validateApiData } from "../../util/validators/validators";
import { getCookies } from "../../util/getToken";

const SERVER_URL = process.env.REACT_APP_SERVER_URL

export const helpdeskView = async params => {
  let authToken = getCookies();
  try {
    const { data } = await axios({
      method: "post",
      url: `${SERVER_URL}/admin/helpdesk/view`,
      data: params,
      headers: { "x-auth-token": authToken }
    });
    return validateApiData(data) ? data : errorResponse;
  } catch (e) {
    console.log("Error in helpdesk", e);
    NotificationManager.error(errorResponse.message, "Oops!");
    return errorResponse;
  }
};
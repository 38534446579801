import {
  createBasicSettings,
  createWebsiteSettings,
  createPaymentSettings,
  createColorSettings,
  bannerCreate,
  bannerUpdate
} from "../apiHelpers/settings/create";
import {
  getAllSettings,
  getAdminThemeOptions,
  bannerList,
  deleteBanner
} from "../apiHelpers/settings/list";

import { getThemeColor } from "./Setting";

export const basicSettingCreateAction = (params, done) => async dispatch => {
  const list = await createBasicSettings(params);
  if (list.status == "OK") {
    done(true, list.data, list.message);
  } else {
    done(false, list.message);
  }
};

export const websiteSettingCreateAction = (params, done) => async dispatch => {
  const list = await createWebsiteSettings(params);
  if (list.status == "OK") {
    done(true, list.data, list.message);
  } else {
    done(false, list.message);
  }
};

export const paymentSettingCreateAction = (params, done) => async dispatch => {
  const list = await createPaymentSettings(params);
  if (list.status == "OK") {
    done(true, list.data, list.message);
  } else {
    done(false, list.message);
  }
};

export const colorSettingCreateAction = (params, done) => async dispatch => {
  const list = await createColorSettings(params);
  if (list.status == "OK") {
    done(true, list.data, list.message);
  } else {
    done(false, list.message);
  }
};

export const getSettingsAction = done => async dispatch => {
  const list = await getAllSettings();
  if (list.status == "OK") {
    done(true, list.data);
  } else {
    done(false, list.message);
  }
};

export const getAdminThemeColorAction = done => async dispatch => {
  const list = await getAdminThemeOptions();
  if (list.status == "OK") {
    dispatch(getThemeColor(list.data.main_color));
  } else {
  }
};

export const bannerListAction = done => async dispatch => {
  const apiData = await bannerList();
  if (apiData.status == "OK") {
    done(true, apiData.data);
  } else {
    done(false, apiData.message);
  }
};

export const bannerCreateAction = (params, done) => async dispatch => {
  const apiData = await bannerCreate(params);
  if (apiData.status == "OK") {
    done(true, apiData.data);
  } else {
    done(false, apiData.message);
  }
};

export const bannerUpdateAction = (params, done) => async dispatch => {
  const apiData = await bannerUpdate(params);
  if (apiData.status == "OK") {
    done(true, apiData.data);
  } else {
    done(false, apiData.message);
  }
};

export const deleteBannerAction = (params, done) => async dispatch => {
  const apiData = await deleteBanner(params);
  if (apiData.status == "OK") {
    done(true, apiData.data);
  } else {
    done(false, apiData.message);
  }
};
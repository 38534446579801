import React, { lazy } from "react";
import IntlMessages from '../../../util/IntlMessages';

const ContainerHeader = lazy(() => import("../../../components/ContainerHeader/index"));
const HOCLoader = lazy(() => import("../../../components/MyComponents/HOCLoader"));
const CustomerTabs = lazy(() => import("../../../components/MyComponents/CustomerTabs"));
const CardBox = lazy(() => import("../../../components/CardBox/index"));

class CustomerView extends React.Component {
  constructor(props) {
    super();
    this.state = {
      error: "",
      redirect: false,
      noResponse: false,
      loader: false,
      mainDetail: null,
      userId: props.location.state ? props.location.state.userId : ""
    };
  }

  componentDidMount() {
    if (!this.props.location.state) {
      this.setState({ redirect: true });
    }
  }

  render() {
    return (
      <HOCLoader
        loading={this.state.loader}
        noResponse={this.state.noResponse}
        redirect={this.state.redirect}
        redirectTo={`/app/user/customers`}
      >
        <div className="dashboard animated slideInUpTiny animation-duration-3">
          <ContainerHeader
            match={this.props.match}
            title={<IntlMessages id="userprofile" />}
          />
          <div className="row">
            <CardBox styleName="col-lg-12">
              <CustomerTabs user={this.state.userId} />
            </CardBox>
          </div>
        </div>
      </HOCLoader>
    );
  }
}

export default CustomerView;

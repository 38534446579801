import {
  providerRatingList,
  getRatingList,
  changeRatingStatus
} from "../apiHelpers/rating/list";

export const providerRatingListAction = (params, done) => async dispatch => {
  const apiData = await providerRatingList(params);
  if (apiData.status == "OK") {
    done(true, apiData.data);
  } else {
    done(false, apiData.message);
  }
};

export const ratingListAction = done => async dispatch => {
  const apiData = await getRatingList();
  if (apiData.status == "OK") {
    done(true, apiData.data);
  } else {
    done(false, apiData.message);
  }
};

export const changeRatingStatusAction = (params, done) => async dispatch => {
  const apiData = await changeRatingStatus(params);
  if (apiData.status == "OK") {
    done(true, apiData.data);
  } else {
    done(false, apiData.message);
  }
};
import axios from "axios";
import { NotificationManager } from "react-notifications";

import { errorResponse, validateApiData } from "../../util/validators/validators";

const SERVER_URL = process.env.REACT_APP_SERVER_URL

export const loginApi = async params => {
  try {
    const { data } = await axios({
      method: "post",
      url: `${SERVER_URL}/admin/user/login`,
      data: params
    });
    if (data.status == "OK") {
      NotificationManager.success(data.message, "Success!");
    } else if (data.status == "NOK") {
      NotificationManager.error(data.message, "Oops!");
    }
    return validateApiData(data) ? data : errorResponse;
  } catch (e) {
    console.log("Error in userlogin", e);
    NotificationManager.error(errorResponse.message, "Oops!");
    return errorResponse;
  }
};
import React, { Component } from "react";
import Card from "@material-ui/core/Card";
import CircularProgress from "@material-ui/core/CircularProgress";
import { validateCustomerAddressForm } from '../../util/validators/validateForm';
import { NotificationManager } from "react-notifications";
import * as actions from "../../actions";
import { connect } from "react-redux";
import { compose } from "redux";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
// import withCustomTheme from '../HOC/HOCwithCustomTheme.jsx';
import axios from "axios";
import { Redirect } from "react-router-dom";
import { Radio, Checkbox } from "@material-ui/core";
import { FormattedMessage, injectIntl } from "react-intl";
import { ROLE_CUSTOMER } from "../../util/config/constants";
import Geosuggest from "react-geosuggest";
import WSButton from "../Button/WSButton";
import WSTextField from "../TextField/WSTextField";
import { isMobile } from "react-device-detect";
const GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY;
const style = {
  width: "100%",
  height: "100%",
};
class CustomerAddressUpdate extends Component {
  constructor(props) {
    super();
    const commonStates = {
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      addressAdded: false,
      backRedirect: false,
      loader: false,
      pass: false,
      noResponse: false,
      errorMessage: "",
    };

    this.state = {
      error: "",
      address_line1: props.location.state
        ? props.location.state.item.address_line1
        : "",
      address_line1Error: "",
      city: props.location.state ? props.location.state.item.city : "",
      cityError: "",
      state: props.location.state ? props.location.state.item.state : "",
      stateError: "",
      country: props.location.state ? props.location.state.item.country : "",
      countryError: "",
      pincode: props.location.state ? props.location.state.item.zipcode : "",
      pincodeError: "",
      phone: props.location.state
        ? parseInt(props.location.state.item.phone)
        : "",
      phoneError: "",
      name: props.location.state ? props.location.state.item.name : "",
      nameError: "",
      emailError: "",
      email: props.location.state ? props.location.state.item.email : "",
      latitude: props.location.state
        ? String(props.location.state.item.latitude)
        : "",
      longitude: props.location.state
        ? String(props.location.state.item.longitude)
        : "",
      type_id: props.location.state ? props.location.state.item.type_id : "",
      addressId: props.location.state ? props.location.state.item.key : "",
      ...commonStates,
      is_default: props.location.state
        ? props.location.state.item.is_default
        : 0,
      role: props.location.state
        ? props.location.state.providerDetails.role
        : 0,
      userId: props.location.state ? props.location.state.userId : "",
    };
  }

  componentDidMount() {
    const { location } = this.props;
    if (!location.state) {
      this.setState({ backRedirect: true });
    }
  }

  submit = (params) => {
    const {
      address_line1,
      city,
      state,
      country,
      pincode,
      name,
      email,
      phone,
      latitude,
      longitude,
      type_id,
      is_default,
      addressId,
      userId,
    } = this.state;
    const { updateAddressAction } = this.props;
    if (
      validateCustomerAddressForm(
          {
              address_line1,
              city,
              state,
              country,
              pincode,
              name,
              email,
              phone
          },
          this.handleError
      )
  ) {
    this.setState({ loader: true });
    updateAddressAction(
      {
        address_line1,
        city,
        state,
        country,
        zipcode: pincode,
        name,
        email,
        phone,
        latitude,
        longitude,
        type_id,
        is_default: is_default ? 1 : 0,
        id: addressId,
        providerId: userId,
      },
      this.onDone
    );
  } else {
    NotificationManager.error(
        <FormattedMessage
            id="Validation Error"
            defaultMessage="Validation Error"
        />,
        'Oops!'
    );
}
  };

  handleError = (params) => {
    this.setState(params);
  };

  onDone = (success, data) => {
    const { history, location } = this.props;
    const { state } = location;
    const { userId } = state;
    this.setState({ loader: false });
    if (success) {
      history.push({
        pathname: `/app/provider/update/${userId}`,
        state: { userId: userId },
      });

      NotificationManager.success(
        <FormattedMessage
          id="addressUpdatedSuccess"
          defaultMessage="Address updated successfully"
        />,
        "Success"
      );
    }
  };

  renderButton = () => {
    const { loader } = this.state;
    if (loader) {
      return <CircularProgress />;
    }
    return (
      <WSButton variant="raised" className="jr-btn" onClick={this.submit}>
        <FormattedMessage id="update" defaultMessage="Update" />
      </WSButton>
    );
  };

  onMarkerClick = (props, marker, e) => {
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
    });
  };

  onMarkerDragEnd = (coord, index) => {
    const { markers } = this.state;
    const { latLng } = coord;
    const lat = latLng.lat();
    const lng = latLng.lng();
    this.setState({ latitude: String(lat), longitude: String(lng) }, () => {
      this.getPlace(lat, lng);
    });
  };

  getPlace = async (lat, lng) => {
    const { settings } = this.props;
    let latLng = parseFloat(lat) + "," + parseFloat(lng);

    const { data } = await axios({
      // method: 'get',
      url: `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latLng}&key=${settings.constants.GOOGLE_MAP_KEY}`,
      // headers: {
      //     'Access-Control-Allow-Origin': '*',
      //     'Content-Type': 'application/json'
      // }
    });
    if (data.status === "OK") {
      if (data.results[0].formatted_address) {
        this.setState({
          address_line1: data.results[0].formatted_address,
        });
      }
      data.results[0].address_components.map((address) => {
        if (address.types.includes("country")) {
          this.setState({ country: address.long_name });
        } else if (address.types.includes("postal_code")) {
          // pincode = address.long_name;
          this.setState({ pincode: address.long_name });
        } else if (address.types.includes("administrative_area_level_1")) {
          this.setState({ state: address.long_name });
        } else if (
          address.types.includes("administrative_area_level_2") ||
          address.types.includes("locality")
        ) {
          this.setState({ city: address.long_name });
        }
      });
    } else {
      NotificationManager.error(
        <FormattedMessage
          id="googleApiNotWorking"
          defaultMessage="Google Api not working, Please add address manually"
        />,
        "Error"
      );
    }
  };

  handleRadioChange = (event) => {
    this.setState({ type_id: event.target.value });
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  handleSelectPlace = (event) => {
    if (event) {
      this.setState(
        {
          latitude: String(event.location.lat),
          longitude: String(event.location.lng),
          centerLat: event.location.lat,
          centerLng: event.location.lng,
        },
        () => {
          this.getPlace(event.location.lat, event.location.lng);
        }
      );
    }
  };

  render() {
    const {
      address_line1,
      city,
      state,
      country,
      pincode,
      name,
      email,
      phone,
      loader,
      address_line1Error,
      cityError,
      stateError,
      countryError,
      // pincodeError,
      phoneError,
      emailError,
      nameError,
      latitude,
      longitude,
      addressAdded,
      backRedirect,
      activeMarker,
      showingInfoWindow,
      selectedPlace,
      type_id,
      is_default,
      role,
      userId,
    } = this.state;
    const { userData, google, intl, location } = this.props;
    if (addressAdded) {
      return <Redirect to={`/app/provider/update/${location.state.userId}`} />;
    }
    if (backRedirect) {
      return <Redirect to={`/app/user/providers`} />;
    }
    return (
      <Card className="col-md-12">
        <div>
          <h4>
            <b>
              <FormattedMessage
                id="markerNote"
                defaultMessage="Note: Drag marker on map to fetch location"
              />
            </b>
          </h4>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div style={{ height: 300 }}>
              <Map
                style={style}
                google={google}
                zoom={14}
                center={{
                  lat: String(latitude),
                  lng: String(longitude),
                }}
              >
                <Marker
                  position={{
                    lat: String(latitude),
                    lng: String(longitude),
                  }}
                  draggable={true}
                  onDragend={(t, map, coord) => this.onMarkerDragEnd(coord)}
                />
              </Map>
            </div>
          </div>
        </div>
        <div
          className={isMobile && "width-300 overflow-hidden search-location"}
        >
          <Geosuggest
            suggestItemClassName="autosuggest-places"
            onSuggestSelect={this.handleSelectPlace}
            placeholder="Search Address Here or Drag Marker in Map"
          />
        </div>

        <form className="row" noValidate autoComplete="off" id="address-create">
          <div className="col-md-4 col-xs-12">
            <WSTextField
              error={nameError ? true : false}
              helperText={nameError}
              name="name"
              label="Name"
              id="name"
              placeholder="Name"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) => {
                this.setState({
                  name: event.target.value,
                  nameError: "",
                  error: "",
                });
              }}
              value={name}
              defaultValue={name}
              disabled={false}
              className={isMobile && "mt-0"}
            />
          </div>
          <div className="col-md-4 col-xs-12">
            <WSTextField
              error={emailError ? true : false}
              helperText={emailError}
              type="email"
              name="email"
              label="Email"
              id="email"
              placeholder="Email"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) => {
                this.setState({
                  email: event.target.value,
                  emailError: "",
                  error: "",
                });
              }}
              value={email}
              defaultValue={email}
              disabled={false}
              className={isMobile && "mt-0"}
            />
          </div>
          <div className="col-md-4 col-xs-12">
            <WSTextField
              error={phoneError ? true : false}
              helperText={phoneError}
              name="phone"
              label="Phone"
              id="phone"
              placeholder="Phone"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) => {
                this.setState({
                  phone: parseInt(event.target.value),
                  phoneError: "",
                  error: "",
                });
              }}
              defaultValue={phone}
              value={phone}
              disabled={false}
              className={isMobile && "mt-0"}
            />
          </div>
          <div className="col-md-4 col-xs-12">
            <WSTextField
              error={address_line1Error ? true : false}
              helperText={address_line1Error}
              name="address_line1"
              label="Address"
              id="address_line1"
              placeholder="Address"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) => {
                this.setState({
                  address_line1: event.target.value,
                  address_line1Error: "",
                  error: "",
                });
              }}
              value={address_line1}
              defaultValue={address_line1}
              disabled={loader ? true : false}
              className={isMobile && "mt-0"}
            />
          </div>
          <div className="col-md-4 col-xs-12">
            <WSTextField
              error={cityError ? true : false}
              helperText={cityError}
              name="city"
              label="City"
              id="city"
              placeholder="City"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) => {
                this.setState({
                  city: event.target.value,
                  cityError: "",
                  error: "",
                });
              }}
              value={city}
              defaultValue={city}
              disabled={loader ? true : false}
              className={isMobile && "mt-0"}
            />
          </div>
          <div className="col-md-4 col-xs-12">
            <WSTextField
              error={stateError ? true : false}
              helperText={stateError}
              name="state"
              label="State"
              id="state"
              placeholder="State"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) => {
                this.setState({
                  state: event.target.value,
                  stateError: "",
                  error: "",
                });
              }}
              value={state}
              defaultValue={state}
              disabled={loader ? true : false}
              className={isMobile && "mt-0"}
            />
          </div>
          <div className="col-md-4 col-xs-12">
            <WSTextField
              error={countryError ? true : false}
              helperText={countryError}
              name="country"
              label="Country"
              id="country"
              placeholder="Country"
              InputLabelProps={{
                shrink: true,
              }}
              onChange={(event) => {
                this.setState({
                  country: event.target.value,
                  countryError: "",
                  error: "",
                });
              }}
              value={country}
              defaultValue={country}
              disabled={loader ? true : false}
              className={isMobile && "mt-0"}
            />
          </div>
          {role === ROLE_CUSTOMER && (
            <div className="col-md-4">
              <div className="row">
                <div className="col-md-4">
                  <div className="row">
                    <Radio
                      checked={type_id == 1}
                      color="primary"
                      onChange={(event) => this.handleRadioChange(event)}
                      value={1}
                    />
                    <p className={isMobile && "mt-15"}>
                      <FormattedMessage id="home" defaultMessage="Home" />
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="row">
                    <Radio
                      checked={type_id == 2}
                      color="primary"
                      onChange={(event) => this.handleRadioChange(event)}
                      value={2}
                    />
                    <p className={isMobile && "mt-15"}>
                      <FormattedMessage id="work" defaultMessage="Work" />
                    </p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="row">
                    <Radio
                      checked={type_id == 3}
                      color="primary"
                      onChange={(event) => this.handleRadioChange(event)}
                      value={3}
                    />
                    <p className={isMobile && "mt-15"}>
                      <FormattedMessage id="other" defaultMessage="Other" />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="col-md-4">
            <div className="row align-items-center">
              <Checkbox
                checked={is_default}
                color="primary"
                onChange={this.handleChange("is_default")}
                value="is_default"
              />
              <p className="mt-1">
                <FormattedMessage
                  id="setAsOffice"
                  defaultMessage="Set as Default"
                />
              </p>
            </div>
          </div>
          <div className="col-md-12 col-xs-12">
            <div className={!isMobile && "mt-3"} />
            {this.renderButton()}
          </div>
        </form>
      </Card>
    );
  }
}
const mapStateToProps = ({ auth }) => {
  return { userData: auth.userData };
};
const composedComponent = compose(
  GoogleApiWrapper((props) => ({
    apiKey: `${GOOGLE_MAP_KEY}`,
  })),

  connect(mapStateToProps, actions)
);
export default composedComponent(injectIntl(CustomerAddressUpdate));

import {
  CUSTOMER_CREATE,
  CUSTOMER_CREATE_SUCCESS,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  HIDE_MESSAGE,
  SHOW_MESSAGE,
  LIST_USERS,
  GET_USERS,
  CUSTOMER_DETAIL,
  FETCH_DETAIL,
  SORT_CUSTOMER,
  FETCH_SORT_CUSTOMER
} from "../constants/ActionTypes";

export const customerCreate = (params, done) => {
  return {
    type: CUSTOMER_CREATE,
    payload: { params, done }
  };
};

export const getUsers = params => {
  return {
    type: GET_USERS,
    payload: params
  };
};

export const detail = params => {
  return {
    type: CUSTOMER_DETAIL,
    payload: params
  };
};

export const fetchDetail = params => {
  return {
    type: FETCH_DETAIL,
    payload: params
  };
};

export const listUsers = params => {
  return {
    type: LIST_USERS,
    payload: params
  };
};

export const customerCreateSuccess = createdUser => {
  return {
    type: CUSTOMER_CREATE_SUCCESS,
    payload: createdUser
  };
};

export const showLoader = () => {
  return {
    type: ON_SHOW_LOADER
  };
};

export const hideLoader = () => {
  return {
    type: ON_HIDE_LOADER
  };
};

export const showMessage = () => {
  return {
    type: SHOW_MESSAGE
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE
  };
};

export const sortCustomer = params => {
  return {
    type: SORT_CUSTOMER,
    payload: params
  };
};

export const fetchSortCustomer = params => {
  return {
    type: FETCH_SORT_CUSTOMER,
    payload: params
  };
};
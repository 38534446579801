import {
  serviceCreate,
  serviceUpdate,
  serviceDelete,
  serviceRestore,
  subcategoryCreate,
  subcategoryUpdate,
  subcategoryDelete,
  subcategoryRestore
} from "../apiHelpers/service/create";
import { serviceList, subcategoryList } from "../apiHelpers/service/list";
import { getServiceDetail, getSubcategoryDetail } from "../apiHelpers/service/detail";

export const serviceCreateAction = (params, done) => async dispatch => {
  const apiData = await serviceCreate(params);
  if (apiData.status == "OK") {
    done(true, apiData.data);
  } else {
    done(false, apiData.message);
  }
};

export const serviceListAction = done => async dispatch => {
  const apiData = await serviceList();
  if (apiData.status == "OK") {
    done(true, apiData.data);
  } else {
    done(false, apiData.message);
  }
};

export const serviceDetailAction = (params, done) => async dispatch => {
  const apiData = await getServiceDetail(params);
  if (apiData.status == "OK") {
    done(true, apiData.data);
  } else {
    done(false, apiData.message);
  }
};

export const serviceUpdateAction = (params, done) => async dispatch => {
  const apiData = await serviceUpdate(params);
  if (apiData.status == "OK") {
    done(true, apiData.data);
  } else {
    done(false, apiData.message);
  }
};

export const deleteServiceAction = (params, done) => async dispatch => {
  const apiData = await serviceDelete(params);
  if (apiData.status == "OK") {
    done(true, apiData.data);
  } else {
    done(false, apiData.message);
  }
};

export const restoreServiceAction = (params, done) => async dispatch => {
  const apiData = await serviceRestore(params);
  if (apiData.status == "OK") {
    done(true, apiData.data);
  } else {
    done(false, apiData.message);
  }
};

export const subcategoryCreateAction = (params, done) => async dispatch => {
  const apiData = await subcategoryCreate(params);
  if (apiData.status == "OK") {
    done(true, apiData.data);
  } else {
    done(false, apiData.message);
  }
};

export const subcategoryListAction = done => async dispatch => {
  const apiData = await subcategoryList();
  if (apiData.status == "OK") {
    done(true, apiData.data);
  } else {
    done(false, apiData.message);
  }
};

export const subcategoryDetailAction = (params, done) => async dispatch => {
  const apiData = await getSubcategoryDetail(params);
  if (apiData.status == "OK") {
    done(true, apiData.data);
  } else {
    done(false, apiData.message);
  }
};

export const subcategoryUpdateAction = (params, done) => async dispatch => {
  const apiData = await subcategoryUpdate(params);
  if (apiData.status == "OK") {
    done(true, apiData.data);
  } else {
    done(false, apiData.message);
  }
};

export const deleteSubcategoryAction = (params, done) => async dispatch => {
  const apiData = await subcategoryDelete(params);
  if (apiData.status == "OK") {
    done(true, apiData.data);
  } else {
    done(false, apiData.message);
  }
};

export const restoreSubCategoryAction = (params, done) => async dispatch => {
  const apiData = await subcategoryRestore(params);
  if (apiData.status == "OK") {
    done(true, apiData.data);
  } else {
    done(false, apiData.message);
  }
};
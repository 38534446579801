import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import Datetime from "react-datetime";
import { isMobile } from "react-device-detect";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import { Fade, FormControl, CircularProgress } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { withStyles } from "@material-ui/core/styles";

import * as actions from "../../../actions";

import WSButton from "../../../components/Button/WSButton";

class TimePickerPopup extends Component {
  constructor(props) {
    super();
    this.state = {
      closeVerification: false,
    };
  }

  handleClose = () => {
    const { closePopup } = this.props;
    closePopup();
  };

  handleError = (params) => {
    this.setState(params);
  };

  renderButton = () => {
    const { loaderButton } = this.props;
    if (loaderButton) {
      return <CircularProgress />;
    } else {
      return (
        <WSButton
          style={{
            borderRadius: 30,
          }}
          onClick={this.onSave}
        >
          Add
        </WSButton>
      );
    }
  };

  onSave = () => {
    const { saveDate } = this.props;
    saveDate();
  };

  render() {
    const {
      isVisible,
      theme,
      dateFormat,
      open,
      valueStartTime,
      onChangeStartDate,
      defaultValueStartTime,
      valueEndTime,
      onChangeEndDate,
      defaultValueEndTime,
      timeFormat,
    } = this.props;
    return (
      <Dialog
        fullWidth={true}
        TransitionComponent={Fade}
        className="text-center dialog-form-new dialog-form-new2"
        open={isVisible}
        onClose={this.handleClose}
        aria-labelledby="form-dialog-title"
      >
        <div
          className="dialog-header"
          style={{
            backgroundColor: theme.palette.primary.main,
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            paddingLeft: 15,
          }}
        >
          <h3
            className={isMobile ? "dialog-heading font-16" : "dialog-heading"}
          >
            Start Time & End Time
          </h3>
          <IconButton
            color="inherit"
            onClick={this.handleClose}
            aria-label="Close"
          >
            <CloseIcon style={{ color: "white" }} />
          </IconButton>
        </div>
        <div className="dialog-body">
          <DialogContent style={{ minHeight: "250px" }}>
            <div style={{ flexDirection: "row" }}>
              <FormControl style={{ width: "40%" }}>
                <Datetime
                  dateFormat={dateFormat}
                  open={open}
                  timeFormat={timeFormat}
                  color="primary"
                  inputProps={{
                    placeholder: "Click here to choose start time",
                  }}
                  value={valueStartTime}
                  onChange={onChangeStartDate}
                  defaultValue={defaultValueStartTime}
                />
              </FormControl>
              <FormControl
                style={{
                  width: "10%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <span></span>
              </FormControl>
              <FormControl style={{ width: "40%" }}>
                <Datetime
                  dateFormat={dateFormat}
                  open={open}
                  timeFormat={timeFormat}
                  color="primary"
                  inputProps={{
                    placeholder: "Click here to choose end time",
                  }}
                  value={valueEndTime}
                  onChange={onChangeEndDate}
                  defaultValue={defaultValueEndTime}
                />
              </FormControl>
            </div>
          </DialogContent>
          <DialogActions>{this.renderButton()}</DialogActions>
        </div>
      </Dialog>
    );
  }
}

const composedHOC = compose(
  withStyles(null, { withTheme: true }),
  connect(null, actions)
);

export default composedHOC(TimePickerPopup);

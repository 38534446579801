import React, { lazy, Fragment } from "react";
import { injectIntl } from "react-intl";
import { NotificationManager } from "react-notifications";
import ReactCrop from "react-image-crop";
import { compose } from "redux";
import { connect } from "react-redux";
import Dropzone from "react-dropzone";
import "react-image-crop/dist/ReactCrop.css";
import moment from "moment";
import { Formik } from "formik";
import { isMobile } from "react-device-detect";
import _ from "lodash";
import PhoneInput from "react-phone-input-2";

import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
import { DatePicker } from "@material-ui/pickers";
import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Divider,
  FormGroup,
  List,
  ListItem,
  ListItemText,
  Checkbox,
  ListItemIcon,
  Paper,
} from "@material-ui/core";

import Selects from "../../../components/Select/Select";
import Option from "../../../components/Select/Option";
import WSTextField from "../../../components/TextField/WSTextField";
import WSButton from "../../../components/Button/WSButton";
import ProviderServiceTable from "../../../components/MyComponents/ProviderServiceTable";
import AddressList from "../../../components/MyComponents/AddressList";
import ProviderPortfolio from "../../../components/MyComponents/ProviderPortfolio";

import * as actions from "../../../actions";
import {
  getCameraBrandList,
  getSkinColor,
  getHairType,
  getHairColor,
  getEthinicity,
  getEyeColor,
  timingData,
} from "../../../util/config/constants";
import IntlMessages from "../../../util/IntlMessages";

import TimePickerPopup from "./TimePickerpopup";

import "../../../styles/style.scss";

const ContainerHeader = lazy(() => import("../../../components/ContainerHeader/index"));
const CardBox = lazy(() => import("../../../components/CardBox/index"));
const HOCLoader = lazy(() => import("../../../components/MyComponents/HOCLoader"));
const MySwitch = lazy(() => import("../../../components/MyComponents/MySwitch"));

const handleDropRejected = (...args) => { this.setState({ showOld: true }); }
class ProviderUpdate extends React.Component {
  constructor(props) {
    super();
    this.state = {
      error: "",
      nameError: "",
      emailError: "",
      businessNameError: "",
      description: "",
      descriptionError: "",
      businessName: "",
      email: "",
      name: "",
      phone: "",
      contactNo_Resi: "",
      createdOn: "",
      shortBrief: "",
      sex: "",
      nationality: [],
      iGProfile: "",
      fBProfile: "",
      website: "",
      licenseType: "",
      licenseNo: "",
      licenseValidtill: "",
      baseLocation: "",
      contactNo_Office: "",
      estDate: "",
      phoneError: "",
      loader: true,
      loaderButton: false,
      pass: false,
      noResponse: false,
      errorMessage: "",
      preview: null,
      generalPhotos: "",
      status: 1,
      crop: {
        x: 40,
        y: 40,
        width: 100,
        height: 100,
      },
      is_blocked: false,
      is_deleted: false,
      isFeatured: false,
      isRecommended: false,
      isApprovedByAdmin: false,
      userId: props?.location?.state ? props?.location?.state?.userId : "",
      providerDetail: "",
      establishedOn: moment().valueOf(),
      existingImage: "",
      adminCut: "",
      experienceRating: "",
      experienceRatingError: "",
      priceRating: "",
      priceRatingError: "",
      prepapredData: [],
      allSelectedCategory: [],
      profession: "",
      selectedCategory: null,
      selectedCategoryId: null,
      removedProvider: [],
      categoryData: [],
      categoryDetails: [],
      phoneNoActive: false,
      resNoActive: false,
      contactNoActive: false,
      isDateTimePickerVisible: false,
      timeLoader: false,
      timing: {},
      timingData: timingData,
      serviceData: [],
      showOld: true,
    };

    this.handleDrop = this.handleDrop.bind(this);
  }
  componentDidMount() {
    if (!this.props?.location) {
      return this.props?.history.push("/app/user/providers");
    } else {
      let url = this.props?.location?.pathname;
      let userId = url.split("/").pop();
      this.setState({ userId: userId });
      this.getData(userId);
    }
  }

  updateProfessionDetailAPI = (values) => {
    const {
      is_blocked,
      is_deleted,
      description,
      isFeatured,
      isRecommended,
      isApprovedByAdmin,
      establishedOn,
      adminCut,
      prepapredData,
      experienceRating,
      priceRating,
      removedProvider,
      createdOn,
      timingData,
      userId,
    } = this.state;

    let categoryDetails = prepapredData &&
      prepapredData.map((category) => ({
        category: category?.category,
        subcategories: category?.subcategories.filter((item) => item?.checked === true),
      }));

    let isProfessionUpdate = [];

    categoryDetails = categoryDetails &&
      categoryDetails.map((cat) => {
        const subValues = cat?.subcategories.map(function (obj) {
          return obj.key;
        });
        cat.subcategories = subValues;

        if (cat?.category === "5d0e20d44a53032881f3d9a5") {
          cat.form = {
            height: values[`height${cat?.category}`],
            bustCms: values[`bustCms${cat?.category}`],
            bustInches: values[`bustInches${cat?.category}`],
            waistCms: values[`waistCms${cat?.category}`],
            waistInches: values[`waistInches${cat?.category}`],
            hipsCms: values[`hipsCms${cat?.category}`],
            hipsInches: values[`hipsInches${cat?.category}`],
            shoeSize: values[`shoeSize${cat?.category}`],
            skinColor: values[`skinColor${cat?.category}`],
            hairType: values[`hairType${cat?.category}`],
            hairColor: values[`hairColor${cat?.category}`],
            eyeColor: values[`eyeColor${cat?.category}`],
            ethinicity: values[`ethinicity${cat?.category}`],
          };
        }
        if (
          cat?.category === "5cff874866f1d1714902de7d" ||
          cat?.category === "5d08d65f737a8212cae898a4"
        ) {
          cat.form = {
            cameraBrand1: values[`cameraBrand1${cat?.category}`],
            cameraBrand2: values[`cameraBrand2${cat?.category}`],
            cameraBrand3: values[`cameraBrand3${cat?.category}`],
            cameraModel1: values[`cameraModel1${cat?.category}`],
            cameraModel2: values[`cameraModel2${cat?.category}`],
            cameraModel3: values[`cameraModel3${cat?.category}`],
            otherGear: values[`otherGear${cat?.category}`],
          };
        }
        if (subValues?.length > 5) {
          NotificationManager.error("You have select upto 5 sub categories", null, 2000);
          isProfessionUpdate.push({ status: false });
        } else if (subValues?.length < 1) {
          NotificationManager.error("You have select atleast 1 sub category", null, 2000);
          isProfessionUpdate.push({ status: false });
        } else {
          isProfessionUpdate.push({ status: true });
        }
        return cat;
      });
    this.setState({ loaderButton: true });
    this.props.providerUpdateAction(
      {
        providerId: userId,
        name: values?.name,
        email: values?.email,
        businessName: values?.businessName,
        establishedOn: moment(establishedOn).valueOf(),
        phone: values?.phone,
        bio: description,
        is_blocked: is_blocked ? 1 : 2,
        is_deleted: is_deleted ? 1 : 2,
        image: this.state?.croppedImageUrl,
        isFeatured: isFeatured ? 1 : 2,
        isRecommended: isRecommended ? 1 : 2,
        isApprovedByAdmin: isApprovedByAdmin,
        adminCut: adminCut,
        experienceRating,
        priceRating,
        providerTypes: categoryDetails,
        removeProviderType: removedProvider,
        baseLocation: values?.baseLocation,
        contactNo_Office: values?.contactNo_Office,
        createdOn,
        fBProfile: values?.fBProfile,
        iGProfile: values?.iGProfile,
        languageSpoken1: values?.languageSpoken1,
        languageSpoken2: values?.languageSpoken2,
        languageSpoken3: values?.languageSpoken3,
        licenseNo: values?.licenseNo,
        licenseType: values?.licenseType,
        licenseValidtill: values?.licenseValidtill,
        nationality: values?.nationality,
        contactNo_Resi: values?.contactNo_Resi,
        sex: values?.sex,
        shortBrief: values?.shortBrief,
        website: values?.website,
        timingPacket: timingData,
      },
      this.onDone
    );
  };

  //apicall
  getData = async (userId) => {
    this.props.providerDetailAction({ providerId: userId }, this.onDoneDetail);
    this.props.providerServiceListAction({ providerId: userId }, this.onServiceDetail);
    this.props.categoryListAction(this.getCategoryList);
  };
  //apicall
  getCategoryList = (success, data) => {
    this.setState({
      categoryData: data,
      timingData: this.props?.providerDetails?.workingHours?.timing_packet,
    });
  };
  onServiceDetail = (success, data) => {
    this.setState({ serviceData: data });
  };
  onDoneDetail = (success, data) => {
    if (success) {
      this.setState(
        {
          providerDetail: data?.detail,
          is_deleted: data?.detail.is_deleted == 1,
          is_blocked: data?.detail.is_blocked == 1,
          name: data?.detail?.name,
          sex: data?.detail?.sex,
          nationality: data?.detail?.nationality,
          email: data?.detail?.email,
          phone: data?.detail?.phone,
          contactNo_Resi: data?.detail?.contactNo_Resi,
          createdOn: data?.detail?.created_at,
          shortBrief: data?.detail?.shortBrief,
          iGProfile: data?.detail?.iGProfile,
          fBProfile: data?.detail?.fBProfile,
          website: data?.detail?.website,
          licenseType: data?.detail?.licenseType,
          licenseNo: data?.detail?.licenseNo,
          licenseValidtill: data?.detail?.licenseValidtill,
          baseLocation: data?.detail?.baseLocation,
          contactNo_Office: data?.detail?.contactNo_Office,
          businessName: data?.detail?.businessName,
          isFeatured: data?.detail.is_featured == 1,
          isRecommended: data?.detail?.isRecommended == 1,
          isApprovedByAdmin: data?.detail?.isApprovedByAdmin,
          establishedOn: moment(Number(data?.detail?.establishedOn)),
          existingImage: data?.detail?.proImage?.medium,
          experienceRating: data?.detail?.experienceRating,
          priceRating: data?.detail?.priceRating,
          languageSpoken1: data?.detail?.languageSpoken1,
          languageSpoken2: data?.detail?.languageSpoken2,
          languageSpoken3: data?.detail?.languageSpoken3,
          adminCut: data?.detail?.adminCut,
        },
        () => { this.setState({ loader: false }); }
      );
    } else {
      this.setState({ loader: false, noResponse: true, error: data });
    }
    const { providerTypes } = data?.detail;

    if (providerTypes?.length) {
      let categories = [];
      let subCategories = [];
      providerTypes.forEach((category, index) => {
        const categoryDetails = {
          catName: category?.providerType?.name,
          catId: category?.providerType?.id,
        };
        categories.push(categoryDetails);
        const prepareDetails = {
          category: category?.providerType?.id,
          subcategories: category?.subcategories.map((sub) => ({
            ...sub,
            checked: true,
            key: sub?.id,
            heading: sub?.name,
          })),
          type: category?.providerType?.name,
        };
        subCategories.push(prepareDetails);
        for (const property in category?.modelPacket) {
          this.setState({ [`${property}${category?.providerType?.id}`]: category?.modelPacket[property], });
        }
      });
      this.setState({
        allSelectedCategory: categories,
        prepapredData: subCategories,
        selectedCategory: categories[0]?.catName,
        selectedCategoryId: categories[0]?.catId,
        profession: categories[0]?.catId,
        isShowSubCategory: true,
      });
    }
  };
  handleClick = (index) => {
    const timingData = [...this.state?.timingData];
    timingData[index].status = !timingData[index]?.status;
    this.setState({ timingData });
  };
  onTimePress = (index) => {
    this.selectedType = "all";
    this.selectedIndex = index;
    this.setState({ isDateTimePickerVisible: true });
  };
  saveDate = () => {
    const { timeValueStart, timeValueEnd, timing } = this.state;
    this.setState({ timeLoader: true });
    const timingData = [...this.state?.timingData];
    if (this.selectedType === "all") {
      timingData[this.selectedIndex].startTime = timeValueStart || (timing?.startTime);
      timingData[this.selectedIndex].endTime = timeValueEnd || (timing?.endTime);
    }
    this.setState({
      timingData: timingData,
      isDateTimePickerVisible: false,
      timeLoader: false,
    });
  };
  onChangeStartDate = (event) => {
    const dateTimeValue = moment(event).format("hh:mm A");
    this.setState({ timeValueStart: dateTimeValue });
  };
  onChangeDateEnd = (event) => {
    const dateTimeValue = moment(event).format("hh:mm A");
    this.setState({ timeValueEnd: dateTimeValue });
  };
  handleClose = () => {
    this.setState({ isDateTimePickerVisible: false });
  };
  getSubcategories = (value) => {
    const { prepapredData, providerDetail, categoryData } = this.state;
    const formData = providerDetail?.providerTypes.filter((item) => item?.providerType?.name === value);
    const filterData = categoryData.filter((item) => item?.heading === value);
    prepapredData.forEach((item) => {
      if (item?.type === value) {
        item.subcategories = _.unionBy(item?.subcategories, filterData && filterData[0]?.subCategories, "key");
        item.formData = formData && formData[0]?.modelPacket;
      }
    });
    this.setState({ prepapredData, });
  };

  handleError = (params) => {
    this.setState(params);
  };

  onDone = (success) => {
    setTimeout(() => {
      this.setState({ loaderButton: false, removedProvider: [] });
      const { userId } = this.state;
      if (success) {
        return this.props?.history.push({
          pathname: `/app/provider/update/${userId}`,
          state: { userId: userId },
        });
      }
    }, 4000);
  };
  renderButton = (props) => {
    if (this.state?.loaderButton) {
      return <CircularProgress />;
    }
    return (
      <Button
        variant="contained"
        color="primary"
        className="jr-btn"
        onClick={props?.handleSubmit}
      >
        Update
      </Button>
    );
  };
  handleDrop([{ preview }]) {
    this.setState({ preview });
    this.setState({ showOld: false });
  }

  onImageLoaded = (image) => {
    this.imageRef = image;
  };
  onCropComplete = async (crop) => {
    if (this.imageRef && crop?.width && crop?.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ croppedImageUrl });
    }
  };

  getCroppedImg(image, pixelCrop) {
    const canvas = document.createElement("canvas");
    const scaleX = image?.naturalWidth / image?.width;
    const scaleY = image?.naturalHeight / image?.height;
    canvas.width = pixelCrop?.width;
    canvas.height = pixelCrop?.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      pixelCrop?.x * scaleX,
      pixelCrop?.y * scaleY,
      pixelCrop?.width * scaleX,
      pixelCrop?.height * scaleY,
      0,
      0,
      pixelCrop?.width,
      pixelCrop?.height
    );

    return new Promise((resolve, reject) => {
      this.fileUrl = canvas.toDataURL();
      resolve(this.fileUrl);
    });
  }

  handleDateChange = (date) => {
    this.setState({ establishedOn: date, });
  };
  render() {
    const { match, languagesData, nationalityData, providerDetails, } = this.props;
    const { workingHours } = providerDetails || {};
    const {
      error,
      emailError,
      nameError,
      phoneError,
      businessNameError,
      description,
      descriptionError,
      preview,
      croppedImageUrl,
      is_blocked,
      is_deleted,
      isFeatured,
      isRecommended,
      isApprovedByAdmin,
      providerDetail,
      loaderButton,
      establishedOn,
      existingImage,
      adminCutError,
      experienceRatingError,
      priceRatingError,
      prepapredData,
      allSelectedCategory,
      selectedCategory,
      selectedCategoryId,
      removedProvider,
      isShowSubCategory,
      profession,
      categoryData,
      phoneNoActive,
      resNoActive,
      contactNoActive,
      isDateTimePickerVisible,
      dateTimeValue,
      timing,
      timeLoader,
      timingData,
      showOld,
      ...rest
    } = this.state;
    return (
      <HOCLoader
        loading={this.state?.loader}
        noResponse={this.state?.noResponse}
        redirect={this.state?.redirect}
        redirectTo={`/app/user/providers`}
        errorShow={error}
      >
        <div className="animated slideInUpTiny animation-duration-3">
          <ContainerHeader
            title={<IntlMessages id="update-provider" />}
            match={match}
          />

          <div className="row">
            <CardBox styleName="col-lg-12">
              <div className="selectCategories mt-4">
                <Formik
                  initialValues={{ ...rest }}
                  enableReinitialize={true}
                  onSubmit={(values) => {
                    this.setState({ ...values });
                    this.updateProfessionDetailAPI(values);
                  }}
                >
                  {(props) => (
                    <form className="dialog-form dialog-form-new" autoComplete="off" >
                      <div className="row col-md-12">
                        {!preview && (
                          <div className="col-md-2 mr-4">
                            {existingImage && (
                              <div>
                                <h3>Provider Existing Image</h3>
                                <img
                                  width={"200px"}
                                  height={"200px"}
                                  alt="existingImage"
                                  src={existingImage}
                                />
                              </div>
                            )}
                          </div>
                        )}
                        <div className="col-md-2">
                          <h3>Drop Image here to Upload</h3>
                          <Dropzone
                            onDrop={this.handleDrop}
                            accept="image/jpeg,image/jpg,image/png"
                            multiple={false}
                            onDropRejected={handleDropRejected}
                          >
                            <p> Drag a file here or click to upload.</p>
                          </Dropzone>
                        </div>
                        <div className="col-md-5">
                          {!showOld && preview && (
                            <div>
                              <h3>Original Image</h3>
                              <ReactCrop
                                src={preview}
                                imageStyle={{
                                  width: "100%",
                                  height: "100%",
                                }}
                                onImageLoaded={this.onImageLoaded}
                                onComplete={this.onCropComplete}
                                crop={this.state?.crop}
                                onChange={(crop) => {
                                  this.setState({ crop });
                                }}
                              />
                            </div>
                          )}
                        </div>
                        <div className="col-md-5">
                          {!showOld && croppedImageUrl && (
                            <>
                              <div>
                                <h3>Final Image</h3>
                                <img
                                  width={"200px"}
                                  height={"200px"}
                                  alt="Crop"
                                  src={croppedImageUrl}
                                />
                              </div>
                              <WSButton
                                style={{
                                  marginLeft: 245,
                                  marginTop: -200,
                                }}
                                onClick={() => { this.setState({ showOld: true, preview: null }) }}
                              >
                                Cancel
                              </WSButton>
                            </>
                          )}
                        </div>
                      </div>

                      <div className="row col-md-12 mt-4">
                        <div className={`col-md-6 col-xs-12 postion-relative mb-2 ${!isMobile && "pr-0 pl-30"}`} >
                          <span className="profile-label" style={{ paddingLeft: isMobile && 15 }} >
                            Name <span className="primary-color">*</span>
                          </span>
                          <WSTextField
                            error={props?.errors?.name && props?.touched?.name}
                            helperText={props?.errors?.name && props?.touched?.name ? props?.errors?.name : ""}
                            className="login-form-textinput"
                            id="name"
                            name="name"
                            placeholder={"Name"}
                            value={props?.values?.name}
                            onChange={props?.handleChange("name")}
                            disabled={loaderButton}
                          />
                        </div>
                        <div className={`col-md-6 col-xs-12 postion-relative mb-2 ${!isMobile && "pr-0 pl-30"}`}>
                          <span className="profile-label" style={{ paddingLeft: isMobile && 15 }} >
                            Email Id
                          </span>
                          <WSTextField
                            error={props?.errors?.email && props?.touched?.email}
                            helperText={props?.errors?.email && props?.touched?.email ? props?.errors?.email : ""}
                            className="login-form-textinput"
                            id="email"
                            name="email"
                            placeholder={"Email Id"}
                            value={props?.values?.email}
                            onChange={props?.handleChange("email")}
                          />
                        </div>
                        <div className={`col-md-6 col-xs-12 postion-relative mb-3 ${!isMobile && "pr-0 pl-30"}`}>
                          <span className="profile-label" style={{ paddingLeft: isMobile && 15 }}>
                            Gender
                          </span>
                          <Selects
                            name="gender"
                            id="gender"
                            label="Gender"
                            className="form-control form-control-select w-100 mt-25"
                            onChange={props?.handleChange("sex")}
                            value={props?.values?.sex}
                          >
                            <Option value="">Select Gender</Option>
                            <Option value="Male">Male</Option>
                            <Option value="Female">Female</Option>
                            <Option value="Undisclosed">Undisclosed</Option>
                          </Selects>
                          {props?.errors?.sex && props?.touched?.sex && (
                            <div className="error-text">{props?.errors?.sex}</div>
                          )}
                        </div>
                        <div className={`col-md-6 col-xs-12 postion-relative mb-3 ${!isMobile && "pr-0 pl-30"}`}>
                          <span className="profile-label" style={{ paddingLeft: isMobile && 15 }}>
                            Nationality
                          </span>
                          <Selects
                            name="nationality"
                            id="nationality"
                            className="form-control form-control-select w-100 mt-25"
                            onChange={props?.handleChange("nationality")}
                            value={props?.values?.nationality}
                          >
                            <Option value="">Select Nationality</Option>
                            {nationalityData && nationalityData.map((nationality) => {
                              return (
                                <Option value={nationality?.name} key={nationality?.id} >
                                  {nationality?.name}
                                </Option>
                              );
                            })}
                          </Selects>
                          {props?.errors?.nationality && props?.touched?.nationality && (
                            <div className="error-text">
                              {props?.errors?.nationality}
                            </div>
                          )}
                        </div>

                        <div className={`col-md-6 col-xs-12 postion-relative mb-3 mt-4 ${!isMobile && "pr-0 pl-30"}`}>
                          <span className="profile-label" style={{ paddingLeft: isMobile && 15 }}>
                            Phone Number
                            <span className="primary-color">*</span>
                          </span>
                          <PhoneInput
                            country={"ae"}
                            enableSearch
                            autoFormat
                            countryCodeEditable={false}
                            disableSearchIcon
                            placeholder="Phone Number"
                            value={props?.values?.phone}
                            containerStyle={{ marginTop: 25 }}
                            inputStyle={{
                              backgroundColor: "#f6f9ff",
                              width: "100%",
                              height: 50,
                              borderRadius: 0,
                              borderColor: phoneNoActive && "#e24f36",
                            }}
                            inputClass="border-shadow-none"
                            buttonStyle={{
                              backgroundColor: "#f6f9ff",
                              borderRadius: 0,
                              borderColor: phoneNoActive && "#e24f36",
                            }}
                            onChange={(phone) =>
                              props.setFieldValue("phone", phone)
                            }
                            onFocus={() =>
                              this.setState({ phoneNoActive: true })
                            }
                            onBlur={() =>
                              this.setState({ phoneNoActive: false })
                            }
                          />
                          {props?.errors?.phoneNo && props?.touched?.phoneNo && (
                            <div className="error-text">
                              {props?.errors?.phoneNo}
                            </div>
                          )}
                        </div>
                        <div className={`col-md-6 col-xs-12 postion-relative mb-3 mt-4 ${!isMobile && "pr-0 pl-30"}`}>
                          <span className="profile-label" style={{ paddingLeft: isMobile && 15 }}>
                            Contact of Residence
                            <span className="primary-color">*</span>
                          </span>
                          <PhoneInput
                            country={"ae"}
                            enableSearch
                            autoFormat
                            countryCodeEditable={false}
                            disableSearchIcon
                            placeholder="Contact of Residence"
                            value={String(props?.values?.contactNo_Resi)}
                            containerStyle={{ marginTop: 25 }}
                            inputStyle={{
                              backgroundColor: "#f6f9ff",
                              width: "100%",
                              height: 50,
                              borderRadius: 0,
                              borderColor: resNoActive && "#e24f36",
                            }}
                            inputClass="border-shadow-none"
                            buttonStyle={{
                              backgroundColor: "#f6f9ff",
                              borderRadius: 0,
                              borderColor: resNoActive && "#e24f36",
                            }}
                            onChange={(contactNo_Resi) => props.setFieldValue("contactNo_Resi", contactNo_Resi)}
                            onFocus={() => this.setState({ resNoActive: true })}
                            onBlur={() => this.setState({ resNoActive: false })}
                          />
                          {props?.errors?.contactNo_Resi &&
                            props?.touched?.contactNo_Resi && (
                              <div className="error-text">
                                {props?.errors?.contactNo_Resi}
                              </div>
                            )}
                        </div>
                        <div className={`col-md-12 col-xs-12 postion-relative mb-2 mt-2 ${!isMobile && "pr-0 pl-30"}`}>
                          <span className="profile-label" style={{ paddingLeft: isMobile && 15 }}>
                            Short Brief <span className="primary-color">*</span>
                          </span>
                          <WSTextField
                            error={props?.errors?.shortBrief && props?.touched?.shortBrief}
                            helperText={props?.errors?.shortBrief && props?.touched?.shortBrief
                              ? props?.errors?.shortBrief
                              : ""
                            }
                            className="login-form-textinput"
                            id="shortBrief"
                            name="shortBrief"
                            rows={3}
                            rowsMax={5}
                            multiline
                            inputProps={{ maxLength: 500 }}
                            placeholder={"Brief"}
                            value={props?.values?.shortBrief}
                            onChange={props?.handleChange("shortBrief")}
                            disabled={loaderButton}
                          />
                        </div>
                        <span className="w-100"></span>
                        <span className={isMobile ? "w-100 profile-header-label pl-15 mt-20" : "w-100 profile-header-label"} >
                          Social Links & Website
                        </span>
                        <div className={`col-md-4 col-xs-12 postion-relative mb-2 ${!isMobile && "pr-0 pl-30"}`}>
                          <span className="profile-label" style={{ paddingLeft: isMobile && 15 }}>
                            IG Profile <span className="primary-color">*</span>
                          </span>
                          <WSTextField
                            error={props?.errors?.iGProfile && props?.touched?.iGProfile}
                            helperText={props?.errors?.iGProfile && props?.touched?.iGProfile
                              ? props?.errors?.iGProfile
                              : ""
                            }
                            className="login-form-textinput"
                            id="igProfile"
                            name="igProfile"
                            placeholder={"IG Profile"}
                            value={props?.values?.iGProfile}
                            onChange={props?.handleChange("iGProfile")}
                            disabled={loaderButton}
                          />
                        </div>
                        <div className={`col-md-4 col-xs-12 postion-relative mb-2 ${!isMobile && "pr-0 pl-30"}`}>
                          <span className="profile-label" style={{ paddingLeft: isMobile && 15 }}>
                            FB Profile <span className="primary-color">*</span>
                          </span>
                          <WSTextField
                            error={props?.errors?.fBProfile && props?.touched?.fBProfile}
                            helperText={props?.errors?.fBProfile && props?.touched?.fBProfile
                              ? props?.errors?.fBProfile
                              : ""
                            }
                            className="login-form-textinput"
                            id="fbProfile"
                            name="fbProfile"
                            placeholder={"FB Profile"}
                            value={props?.values?.fBProfile}
                            onChange={props?.handleChange("fBProfile")}
                            disabled={loaderButton}
                          />
                        </div>
                        <div className={`col-md-4 col-xs-12 postion-relative ${!isMobile && "pr-0 pl-30"}`}>
                          <span className="profile-label" style={{ paddingLeft: isMobile && 15 }}>
                            Website <span className="primary-color">*</span>
                          </span>
                          <WSTextField
                            error={props?.errors?.website && props?.touched?.website}
                            helperText={props?.errors?.website && props?.touched?.website
                              ? props?.errors?.website
                              : ""
                            }
                            className="login-form-textinput"
                            id="website"
                            name="website"
                            placeholder={"Website"}
                            value={props?.values?.website}
                            onChange={props?.handleChange("website")}
                            disabled={loaderButton}
                          />
                        </div>
                        <span
                          className={isMobile
                            ? "w-100 profile-header-label pl-15 mt-20"
                            : "w-100 profile-header-label"
                          }
                        >
                          Languages
                        </span>
                        <div className={`col-md-4 col-xs-12 postion-relative mb-3 ${!isMobile && "pr-0 pl-30"}`}>
                          <span className="profile-label" style={{ paddingLeft: isMobile && 15 }}>
                            Primary Language
                          </span>
                          <Selects
                            name="language1"
                            id="language1"
                            className="form-control form-control-select w-100 mt-25"
                            onChange={props?.handleChange("languageSpoken1")}
                            value={props?.values?.languageSpoken1}
                          >
                            <Option value="">Select Primary Language</Option>
                            {languagesData && languagesData.map((language) => {
                              return (
                                <Option value={language?.id}>
                                  {language?.name}
                                </Option>
                              );
                            })}
                          </Selects>
                          {props?.errors?.languageSpoken1 &&
                            props?.touched?.languageSpoken1 && (
                              <div className="error-text">
                                {props?.errors?.languageSpoken1}
                              </div>
                            )}
                        </div>
                        <div className={`col-md-4 col-xs-12 postion-relative mb-3 ${!isMobile && "pr-0 pl-30"}`}>
                          <span className="profile-label" style={{ paddingLeft: isMobile && 15 }}>
                            Secondary Language
                          </span>
                          <Selects
                            name="language2"
                            id="language2"
                            className="form-control form-control-select w-100 mt-25"
                            onChange={props?.handleChange("languageSpoken2")}
                            value={props?.values?.languageSpoken2}
                          >
                            <Option value="">Select Secondary Language</Option>
                            {languagesData && languagesData.map((language) => {
                              return (
                                <Option value={language?.id}>
                                  {language?.name}
                                </Option>
                              );
                            })}
                          </Selects>
                          {props?.errors?.languageSpoken2 &&
                            props?.touched?.languageSpoken2 && (
                              <div className="error-text">
                                {props?.errors?.languageSpoken2}
                              </div>
                            )}
                        </div>
                        <div className={`col-md-4 col-xs-12 postion-relative mb-3 ${!isMobile && "pr-0 pl-30"}`}>
                          <span className="profile-label" style={{ paddingLeft: isMobile && 15 }}>
                            Additional Language
                          </span>
                          <Selects
                            name="language3"
                            id="language3"
                            className="form-control form-control-select w-100 mt-25"
                            onChange={props?.handleChange("languageSpoken3")}
                            value={props?.values?.languageSpoken3}
                          >
                            <Option value="">Select Additional Language</Option>
                            {languagesData && languagesData.map((language) => {
                              return (
                                <Option value={language?.id}>
                                  {language?.name}
                                </Option>
                              );
                            })}
                          </Selects>
                          {props?.errors?.languageSpoken3 &&
                            props?.touched?.languageSpoken3 && (
                              <div className="error-text">
                                {props?.errors?.languageSpoken3}
                              </div>
                            )}
                        </div>
                      </div>

                      <div className="row col-md-12 mt-5">
                        <div className={`col-md-4 col-xs-12 postion-relative mb-2 ${!isMobile && "pr-0 pl-30"}`}>
                          <span className="profile-label" style={{ paddingLeft: isMobile && 15 }}>
                            Business Name
                          </span>
                          <WSTextField
                            error={props?.errors?.businessName && props?.touched?.businessName}
                            helperText={props?.errors?.businessName &&
                              props?.touched?.businessName
                              ? props?.errors?.businessName
                              : ""
                            }
                            className="login-form-textinput"
                            id="businessName"
                            name="businessName"
                            placeholder={"Business Name "}
                            value={props?.values?.businessName}
                            onChange={props?.handleChange("businessName")}
                            disabled={loaderButton}
                          />
                        </div>
                        <div className={`col-md-4 col-xs-12 postion-relative mb-2 ${!isMobile && "pr-0 pl-30"}`}>
                          <span className="profile-label" style={{ paddingLeft: isMobile && 15 }}>
                            License No
                          </span>
                          <WSTextField
                            error={props?.errors?.licenseNo && props?.touched?.licenseNo}
                            helperText={props?.errors?.licenseNo && props?.touched?.licenseNo
                              ? props?.errors?.licenseNo
                              : ""
                            }
                            className="login-form-textinput"
                            id="licenseNo"
                            name="licenseNo"
                            placeholder={"License No"}
                            value={props?.values?.licenseNo}
                            onChange={props?.handleChange("licenseNo")}
                            disabled={loaderButton}
                          />
                        </div>
                        <div className={`col-md-4 col-xs-12 postion-relative mb-2 ${!isMobile && "pr-0 pl-30"}`}>
                          <span className="profile-label" style={{ paddingLeft: isMobile && 15 }}>
                            License Type
                          </span>
                          <Selects
                            name="licenseType"
                            id="licenseType"
                            className="form-control form-control-select w-100 mt-25"
                            onChange={props?.handleChange("licenseType")}
                            value={props?.values?.licenseType}
                          >
                            <Option value="">Select License Type</Option>
                            <Option value="Trade">Trade</Option>
                            <Option value="Commercial">Commercial</Option>
                            <Option value="Free Zone">Free Zone</Option>
                            <Option value="Freelancer">Freelancer</Option>
                          </Selects>
                          {props?.errors?.licenseType &&
                            props?.touched?.licenseType && (
                              <div className="error-text">
                                {props?.errors?.licenseType}
                              </div>
                            )}
                        </div>
                        <span className="w-100"></span>
                        <div className={`col-md-4 col-xs-12 postion-relative mb-2 ${!isMobile && "pr-0 pl-30"}`}>
                          <span className="profile-label" style={{ paddingLeft: isMobile && 15 }}>
                            License Valid Till
                          </span>
                          <WSTextField
                            error={props?.errors?.licenseValidtill && props?.touched?.licenseValidtill}
                            helperText={props?.errors?.licenseValidtill &&
                              props?.touched?.licenseValidtill
                              ? props?.errors?.licenseValidtill
                              : ""
                            }
                            className="login-form-textinput"
                            id="licenseValid"
                            name="licenseValid"
                            type="date"
                            placeholder={"License Valid Till"}
                            value={props?.values?.licenseValidtill}
                            onChange={props?.handleChange("licenseValid")}
                            disabled={loaderButton}
                          />
                        </div>
                        <div className={`col-md-4 col-xs-12 postion-relative mb-3 ${!isMobile && "pr-0 pl-30"}`}>
                          <span className="profile-label" style={{ paddingLeft: isMobile && 15 }}>
                            Contact No Office
                          </span>
                          <PhoneInput
                            country={"ae"}
                            enableSearch
                            autoFormat
                            countryCodeEditable={false}
                            disableSearchIcon
                            placeholder="Contact No Office"
                            value={String(props?.values?.contactNo_Office)}
                            containerStyle={{ marginTop: 25 }}
                            inputStyle={{
                              backgroundColor: "#f6f9ff",
                              width: "100%",
                              height: 50,
                              borderRadius: 0,
                              borderColor: contactNoActive && "#e24f36",
                            }}
                            inputClass="border-shadow-none"
                            buttonStyle={{
                              backgroundColor: "#f6f9ff",
                              borderRadius: 0,
                              borderColor: contactNoActive && "#e24f36",
                            }}
                            onChange={(contactNo_Office) =>
                              props.setFieldValue("contactNo_Office", contactNo_Office)
                            }
                            onFocus={() =>
                              this.setState({ contactNoActive: true })
                            }
                            onBlur={() =>
                              this.setState({ contactNoActive: false })
                            }
                          />
                          {props?.errors?.contactNo_Office &&
                            props?.touched?.contactNo_Office && (
                              <div className="error-text">
                                {props?.errors?.contactNo_Office}
                              </div>
                            )}
                        </div>
                        <div className={`col-md-4 col-xs-12 postion-relative mb-3 ${!isMobile && "pr-0 pl-30"}`}>
                          <span className="profile-label" style={{ paddingLeft: isMobile && 15 }}>
                            Base Location
                          </span>
                          <Selects
                            name="baseLocation"
                            id="baseLocation"
                            className="form-control form-control-select w-100 mt-25"
                            onChange={props?.handleChange("baseLocation")}
                            value={props?.values?.baseLocation}
                          >
                            <Option value="">Select Base Location</Option>
                            <Option value="Dubai">Dubai</Option>
                            <Option value="Abu Dhabi">Abu Dhabi</Option>
                            <Option value="Sharjah">Sharjah</Option>
                            <Option value="Other Emirates">
                              Other Emirates
                            </Option>
                          </Selects>
                          {props?.errors?.baseLocation &&
                            props?.touched?.baseLocation && (
                              <div className="error-text">
                                {props?.errors?.baseLocation}
                              </div>
                            )}
                        </div>
                        <span className="w-100"></span>
                        <div className={`col-md-6 col-xs-12 postion-relative mb-2 ${!isMobile && "pr-0 pl-30"}`}>
                          <DatePicker
                            className="mt-3  providerInputs"
                            fullWidth
                            name="establishedOn"
                            placeholder="Add Established at"
                            label="Established On"
                            value={establishedOn}
                            format={"DD-MMM-Y"}
                            onChange={this.handleDateChange}
                            animateYearScrolling={false}
                            leftArrowIcon={<i className="zmdi zmdi-arrow-back" />}
                            rightArrowIcon={<i className="zmdi zmdi-arrow-forward" />}
                            disableFuture={true}
                          />
                        </div>
                        <div className={`col-md-6 col-xs-12 postion-relative mb-2 ${!isMobile && "pr-0 pl-30"}`}>
                          <TextField
                            error={adminCutError}
                            helperText={adminCutError}
                            type="text"
                            className=" providerInputs"
                            name="adminCut"
                            id="adminCut"
                            label="Admin Cut"
                            fullWidth
                            placeholder="Add Admin Cut"
                            InputLabelProps={{ shrink: true, }}
                            multiline={true}
                            onChange={(event) => {
                              this.setState({ adminCut: event?.target?.value, adminCutError: "", error: "", });
                            }}
                            margin="normal"
                            defaultValue={providerDetail?.adminCut}
                            disabled={loaderButton}
                          />
                        </div>
                      </div>

                      <div className="col-md-12 col-xs-12 providerFrom">
                        <TextField
                          error={descriptionError}
                          helperText={descriptionError}
                          type="text"
                          label="Admin Comment"
                          className="col-md-12 adminComment"
                          name="description"
                          id="description"
                          fullWidth
                          placeholder="Add Admin Comment"
                          InputLabelProps={{ shrink: true, }}
                          multiline={true}
                          onChange={(event) => {
                            this.setState({ description: event?.target?.value, descriptionError: "", error: "", });
                          }}
                          margin="normal"
                          defaultValue={providerDetail?.description}
                          disabled={loaderButton}
                        />
                      </div>
                      <div className="col-md-12 col-xs-12 providerFrom"></div>
                      <div className="col-md-12 col-xs-12 providerFrom">
                        <FormControl className="w-100 mt-3 providerInputs" error={experienceRatingError} >
                          <InputLabel htmlFor={"status"}>
                            Experience Rating
                          </InputLabel>
                          <Select
                            defaultValue={providerDetail?.experienceRating}
                            value={this.state?.experienceRating}
                            className="providerInputs"
                            onChange={(event) => {
                              this.setState({ experienceRating: event?.target?.value, experienceRatingError: "", error: "", });
                            }}
                            input={<Input id={"experienceRating"} />}
                          >
                            <MenuItem value="Pro">Pro</MenuItem>
                            <MenuItem value="Semi-Pro">Semi-Pro</MenuItem>
                            <MenuItem value="Amateur">Amateur</MenuItem>
                          </Select>
                          <FormHelperText>
                            {experienceRatingError}
                          </FormHelperText>
                        </FormControl>
                        <FormControl className="w-100 mt-3 providerInputs" error={priceRatingError} >
                          <InputLabel htmlFor={"status"}>
                            Price Rating
                          </InputLabel>
                          <Select
                            defaultValue={providerDetail?.priceRating}
                            value={this.state?.priceRating}
                            className="providerInputs"
                            onChange={(event) => {
                              this.setState({ priceRating: event?.target?.value, priceRatingError: "", error: "", });
                            }}
                            input={<Input id={"priceRating"} />}
                          >
                            <MenuItem value="Basic">Basic</MenuItem>
                            <MenuItem value="Average">Average</MenuItem>
                            <MenuItem value="Premium">Premium</MenuItem>
                          </Select>
                          <FormHelperText>{priceRatingError}</FormHelperText>
                        </FormControl>
                      </div>
                      <div className="row col-md-12 mt-4">
                        <div className="col-md-3">
                          <MySwitch
                            onChange={() =>
                              this.setState((prevState) => ({ is_blocked: !prevState?.is_blocked, }))
                            }
                            label="Blocked User"
                            name="is_blocked"
                            checked={is_blocked}
                          />
                        </div>

                        <div className="col-md-3">
                          <MySwitch
                            onChange={() =>
                              this.setState((prevState) => ({ isFeatured: !prevState?.isFeatured, }))
                            }
                            label="Make Featured"
                            name="isFeatured"
                            checked={isFeatured}
                          />
                        </div>
                        <div className="col-md-3">
                          <MySwitch
                            onChange={() =>
                              this.setState((prevState) => ({ isRecommended: !prevState?.isRecommended, }))
                            }
                            label="Make Recommended"
                            name="isRecommended"
                            checked={isRecommended}
                          />
                        </div>
                        <div className="col-md-3">
                          <MySwitch
                            disabled={isApprovedByAdmin}
                            onChange={() =>
                              this.setState((prevState) => ({ isApprovedByAdmin: !prevState?.isApprovedByAdmin, }))
                            }
                            label="Make Approved"
                            name="isApprovedByAdmin"
                            checked={isApprovedByAdmin}
                          />
                        </div>
                      </div>
                      <div className="mt-4">
                        <span className="mb-0 mt-5 ml-3 mr-2 mb-10 font-w600 font-14">
                          Select Profession
                          <span className="font-w400">(upto 3)</span>
                        </span>
                        <div className="row col-md-12 p-0 ml-4">
                          <div className="col-md-3 col-xs-12">
                            <Selects
                              name="profession"
                              id="profession"
                              className="form-control form-control-select w-100 mt-2"
                              onChange={(e) => {
                                if (!prepapredData.find((val) => val?.category === e?.target?.value.split("-")[1])) {
                                  this.setState({ profession: e?.target?.value.split("-")[1], });
                                  props.setFieldValue("profession", e?.target?.value.split("-")[0]);
                                  allSelectedCategory?.length < 3 && allSelectedCategory.push({
                                    catName: e?.target?.value.split("-")[0],
                                    catId: e?.target?.value.split("-")[1],
                                  });
                                  prepapredData?.length < 3 && prepapredData.push({
                                    category: e?.target?.value.split("-")[1],
                                    subcategories: [],
                                    type: e?.target?.value?.split("-")[0],
                                  });
                                  this.setState({
                                    selectedCategory: e?.target?.value.split("-")[0],
                                    selectedCategoryId: e?.target?.value.split("-")[1],
                                    isShowSubCategory: true,
                                  });
                                  this.getSubcategories(e?.target?.value.split("-")[0]);
                                }
                              }}
                              value={""}
                            >
                              {allSelectedCategory?.length === 0 && (
                                <Option value="">Select Profession</Option>
                              )}
                              {categoryData && categoryData.map((category) => {
                                return (
                                  <Option value={`${category?.heading}-${category?.key}`} key={category?.key} >
                                    {category?.heading}
                                  </Option>
                                );
                              })}
                            </Selects>
                            {props?.errors?.profession &&
                              props?.touched?.profession && (
                                <div className="error-text">
                                  {props?.errors?.profession}
                                </div>
                              )}
                          </div>
                          <span className="w-100"></span>
                          {allSelectedCategory && allSelectedCategory.map((selectCategory, index) => {
                            return (
                              <div className="col-md-3 pb-2 mt-20" key={selectCategory?.catName} >
                                <div className={`genere-btn ${selectedCategory === selectCategory?.catName ? "active" : ""}`}
                                  onClick={() => {
                                    this.setState({
                                      selectedCategory: selectCategory?.catName,
                                      selectedCategoryId: selectCategory?.catId,
                                      isShowSubCategory: true,
                                      profession: selectCategory?.catId,
                                    });
                                    this.getSubcategories(selectCategory?.catName);
                                  }}
                                >
                                  {selectCategory?.catName}
                                  <div
                                    class="cross"
                                    onClick={() => {
                                      allSelectedCategory.splice(index, 1);
                                      prepapredData.splice(index, 1);
                                      this.setState({ removedProvider: [...removedProvider, selectCategory?.catId,], }, () => { });
                                      setTimeout(() => {
                                        this.setState({
                                          loaderButton: false,
                                          isShowSubCategory: allSelectedCategory?.length === 0
                                            ? false
                                            : true,
                                          selectedCategory: allSelectedCategory?.length !== 0
                                            ? allSelectedCategory[index - 1]
                                              ?.catName
                                            : "",
                                          selectedCategoryId: allSelectedCategory?.length !== 0
                                            ? allSelectedCategory[index - 1]
                                              ?.catId
                                            : "",
                                          profession: allSelectedCategory?.length !== 0
                                            ? allSelectedCategory[index - 1]
                                              ?.catId
                                            : "",
                                        });
                                      }, 200);
                                    }}
                                  >
                                    X
                                    </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        {allSelectedCategory?.length ? (
                          <Divider className="mt-20" />
                        ) : (
                          <span></span>
                        )}
                        <div className="row col-md-12 p-0">
                          {prepapredData && isShowSubCategory && prepapredData.filter((item) => item?.category === selectedCategoryId)
                            .map((category) => {
                              return category?.subcategories?.length ? (
                                <span className="mb-0 mt-4 ml-3 font-w600 font-14 mb-30">
                                  Select Sub Categories{" "}
                                  <span className="font-w400">
                                    (upto 5 sub categories)
                                    </span>
                                </span>
                              ) : (
                                <span></span>
                              );
                            })}
                          <span className="w-100"></span>
                          <FormGroup aria-label="position" row className="selectSubCategories" >
                            {prepapredData && isShowSubCategory && prepapredData.filter((item) => item?.category === selectedCategoryId)
                              .map((category) => {
                                return (
                                  category?.subcategories.map(
                                    (subCategory) => {
                                      return (
                                        <div className={`col-md-3 col-xs-12 mb-2 ml-2 mt-4 flex-align-center ${!isMobile && "pr-0 pl-30"}`} key={subCategory?.key}   >
                                          <FormControlLabel
                                            checked={subCategory?.checked}
                                            value={subCategory?.key}
                                            onChange={(e) => {
                                              this.setState({ loaderButton: false, });
                                              if (e?.target?.checked) {
                                                subCategory.checked = true;
                                              } else {
                                                subCategory.checked = false;
                                              }
                                            }}
                                            control={<Checkbox color="primary" />}
                                            label={subCategory?.heading}
                                            labelPlacement="right"
                                          />
                                        </div>
                                      );
                                    }
                                  )
                                );
                              })}
                          </FormGroup>
                          <span className="w-100"></span>
                          {selectedCategory === "Female Models" &&
                            isShowSubCategory && (
                              <Fragment>
                                <span
                                  className="w-100 profile-header-label ml-2"
                                  style={{
                                    color: "black",
                                    marginTop: isMobile ? 20 : 30,
                                    paddingLeft: isMobile && 15,
                                  }}
                                >
                                  Vital Stats (Personal Details)
                                </span>
                                <div className={`col-md-2 col-xs-12 postion-relative mb-2 ml-4 ${!isMobile && "pr-0 pl-30"}`}  >
                                  <span className="profile-label" style={{ paddingLeft: isMobile && 15 }}  >
                                    Height
                                  </span>
                                  <WSTextField
                                    error={props?.touched?.height}
                                    helperText={props?.errors?.height &&
                                      props?.touched?.height
                                      ? props?.errors?.height
                                      : ""
                                    }
                                    className="login-form-textinput"
                                    id="height"
                                    name={`height${profession}`}
                                    placeholder={"Height"}
                                    value={props?.values[`height${profession}`]}
                                    onChange={props?.handleChange(`height${profession}`)}
                                    disabled={loaderButton}
                                  />
                                </div>
                                <div className={`col-md-2 col-xs-12 postion-relative mb-2 ml-4 ${!isMobile && "pr-0 pl-30"}`} >
                                  <span className="profile-label" style={{ paddingLeft: isMobile && 15 }}  >
                                    Show Size in Us
                                  </span>
                                  <WSTextField
                                    error={props?.errors?.shoeSize && props?.touched?.shoeSize}
                                    helperText={props?.errors?.shoeSize &&
                                      props?.touched?.shoeSize
                                      ? props?.errors?.shoeSize
                                      : ""
                                    }
                                    className="login-form-textinput"
                                    id="shoeSize"
                                    name={`shoeSize${profession}`}
                                    placeholder={"Show Size in Us"}
                                    value={props?.values[`shoeSize${profession}`]}
                                    onChange={props?.handleChange(`shoeSize${profession}`)}
                                    disabled={loaderButton}
                                  />
                                </div>
                                <span className="w-100"></span>
                                <div className={`col-md-2 col-xs-12 postion-relative mb-2 ml-4 ${!isMobile && "pr-0 pl-30"}`}  >
                                  <span className="profile-label" style={{ paddingLeft: isMobile && 15 }}  >
                                    Bust in Cms
                                  </span>
                                  <WSTextField
                                    error={props?.errors?.bustCms && props?.touched?.bustCms}
                                    helperText={props?.errors?.bustCms &&
                                      props?.touched?.bustCms
                                      ? props?.errors?.bustCms
                                      : ""
                                    }
                                    className="login-form-textinput"
                                    id="bustCms"
                                    name={`bustCms${profession}`}
                                    placeholder={"Bust in Cms"}
                                    value={props?.values[`bustCms${profession}`]}
                                    onChange={props?.handleChange(`bustCms${profession}`)}
                                    disabled={loaderButton}
                                  />
                                </div>
                                <div className={`col-md-2 col-xs-12 postion-relative mb-2 ml-4 ${!isMobile && "pr-0 pl-30"}`} >
                                  <span className="profile-label" style={{ paddingLeft: isMobile && 15 }}  >
                                    Bust in Inches
                                  </span>
                                  <WSTextField
                                    error={props?.errors?.bustInches && props?.touched?.bustInches}
                                    helperText={props?.errors?.bustInches &&
                                      props?.touched?.bustInches
                                      ? props?.errors?.bustInches
                                      : ""
                                    }
                                    className="login-form-textinput"
                                    id="bustInches"
                                    name={`bustInches${profession}`}
                                    placeholder={"Bust in Inches"}
                                    value={props?.values[`bustInches${profession}`]}
                                    onChange={props?.handleChange(`bustInches${profession}`)}
                                    disabled={loaderButton}
                                  />
                                </div>
                                <div className={`col-md-2 col-xs-12 postion-relative mb-2 ml-4 ${!isMobile && "pr-0 pl-30"}`}  >
                                  <span className="profile-label" style={{ paddingLeft: isMobile && 15 }} >
                                    Waist in Cms
                                  </span>
                                  <WSTextField
                                    error={props?.errors?.waistCms && props?.touched?.waistCms}
                                    helperText={props?.errors?.waistCms &&
                                      props?.touched?.waistCms
                                      ? props?.errors?.waistCms
                                      : ""
                                    }
                                    className="login-form-textinput"
                                    id="waistCms"
                                    name={`waistCms${profession}`}
                                    placeholder={"Waist in Cms"}
                                    value={props?.values[`waistCms${profession}`]}
                                    onChange={props?.handleChange(`waistCms${profession}`)}
                                    disabled={loaderButton}
                                  />
                                </div>
                                <div className={`col-md-2 col-xs-12 postion-relative mb-2 ml-4 ${!isMobile && "pr-0 pl-30"}`} >
                                  <span className="profile-label" style={{ paddingLeft: isMobile && 15 }}  >
                                    Waist Inches
                                  </span>
                                  <WSTextField
                                    error={props?.errors?.waistInches && props?.touched?.waistInches}
                                    helperText={props?.errors?.waistInches &&
                                      props?.touched?.waistInches
                                      ? props?.errors?.waistInches
                                      : ""
                                    }
                                    className="login-form-textinput"
                                    id="waistInches"
                                    name={`waistInches${profession}`}
                                    placeholder={"Waist Inches"}
                                    value={props?.values[`waistInches${profession}`]}
                                    onChange={props?.handleChange(`waistInches${profession}`)}
                                    disabled={loaderButton}
                                  />
                                </div>
                                <div className={`col-md-2 col-xs-12 postion-relative mb-2 ml-4 ${!isMobile && "pr-0 pl-30"}`} >
                                  <span className="profile-label" style={{ paddingLeft: isMobile && 15 }}  >
                                    Hips in Cms
                                  </span>
                                  <WSTextField
                                    error={props?.errors?.hipsCms && props?.touched?.hipsCms}
                                    helperText={props?.errors?.hipsCms &&
                                      props?.touched?.hipsCms
                                      ? props?.errors?.hipsCms
                                      : ""
                                    }
                                    className="login-form-textinput"
                                    id="hipsCms"
                                    name={`hipsCms${profession}`}
                                    placeholder={"Hips in Cms"}
                                    value={props?.values[`hipsCms${profession}`]}
                                    onChange={props?.handleChange(`hipsCms${profession}`)}
                                    disabled={loaderButton}
                                  />
                                </div>
                                <div className={`col-md-2 col-xs-12 postion-relative mb-2 ml-4 ${!isMobile && "pr-0 pl-30"}`}  >
                                  <span className="profile-label" style={{ paddingLeft: isMobile && 15 }}  >
                                    Hips Inches
                                  </span>
                                  <WSTextField
                                    error={props?.errors?.hipsInches && props?.touched?.hipsInches}
                                    helperText={props?.errors?.hipsInches &&
                                      props?.touched?.hipsInches
                                      ? props?.errors?.hipsInches
                                      : ""
                                    }
                                    className="login-form-textinput"
                                    id="hipsInches"
                                    name={`hipsInches${profession}`}
                                    placeholder={"Hips Inches"}
                                    value={props?.values[`hipsInches${profession}`]}
                                    onChange={props?.handleChange(`hipsInches${profession}`)}
                                    disabled={loaderButton}
                                  />
                                </div>
                                <span className="w-100"></span>
                                <div className={`col-md-2 col-xs-12 postion-relative mb-2 ml-4 ${!isMobile && "pr-0 pl-30"}`} >
                                  <span className="profile-label" style={{ paddingLeft: isMobile && 15 }} >
                                    Skin Color
                                  </span>
                                  <Selects
                                    name={`skinColor${profession}`}
                                    id="skinColor"
                                    isMobile
                                    className="form-control form-control-select w-100 mt-4"
                                    onChange={props?.handleChange(`skinColor${profession}`)}
                                    value={props?.values[`skinColor${profession}`]}
                                  >
                                    <Option value="">Select</Option>
                                    {getSkinColor && getSkinColor.map((skin) => {
                                      return (
                                        <Option value={skin} key={skin}>
                                          {skin}
                                        </Option>
                                      );
                                    })}
                                  </Selects>
                                  {props?.errors?.skinColor &&
                                    props?.touched?.skinColor && (
                                      <div className="error-text">
                                        {props?.errors?.skinColor}
                                      </div>
                                    )}
                                </div>
                                <div className={`col-md-2 col-xs-12 postion-relative mb-2 ml-4 ${!isMobile && "pr-0 pl-30"}`} >
                                  <span className="profile-label" style={{ paddingLeft: isMobile && 15 }} >
                                    Current Hair Type
                                  </span>
                                  <Selects
                                    name={`hairType${profession}`}
                                    id="hairType"
                                    className="form-control form-control-select w-100 mt-4"
                                    onChange={props?.handleChange(`hairType${profession}`)}
                                    value={props?.values[`hairType${profession}`]}
                                  >
                                    <Option value="">Select</Option>
                                    {getHairType && getHairType.map((type) => {
                                      return (
                                        <Option value={type} key={type}>
                                          {type}
                                        </Option>
                                      );
                                    })}
                                  </Selects>
                                  {props?.errors?.hairType &&
                                    props?.touched?.hairType && (
                                      <div className="error-text">
                                        {props?.errors?.hairType}
                                      </div>
                                    )}
                                </div>
                                <div className={`col-md-2 col-xs-12 postion-relative mb-2 ml-4 ${!isMobile && "pr-0 pl-30"}`} >
                                  <span className="profile-label" style={{ paddingLeft: isMobile && 15 }} >
                                    Current Hair Color
                                  </span>
                                  <Selects
                                    name={`hairColor${profession}`}
                                    id="hairColor"
                                    className="form-control form-control-select w-100 mt-4"
                                    onChange={props?.handleChange(`hairColor${profession}`)}
                                    value={props?.values[`hairColor${profession}`]}
                                  >
                                    <Option value="">Select</Option>
                                    {getHairColor && getHairColor.map((color) => {
                                      return (
                                        <Option value={color} key={color}>
                                          {color}
                                        </Option>
                                      );
                                    })}
                                  </Selects>
                                  {props?.errors?.hairColor &&
                                    props?.touched?.hairColor && (
                                      <div className="error-text">
                                        {props?.errors?.hairColor}
                                      </div>
                                    )}
                                </div>
                                <div className={`col-md-2 col-xs-12 postion-relative mb-2 ml-4 ${!isMobile && "pr-0 pl-30"}`} >
                                  <span className="profile-label" style={{ paddingLeft: isMobile && 15 }} >
                                    Eye Color
                                  </span>
                                  <Selects
                                    name={`eyeColor${profession}`}
                                    id="eyeColor"
                                    className="form-control form-control-select w-100 mt-4"
                                    onChange={props?.handleChange(`eyeColor${profession}`)}
                                    value={props?.values[`eyeColor${profession}`]}
                                  >
                                    <Option value="">Select</Option>
                                    {getEyeColor && getEyeColor.map((color) => {
                                      return (
                                        <Option value={color} key={color}>
                                          {color}
                                        </Option>
                                      );
                                    })}
                                  </Selects>
                                  {props?.errors?.eyeColor &&
                                    props?.touched?.eyeColor && (
                                      <div className="error-text">
                                        {props?.errors?.eyeColor}
                                      </div>
                                    )}
                                </div>
                                <div className={`col-md-2 col-xs-12 postion-relative mb-2 ml-4 ${!isMobile && "pr-0 pl-30"}`} >
                                  <span className="profile-label" style={{ paddingLeft: isMobile && 15 }} >
                                    Ethinicity
                                  </span>
                                  <Selects
                                    name={`ethinicity${profession}`}
                                    id="ethinicity"
                                    className="form-control form-control-select w-100 mt-4"
                                    onChange={props?.handleChange(`ethinicity${profession}`)}
                                    value={props?.values[`ethinicity${profession}`]}
                                  >
                                    <Option value="">Select</Option>
                                    {getEthinicity && getEthinicity.map((type) => {
                                      return (
                                        <Option value={type} key={type}>
                                          {type}
                                        </Option>
                                      );
                                    })}
                                  </Selects>
                                  {props?.errors?.ethinicity &&
                                    props?.touched?.ethinicity && (
                                      <div className="error-text">
                                        {props?.errors?.ethinicity}
                                      </div>
                                    )}
                                </div>
                              </Fragment>
                            )}
                          <span className="w-100"></span>
                          {(selectedCategory === "Photographer" ||
                            selectedCategory === "Videographer") &&
                            isShowSubCategory && (
                              <Fragment>
                                <span className="mb-0 mt-4 ml-4 font-w600 font-14">
                                  Select your gadget and its type
                                </span>
                                <div className="row col-md-12 p-0 ml-5">
                                  <div className={`col-md-3 col-xs-12 mb-3 mt-2 ${!isMobile && "pr-0 pl-30"}`} >
                                    <span className="profile-text-label" style={{ paddingLeft: isMobile && 15 }} >
                                      Camera 1 Brand
                                    </span>
                                    <Selects
                                      name={`cameraBrand1${profession}`}
                                      id="cameraBrand1"
                                      className={isMobile
                                        ? "form-control form-control-select w-100 mt-2 ml-15"
                                        : "form-control form-control-select w-100 mt-2"
                                      }
                                      onChange={props?.handleChange(`cameraBrand1${profession}`)}
                                      value={props.values[`cameraBrand1${profession}`] || ""}
                                    >
                                      <Option value="">
                                        Select Camera 1 Brand
                                      </Option>
                                      {getCameraBrandList &&
                                        Object.keys(getCameraBrandList).map(
                                          (brand) => {
                                            return (
                                              <Option value={brand}>
                                                {brand}
                                              </Option>
                                            );
                                          }
                                        )}
                                    </Selects>
                                    {props?.errors?.cameraBrand1 &&
                                      props?.touched?.cameraBrand1 && (
                                        <div className="error-text">
                                          {props?.errors?.cameraBrand1}
                                        </div>
                                      )}
                                  </div>
                                  <div className={`col-md-3 col-xs-12 mb-3 mt-2 ${!isMobile && "pr-0 pl-30"}`} >
                                    <span className="profile-text-label" style={{ paddingLeft: isMobile && 15 }}>
                                      Camera 1 Model
                                    </span>
                                    <Selects
                                      name={`cameraModel1${profession}`}
                                      id="cameraModel1"
                                      className={isMobile
                                        ? "form-control form-control-select w-100 mt-2 ml-15"
                                        : "form-control form-control-select w-100 mt-2"
                                      }
                                      onChange={props?.handleChange(`cameraModel1${profession}`)}
                                      value={props?.values[`cameraModel1${profession}`] || ""}
                                    >
                                      <Option value="">
                                        Select Camera 1 Model
                                      </Option>
                                      {getCameraBrandList && props?.values[`cameraBrand1${profession}`] &&
                                        getCameraBrandList[props?.values[`cameraBrand1${profession}`]].map((model) => {
                                          return (
                                            <Option value={model}>
                                              {model}
                                            </Option>
                                          );
                                        })}
                                    </Selects>
                                    {props?.errors?.cameraModel1 &&
                                      props?.touched?.cameraModel1 && (
                                        <div className="error-text">
                                          {props?.errors?.cameraModel1}
                                        </div>
                                      )}
                                  </div>
                                  <span className="w-100"></span>
                                  <div className={`col-md-3 col-xs-12 mb-3 ${!isMobile && "pr-0 pl-30"}`}>
                                    <span className="profile-text-label" style={{ paddingLeft: isMobile && 15 }}>
                                      Camera 2 Brand
                                    </span>
                                    <Selects
                                      name={`cameraBrand2${profession}`}
                                      id="cameraBrand2"
                                      className={isMobile
                                        ? "form-control form-control-select w-100 mt-2 ml-15"
                                        : "form-control form-control-select w-100 mt-2"
                                      }
                                      onChange={props?.handleChange(`cameraBrand2${profession}`)}
                                      value={props?.values[`cameraBrand2${profession}`] || ""}
                                    >
                                      <Option value="">
                                        Select Camera 2 Brand
                                      </Option>
                                      {getCameraBrandList &&
                                        Object.keys(getCameraBrandList).map(
                                          (brand) => {
                                            return (
                                              <Option value={brand}>
                                                {brand}
                                              </Option>
                                            );
                                          }
                                        )}
                                    </Selects>
                                  </div>
                                  <div className={`col-md-3 col-xs-12 mb-3 ${!isMobile && "pr-0 pl-30"}`} >
                                    <span className="profile-text-label" style={{ paddingLeft: isMobile && 15 }} >
                                      Camera 2 Model
                                    </span>
                                    <Selects
                                      name={`cameraModel2${profession}`}
                                      id="cameraModel2"
                                      className={isMobile
                                        ? "form-control form-control-select w-100 mt-2 ml-15"
                                        : "form-control form-control-select w-100 mt-2"
                                      }
                                      onChange={props?.handleChange(`cameraModel2${profession}`)}
                                      value={props?.values[`cameraModel2${profession}`] || ""}
                                    >
                                      <Option value="">
                                        Select Camera 2 Model
                                      </Option>
                                      {getCameraBrandList && props?.values[`cameraBrand2${profession}`] &&
                                        getCameraBrandList[props?.values[`cameraBrand2${profession}`]].map((model) => {
                                          return (
                                            <Option value={model}>
                                              {model}
                                            </Option>
                                          );
                                        })}
                                    </Selects>
                                  </div>
                                  <span className="w-100"></span>
                                  <div className={`col-md-3 col-xs-12 mb-3 ${!isMobile && "pr-0 pl-30"}`} >
                                    <span className="profile-text-label" style={{ paddingLeft: isMobile && 15 }} >
                                      Camera 3 Brand
                                    </span>
                                    <Selects
                                      name={`cameraBrand3${profession}`}
                                      id="cameraBrand3"
                                      className={isMobile
                                        ? "form-control form-control-select w-100 mt-2 ml-15"
                                        : "form-control form-control-select w-100 mt-2"
                                      }
                                      onChange={props?.handleChange(`cameraBrand3${profession}`)}
                                      value={props?.values[`cameraBrand3${profession}`] || ""}
                                    >
                                      <Option value="">
                                        Select Camera 3 Brand
                                      </Option>
                                      {getCameraBrandList &&
                                        Object.keys(getCameraBrandList).map(
                                          (brand) => {
                                            return (
                                              <Option value={brand}>
                                                {brand}
                                              </Option>
                                            );
                                          }
                                        )}
                                    </Selects>
                                  </div>
                                  <div className={`col-md-3 col-xs-12 mb-3 ${!isMobile && "pr-0 pl-30"}`}>
                                    <span className="profile-text-label" style={{ paddingLeft: isMobile && 15 }}>
                                      Camera 3 Model
                                    </span>
                                    <Selects
                                      name={`cameraModel3${profession}`}
                                      id="cameraModel3"
                                      className={isMobile
                                        ? "form-control form-control-select w-100 mt-2 ml-15"
                                        : "form-control form-control-select w-100 mt-2"
                                      }
                                      onChange={props?.handleChange(`cameraModel3${profession}`)}
                                      value={props?.values[`cameraModel3${profession}`] || ""}
                                    >
                                      <Option value="">
                                        Select Camera 3 Model
                                      </Option>
                                      {getCameraBrandList && props?.values[`cameraBrand3${profession}`] &&
                                        getCameraBrandList[props?.values[`cameraBrand3${profession}`]].map((model) => {
                                          return (
                                            <Option value={model}>
                                              {model}
                                            </Option>
                                          );
                                        })}
                                    </Selects>
                                  </div>
                                  <span className="w-100"></span>
                                  <div className={`col-md-3 col-xs-12 ${!isMobile && "pr-0 pl-30"}`}>
                                    <span className="profile-label" style={{ paddingLeft: isMobile && 30 }}>
                                      Other Gear
                                    </span>
                                    <WSTextField
                                      error={props?.errors?.otherGear &&
                                        props?.touched?.otherGear
                                      }
                                      helperText={props?.errors?.otherGear &&
                                        props?.touched?.otherGear
                                        ? props?.errors?.otherGear
                                        : ""
                                      }
                                      className={isMobile ? "login-form-textinput ml-15" : "login-form-textinput"}
                                      id="otherGear"
                                      name={`otherGear${profession}`}
                                      placeholder={"Other Gear"}
                                      value={props?.values[`otherGear${profession}`] || ""}
                                      onChange={props?.handleChange(`otherGear${profession}`)}
                                      disabled={loaderButton}
                                    />
                                  </div>
                                </div>
                              </Fragment>
                            )}
                          <span className="w-100"></span>
                        </div>
                        <Paper
                          style={{
                            marginTop: 30,
                            marginBottom: 30,
                            padding: 20,
                          }}
                        >
                          <div className="col-md-12">
                            <h2>Provider Portfolio</h2>
                          </div>
                          <ProviderPortfolio editable />
                        </Paper>

                        <div>
                          <ProviderServiceTable
                            actualData={this.state?.serviceData}
                            providerId={providerDetail && providerDetail?.id}
                            headings={[
                              { name: "Name", id: "name" },
                              { name: "Price per hour", id: "price" },
                              { name: "Total Packages", id: "packageCount" },
                              { name: "Status", id: "status" },
                              { name: "Actions", id: "action" },
                            ]}
                            title="providerservices"
                            type="servicesUpdate"
                          />
                        </div>
                        <div>
                          <div>
                            <AddressList
                              {...this.props}
                              providerAddress={providerDetail}
                            />
                          </div>
                        </div>
                        <div className="mt-5">
                          <div className="col-md-12">
                            <h3>Manage Timings</h3>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <List>
                                {timingData.map((timing) => {
                                  return (
                                    <ListItem key={timing?.key}>
                                      <ListItemIcon>
                                        <Checkbox
                                          color="primary"
                                          checked={timing?.status}
                                          onChange={() =>
                                            this.handleClick(timing?.key - 1)
                                          }
                                          value={timing?.key}
                                        />
                                      </ListItemIcon>
                                      <ListItemText
                                        className="dashboard-bookhr-text"
                                        disableTypography={true}
                                        primary={
                                          <div className="row col-md-12 p-0 flex-align-center">
                                            <div className={`col-md-2 col-xs-4`}>
                                              <h5 className="m-0 text-black-400">
                                                {timing.day}
                                              </h5>
                                            </div>
                                            <div className={`col-md-4 col-xs-8 select-time-container`}
                                              onClick={() => {
                                                this.onTimePress(timing?.key - 1);
                                                this.setState({ timing });
                                              }}
                                            >
                                              <div className="d-flex">
                                                <p className="m-0 text-black-300" style={{ cursor: "pointer", }}>
                                                  {timing?.startTime}
                                                </p>

                                                <p className="m-0 text-black-300 ml-2 mr-2">
                                                  -
                                                </p>
                                                <p className="m-0 text-black-300" style={{ cursor: "pointer", }}>
                                                  {timing?.endTime}
                                                </p>
                                              </div>
                                            </div>
                                          </div>
                                        }
                                      />
                                    </ListItem>
                                  );
                                })}
                              </List>
                            </div>
                          </div>
                          <TimePickerPopup
                            isVisible={isDateTimePickerVisible}
                            onChangeStartDate={this.onChangeStartDate}
                            onChangeEndDate={this.onChangeDateEnd}
                            dateTimeValue={dateTimeValue}
                            closePopup={this.handleClose}
                            dateFormat={false}
                            timeFormat={true}
                            open={true}
                            saveDate={this.saveDate}
                            loaderButton={timeLoader}
                            defaultValueStartTime={timing && timing?.startTime}
                            defaultValueEndTime={timing && timing?.endTime}
                          />
                        </div>
                        <div className="col-md-12 col-xs-12">
                          <div className="mt-3" />
                          {this.renderButton(props)}
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </CardBox>
          </div>
        </div>
      </HOCLoader>
    );
  }
}
const mapStateToProps = ({ admin }) => {
  return {
    providerDetails: admin?.providerDetail,
    nationalityData: admin?.nationalityData,
    languagesData: admin?.languagesData,
  };
};

const composedHOC = compose(connect(mapStateToProps, actions));
export default composedHOC(injectIntl(ProviderUpdate));

import React, { lazy } from "react";

const BookingTable = lazy(() => import("../../../components/MyComponents/BookingTable"));

class BookingList extends React.Component {
  render = () => {
    const { title = "Bookings", match } = this.props;
    return (
      <BookingTable
        headings={[
          { name: "Booking Token", id: "token" },
          { name: "Date Time", id: "datetime" },
          { name: "Total Amount", id: "total_amount" },
          { name: "Payment Status", id: "payment_status" },
          { name: "Booking Status", id: "status" },
          { name: "Actions", id: "actions" }
        ]}
        title={title}
        match={match}
      />
    );
  };
}

export default BookingList;
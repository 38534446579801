import {
  all,
  call,
  fork,
  put,
  takeEvery,
  select
} from "redux-saga/effects";

import {
  setThemeColor,
  getThemeColor,
  getDarkTheme,
  setDarkTheme
} from "../actions/Setting";

import { setAdminThemeValues } from "../apiHelpers/settings/create";

import { THEME_COLOR, DARK_THEME, DRAWER_TYPE } from "../constants/ActionTypes";

function* getAdminThemeOptions({ payload }) {
  try {
    const data = yield call(setAdminThemeValues, { mainColor: payload });
    if (data.status === "OK") {
      yield put(getThemeColor(payload));
    } else {
      console.log("Error Setting Saga", data);
    }
  } catch (error) {
    console.log("Error Found");
  }
}

// function* getAdminDarkOption() {
//   const returnedState = state => state.settings;
//   const settings = yield select(returnedState);
//   try {
//     const data = yield call(setAdminThemeValues, {
//       isDark: settings.darkTheme
//     });
//   } catch (error) {
//     console.log("Error Found", error);
//   }
// }

// function* getAdminDrawerOption() {
//   const returnedState = state => state.settings;
//   const settings = yield select(returnedState);
//   try {
//     const data = yield call(setAdminThemeValues, {
//       navBarType: settings.drawerType
//     });
//   } catch (error) {
//     console.log("Error Found", error);
//   }
// }

export function* getThemeOptions() {
  yield takeEvery(THEME_COLOR, getAdminThemeOptions);
  //   yield takeEvery(DARK_THEME, getAdminDarkOption);
  //   yield takeEvery(DRAWER_TYPE, getAdminDrawerOption);
}

export default function* rootSaga() {
  yield all([fork(getThemeOptions)]);
}
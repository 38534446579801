import moment from "moment";

export const validateName = name => {
  const regex = /^[a-zA-Z1-9 -]{2,30}$/;
  if (regex.test(name) && name !== "") {
    return true;
  }
  return false;
};

export const validateCategoryName = name => {
  const regex = /^[a-zA-Z!@#$&()\\-`.+,/|^%* \"]*$/;
  if (regex.test(name) && name !== "") {
    return true;
  }
  return false;
};

export const validateNonEmpty = value => {
  if (value !== "" && typeof value !== "undefined") {
    return true;
  }
  return false;
};

export const validateCategoryId = categoryId => {
  if (categoryId !== "" && typeof categoryId !== "undefined") {
    return true;
  }
  return false;
};

export const validateDropDown = column => {
  if (column !== "" && typeof column !== "undefined") {
    return true;
  }
  return false;
};

export const isDate = date => {
  if (isNaN(Date.parse(date))) {
    return false;
  }
  return true;
};

export const validateYoutubeUrl = url => {
  const regex = /^(http(s)?:\/\/)?((w){3}.)?youtu(be|.be)?(\.com)?\/.+/;
  if (regex.test(url)) {
    return true;
  }
  return false;
};

export const isUrl = url => {
  const regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
  if (regexp.test(url)) {
    return true;
  }
  return false;
};

export const validateBusinessName = businessName => {
  const regex = /^[a-zA-Z0-9 ]{2,50}$/;
  if (regex.test(businessName)) {
    return true;
  }
  return false;
};

export const validatePhone = phone => {
  const regex = /^(1\s|1|)?((\(\d{3}\))|\d{3})(\-|\s)?(\d{3})(\-|\s)?(\d{4})$/;
  if (regex.test(phone)) {
    return true;
  }
  return false;
};

export const validatePincode = pincode => {
  const regex = /^[a-zA-Z0-9 -_]*$/;
  if (regex.test(pincode)) {
    return true;
  }
  return false;
};

export const validateInteger = number => {
  const regex = /^\d+$/;

  if (regex.test(number)) {
    return true;
  }
  return false;
};

export const validateCity = pincode => {
  const regex = /^[a-zA-Z0-9 -_]*$/;
  if (regex.test(pincode)) {
    return true;
  }
  return false;
};

export const validateNumber = number => {
  const regex = /^\d+$/;
  if (regex.test(number)) {
    return true;
  }
  return false;
};

export const validateEmail = email => {
  if (email.includes("@") && email.includes(".") && email !== "") {
    return true;
  }
  return false;
};

export const validatePassword = password => {
  if (password !== undefined || password !== "") {
    return password.length > 5;
  }
  return false;
};

export const validatePasswordForSignUp = (password, done) => {
  const capitalCheck = /[A-Z]/;
  const lowerCheck = /[a-z]/;
  const digitCheck = /\d{1}/;
  const specialCheck = /[!@#$%&*()_+\-=\[\]{};':"\\|,.<>\/?]/;
  let validation = true;
  if (password.length < 8) {
    validation = false;
    done("Password Must be atleast 8 characters");
  } else if (password.length > 20) {
    validation = false;
    done("Password must be less than 20 characters");
  } else if (!capitalCheck.test(password)) {
    validation = false;
    done("Password must have one uppercase character");
  } else if (!lowerCheck.test(password)) {
    validation = false;
    done("Password must have one lowercase character");
  } else if (!digitCheck.test(password)) {
    validation = false;
    done("Password must have one numeric character");
  } else if (!specialCheck.test(password)) {
    validation = false;
    done("Password must have one special character");
  }
  return validation;
};

export const validateSignInData = (email, password, done) => {
  let validation = true;
  if (!validateEmail(email)) {
    validation = false;
    done({ emailError: "Enter valid email" });
  }
  if (!password) {
    validation = false;
    done({ error: "Enter valid password" });
  }
  return validation;
};

export const validateSignUpData = (
  name,
  businessName,
  email,
  password,
  done
) => {
  let validation = true;
  if (!validateName(name)) {
    validation = false;
    done({ nameError: "Enter valid name" });
  }
  if (!validateBusinessName(businessName)) {
    validation = false;
    done({ businessNameError: "Enter valid business name" });
  }
  if (!validateEmail(email)) {
    validation = false;
    done({ emailError: "Enter valid email" });
  }
  if (
    !validatePasswordForSignUp(password, error => {
      done({ error });
    })
  ) {
    validation = false;
  }
  return validation;
};

export const validateResetPassword = (password, confirmPassword) => {
  if (password.length > 5 && confirmPassword.length > 5) {
    if (password === confirmPassword) {
      return null;
    }
    return "Password don't match";
  }
  return "Password should be 6 character long";
};

export const isString = variable => {
  if (typeof variable === "string" || variable instanceof String) {
    return true;
  }
  return false;
};

export const validateData = data => {
  if (data.status && isString(data.message)) {
    return true;
  }
  return false;
};

export const validateEmpty = text => {
  return text ? true : false;
};

export const validateAddressForm = (
  { name, email, phone, pincode, address, city, state, country },
  done
) => {
  let validation = true;
  if (!validateName(name)) {
    validation = false;
    done({
      nameError: "Enter valid name"
    });
  }
  if (!validatePhone(phone)) {
    validation = false;
    done({
      phoneError: "Enter valid phone"
    });
  }
  if (!validateEmail(email)) {
    validation = false;
    done({
      emailError: "Enter valid email"
    });
  }
  if (pincode.length < 4) {
    validation = false;
    done({
      pincodeError: "Enter valid pincode"
    });
  }
  if (address.length < 2) {
    done({
      addressError: "Enter valid address"
    });
  }
  if (!validateName(city)) {
    validation = false;
    done({
      cityError: "Enter valid city"
    });
  }
  if (!validateName(state)) {
    validation = false;
    done({
      stateError: "Enter valid state"
    });
  }
  if (!validateName(country)) {
    validation = false;
    done({
      countryError: "Enter valid country"
    });
  }
  return validation;
};

export const validateConfirmBookingDetail = (
  {
    note,
    customerAddress,
    paymentType,
    date,
    time,
    proPackageId,
    providerId,
    proServiceId
  },
  done
) => {
  let validation = true;
  let errorMessage = "";
  if (!validateEmpty(date)) {
    validation = false;
    errorMessage = "Please select date";
  }
  if (!validateEmpty(time)) {
    validation = false;
    errorMessage = "Select valid time";
  }
  if (!validateEmpty(customerAddress)) {
    validation = false;
    errorMessage = "Select Address";
  }
  if (validation === false) {
    done(errorMessage);
  }
  return validation;
};

export const validateServiceCreate = (
  { description, price, packageArray, faqArray },
  done
) => {
  let validation = true;
  if (description.length < 10) {
    validation = false;
    done({ descriptionError: "Description should be more then 10 characters" });
  }
  if (!validateNumber(price)) {
    validation = false;
    done({ priceError: "Price should be a number" });
  }
  if (packageArray.length > 0) {
    let packageValidation = true;
    packageArray.map((packageItem, index) => {
      if (!packageItem.description) {
        validation = false;
        packageValidation = false;
        packageArray[index].descriptionError = "Enter Description";
      }
      if (!packageItem.price) {
        validation = false;
        packageValidation = false;
        packageArray[index].priceError = "Enter Price";
      }
      if (!packageItem.name) {
        validation = false;
        packageValidation = false;
        packageArray[index].nameError = "Enter Name";
      }
      if (!packageValidation) {
        done({ packageArray });
      }
    });
  }
  if (faqArray.length > 0) {
    let faqValidation = true;
    faqArray.map((faqItem, index) => {
      if (!faqItem.question) {
        validation = false;
        faqValidation = false;
        faqArray[index].questionError =
          "Question should be atleast 10 characters";
      }
      if (!faqItem.answer) {
        validation = false;
        faqValidation = false;
        faqArray[index].answerError = "Question should be atleast 10 answers";
      }
      if (!faqValidation) {
        done({ faqArray });
      }
    });
  }
  return validation;
};

export const validateProfileUpdate = (
  { name, businessName, phone, description, establishedOn, website },
  done
) => {
  let validation = true;
  if (name > 0) {
    if (!validateName(name)) {
      validation = false;
      done({ nameError: "Enter valid name" });
    }
  }
  if (businessName) {
    if (!validateBusinessName(businessName)) {
      validation = false;
      done({ businessNameError: "Enter valid business name" });
    }
  }
  if (phone) {
    if (!validatePhone(phone)) {
      validation = false;
      done({ phoneError: "Enter valid Phone" });
    }
  }
  if (description) {
    if (description.length < 10) {
      validation = false;
      done({ descriptionError: "Description should be atleast 10 characters" });
    }
  }
  if (establishedOn) {
    if (!isDate(establishedOn)) {
      validation = false;
      done({ establishedOnError: "Please enter a valid date" });
    }
  }
  if (website) {
    if (!isUrl(website)) {
      validation = false;
      done({ websiteError: "Please enter a valid website" });
    }
  }
  return validation;
};

export const validateProviderForm = ({ name, email, businessName, experienceRating, priceRating }, done) => {
  let validation = true;
  if (!validateName(name)) {
    validation = false;
    done({ nameError: "Enter valid name" });
  }
  if (!validateBusinessName(businessName)) {
    validation = false;
    done({ businessNameError: "Enter valid business name" });
  }
  if (!validateEmail(email)) {
    validation = false;
    done({ emailError: "Enter valid email" });
  }
  if (!(experienceRating)) {
    validation = false;
    done({ experienceRatingError: "Enter valid Experience Rating" });
  }
  if (!(priceRating)) {
    validation = false;
    done({ priceRatingError: "Please Select Price Rating" });
  }
  return validation;
};

export const validateProviderCreateForm = ({ name, email, businessName }, done) => {
  let validation = true;
  if (!validateName(name)) {
    validation = false;
    done({ nameError: "Enter valid name" });
  }
  if (!validateBusinessName(businessName)) {
    validation = false;
    done({ businessNameError: "Enter valid business name" });
  }
  if (!validateEmail(email)) {
    validation = false;
    done({ emailError: "Enter valid email" });
  }
  return validation;
};


export const validateCustomerForm = ({ name, email }, done) => {
  let validation = true;
  if (!validateName(name)) {
    validation = false;
    done({ nameError: "Enter valid name" });
  }
  if (!validateEmail(email)) {
    validation = false;
    done({ emailError: "Enter valid email" });
  }
  return validation;
};

export const validateSigninForm = ({ password, email }, done) => {
  let validation = true;
  if (!validatePassword(password)) {
    validation = false;
    done({ passwordError: "Enter valid password" });
  }
  if (!validateEmail(email)) {
    validation = false;
    done({ emailError: "Enter valid email" });
  }
  return validation;
};

export const validateCategoryForm = (
  { name, howItWorks1, howItWorks2, howItWorks3 },
  done
) => {
  let validation = true;
  if (!validateCategoryName(name)) {
    validation = false;
    done({ nameError: "Enter valid name" });
  }
  if (!validateNonEmpty(howItWorks1)) {
    validation = false;
    done({ howItWorks1Error: "This cannot be empty" });
  }
  if (!validateNonEmpty(howItWorks2)) {
    validation = false;
    done({ howItWorks2Error: "This cannot be empty" });
  }
  if (!validateNonEmpty(howItWorks3)) {
    validation = false;
    done({ howItWorks3Error: "This cannot be empty" });
  }
  return validation;
};

export const validateBannerForm = ({ title }, done) => {
  let validation = true;
  if (!validateName(title)) {
    validation = false;
    done({ titleError: "Enter valid Title" });
  }
  return validation;
};

export const validateBasicSettingForm = (
  { companyName, appName, seoTitle, seoDescription, seoKeywords, adminEmail },
  done
) => {
  let validation = true;
  if (!validateNonEmpty(companyName)) {
    validation = false;
    done({ companyNameError: "Enter valid Company Name" });
  }
  if (!validateNonEmpty(appName)) {
    validation = false;
    done({ appNameError: "Enter valid Application Name" });
  }
  if (!validateNonEmpty(seoTitle)) {
    validation = false;
    done({ seoTitleError: "Enter valid SEO Title" });
  }
  if (!validateNonEmpty(seoDescription)) {
    validation = false;
    done({ seoDescriptionError: "Enter valid SEO Description" });
  }
  if (!validateNonEmpty(seoKeywords)) {
    validation = false;
    done({ seoKeywordsError: "Enter valid SEO Keywords" });
  }
  if (!validateNonEmpty(adminEmail)) {
    validation = false;
    done({ adminEmailError: "Enter valid Admin Email" });
  }
  return validation;
};

export const validateWebsiteSettingForm = (
  {
    phone,
    bookingPrefix,
    address1,
    address2,
    about,
    fb,
    skype,
    insta,
    googlePlus,
    youtube,
    linkedIn,
    ar,
    r1,
    r2,
    r3
  },
  done
) => {
  let validation = true;
  if (!validateNonEmpty(phone)) {
    validation = false;
    done({ phoneError: "Enter valid Phone Number" });
  }
  if (!validateNonEmpty(bookingPrefix)) {
    validation = false;
    done({ bookingPrefixError: "Enter valid Booking Prefix" });
  }
  if (!validateNonEmpty(address1)) {
    validation = false;
    done({ address1Error: "Enter valid Address" });
  }
  if (!validateNonEmpty(address2)) {
    validation = false;
    done({ address2Error: "Enter valid Address" });
  }
  if (!validateNonEmpty(about)) {
    validation = false;
    done({ aboutError: "Enter valid About Us" });
  }
  //   if (!validateNonEmpty(fb)) {
  //     validation = false;
  //     done({ fbError: "Enter valid Facebook Page Link" });
  //   }
  //   if (!validateNonEmpty(skype)) {
  //     validation = false;
  //     done({ skypeError: "Enter valid Skype" });
  //   }
  //   if (!validateNonEmpty(insta)) {
  //     validation = false;
  //     done({ instaError: "Enter valid Instagram Page Link" });
  //   }
  //   if (!validateNonEmpty(googlePlus)) {
  //     validation = false;
  //     done({ googlePlusError: "Enter valid GooglePlus Page Link" });
  //   }
  //   if (!validateNonEmpty(youtube)) {
  //     validation = false;
  //     done({ youtubeError: "Enter valid Youtube Page Link" });
  //   }
  //   if (!validateNonEmpty(linkedin)) {
  //     validation = false;
  //     done({ linkedinError: "Enter valid LinkedIn Page Link" });
  //   }
  if (!validateNonEmpty(ar)) {
    validation = false;
    done({ arError: "Enter valid Rating Average Parameter" });
  }
  if (!validateNonEmpty(r1)) {
    validation = false;
    done({ r1Error: "Enter valid Rating 1st Parameter" });
  }
  if (!validateNonEmpty(r2)) {
    validation = false;
    done({ r2Error: "Enter valid Rating 2nd Parameter" });
  }
  if (!validateNonEmpty(r3)) {
    validation = false;
    done({ r3Error: "Enter valid Rating 3rd Parameter" });
  }
  return validation;
};

export const validatePaymentSettingForm = (
  {
    cardLimit,
    defaultAdminCut,
    currency,
    currencySymbol,
    walletPrefix,
    // awsBucketKey,
    // awsBucketName,
    // awsBucketSecret,
    // awsBucketStatus,
    onesignalAuthKey,
    onesignalCustomerAppId,
    onesignalRestCustomerkey,
    onesignalProviderAppId,
    onesignalRestProviderkey,
    servicesLimit,
    googleMapKey,
    stripeSecret,
    stripePublic,
    twilioAuthToken,
    twilioAccountSid,
    cardstatus,
    codStatus,
    walletStatus
  },
  done
) => {
  let validation = true;
  if (!validateNonEmpty(cardLimit)) {
    validation = false;
    done({ cardLimitError: "Enter valid Phone Number" });
  }
  if (!validateNonEmpty(defaultAdminCut)) {
    validation = false;
    done({ defaultAdminCutError: "Enter valid Booking Prefix" });
  }
  if (!validateNonEmpty(currency)) {
    validation = false;
    done({ currencyError: "Enter valid Address" });
  }
  if (!validateNonEmpty(currencySymbol)) {
    validation = false;
    done({ currencySymbolError: "Enter valid Address" });
  }
  if (!validateNonEmpty(walletPrefix)) {
    validation = false;
    done({ walletPrefixError: "Enter valid About Us" });
  }
  // if (!validateNonEmpty(awsBucketKey)) {
  //   validation = false;
  //   done({ awsBucketKeyError: "Enter valid Rating Average Parameter" });
  // }
  // if (!validateNonEmpty(awsBucketName)) {
  //   validation = false;
  //   done({ awsBucketNameError: "Enter valid Rating 1st Parameter" });
  // }
  // if (!validateNonEmpty(awsBucketSecret)) {
  //   validation = false;
  //   done({ awsBucketSecretError: "Enter valid Rating 2nd Parameter" });
  // }
  // if (!validateNonEmpty(awsBucketStatus)) {
  //   validation = false;
  //   done({ awsBucketStatusError: "Enter valid Rating 3rd Parameter" });
  // }
  if (!validateNonEmpty(onesignalAuthKey)) {
    validation = false;
    done({ onesignalAuthKeyError: "Enter valid Rating 1st Parameter" });
  }
  if (!validateNonEmpty(onesignalAuthKey)) {
    validation = false;
    done({ onesignalAuthKeyError: "Enter valid Rating 2nd Parameter" });
  }
  if (!validateNonEmpty(onesignalCustomerAppId)) {
    validation = false;
    done({ onesignalCustomerAppIdError: "Enter valid Rating 3rd Parameter" });
  }
  if (!validateNonEmpty(onesignalRestCustomerkey)) {
    validation = false;
    done({ onesignalRestCustomerkeyError: "Enter valid Rating 1st Parameter" });
  }
  if (!validateNonEmpty(onesignalProviderAppId)) {
    validation = false;
    done({ onesignalProviderAppIdError: "Enter valid Rating 2nd Parameter" });
  }
  if (!validateNonEmpty(onesignalRestProviderkey)) {
    validation = false;
    done({ onesignalRestProviderkeyError: "Enter valid Rating 3rd Parameter" });
  }
  if (!validateNonEmpty(servicesLimit)) {
    validation = false;
    done({ servicesLimitError: "Enter valid Rating 1st Parameter" });
  }
  //   if (!validateNonEmpty(googleMapKey)) {
  //     validation = false;
  //     done({ googleMapKeyError: "Enter valid Rating 2nd Parameter" });
  //   }
  if (!validateNonEmpty(stripeSecret)) {
    validation = false;
    done({ stripeSecretError: "Enter valid Rating 3rd Parameter" });
  }
  if (!validateNonEmpty(stripePublic)) {
    validation = false;
    done({ stripePublicError: "Enter valid Rating 1st Parameter" });
  }
  if (!validateNonEmpty(twilioAuthToken)) {
    validation = false;
    done({ twilioAuthTokenError: "Enter valid Rating 2nd Parameter" });
  }
  if (!validateNonEmpty(twilioAccountSid)) {
    validation = false;
    done({ twilioAccountSidError: "Enter valid Rating 3rd Parameter" });
  }
  if (!cardstatus && !codStatus) {
    validation = false;
    done({ codStatusError: "One payment method is required in system" });
  }
  if (!cardstatus && walletStatus) {
    validation = false;
    done({
      walletStatusError: "For Wallet, Card payment is mandatory"
    });
  }
  return validation;
};

export const validateAdminProfileForm = ({ name, email }, done) => {
  let validation = true;
  if (!validateName(name)) {
    validation = false;
    done({ nameError: "Enter valid name" });
  }
  if (!validateEmail(email)) {
    validation = false;
    done({ emailError: "Select valid category" });
  }
  return validation;
};

export const validateServiceForm = ({ name, categoryId, price, questionoire }, done) => {
  let validation = true;
  if (name && !validateName(name)) {
    validation = false;
    done({ nameError: "Enter valid name" });
  }
  if (price && !validateNumber(price)) {
    validation = false;
    done({ priceError: "Price should be a number" });
  }
  if (categoryId && !validateCategoryId(categoryId)) {
    validation = false;
    done({ categoryError: "Select valid category" });
  }
  if (questionoire && questionoire.length > 0) {
    const validateFAQs = questionoire.map(faqItem => {
      if (!validateNonEmpty(faqItem.question)) {
        faqItem.questionError = 'Enter valid Question';
        validation = false;
      }
      return faqItem;
    });
    done({ questionoire: validateFAQs });
  }
  return validation;
};

export const validateCMSForm = (
  { title, metaTitle, metaKeyword, metaDescription },
  done
) => {
  let validation = true;
  if (!validateName(title)) {
    validation = false;
    done({ titleError: "Enter valid Title" });
  }
  if (!validateNonEmpty(metaTitle)) {
    validation = false;
    done({ metaTitleError: "Enter valid SEO Meta Title" });
  }
  if (!validateNonEmpty(metaKeyword)) {
    validation = false;
    done({ metaKeywordError: "Enter valid SEO Meta Keywords" });
  }
  if (!validateNonEmpty(metaDescription)) {
    validation = false;
    done({ metaDescriptionError: "Enter valid SEO Meta Descrption" });
  }
  return validation;
};

export const validateHelpdeskReplyForm = ({ description }, done) => {
  let validation = true;
  if (!validateNonEmpty(description)) {
    validation = false;
    done({ descriptionError: "Enter valid Message" });
  }
  return validation;
};

export const validateAdminChangePasswordForm = (
  { password, newPassword, confirmPassword },
  done
) => {
  let validation = true;
  if (!validateNonEmpty(password)) {
    validation = false;
    done({ passwordError: "Enter valid Old Password" });
  }
  if (!validatePassword(newPassword)) {
    validation = false;
    done({ newPasswordError: "Enter valid and strong New Password" });
  }
  if (!validatePassword(confirmPassword) || newPassword != confirmPassword) {
    validation = false;
    done({ confirmPasswordError: "Enter valid Confirm Password" });
  }
  return validation;
};

export const validatePromoForm = (
  {
    code,
    description,
    bookingCount,
    timesType,
    offerType,
    typeId,
    startDate,
    endDate,
    percentAmount,
    heading,
    uptoAmount
  },
  done
) => {
  let validation = true;
  if (!validateNonEmpty(code)) {
    validation = false;
    done({ codeError: "Enter valid PromoCode" });
  }
  if (!validateNonEmpty(description)) {
    validation = false;
    done({ descriptionError: "Enter valid Description" });
  }
  //   if (!validateNonEmpty(bookingCount)) {
  //     validation = false;
  //     done({ bookingCountError: "Enter valid booking Count" });
  //   }
  //   if (!validateDropDown(typeId)) {
  //     validation = false;
  //     done({ typeIdError: "Select valid Type ID" });
  //   }
  //   if (!validateDropDown(offerType)) {
  //     validation = false;
  //     done({ offerTypeError: "Select valid Offer type" });
  //   }
  // if (!validateDropDown(timesType)) {
  //     validation = false;
  //     done({ timesTypeError: "Select valid Times type" });
  //   }
  if (!validateNonEmpty(startDate)) {
    validation = false;
    done({ startDateError: "Select valid Start Date" });
  }
  if (!validateNonEmpty(endDate)) {
    validation = false;
    done({ endDateError: "Select valid End Date" });
  }
  if (moment(startDate).valueOf() >= moment(endDate).valueOf()) {
    validation = false;
    done({ endDateError: "Start Date should be before End Date" });
  }
  if (!validateNonEmpty(percentAmount)) {
    validation = false;
    done({ percentAmountError: "Enter valid Discounted Perentage" });
  }
  if (percentAmount <= 0) {
    validation = false;
    done({ percentAmountError: "Enter valid Discounted Perentage" });
  }
  if (!validateInteger(percentAmount)) {
    validation = false;
    done({ percentAmountError: "Enter valid Number Value upto 100" });
  }
  if (!validateInteger(uptoAmount)) {
    validation = false;
    done({ uptoAmountError: "Enter valid Number Value" });
  }
  if (!validateNonEmpty(heading)) {
    validation = false;
    done({ headingError: "Enter valid heading" });
  }
  return validation;
};

export const validateYoutubeURLs = (youTubeArray, done) => {
  let validation = true;
  if (youTubeArray.length > 0) {
    youTubeArray.map((youtube, index) => {
      if (youtube.url) {
        if (!validateYoutubeUrl(youtube.url)) {
          validation = false;
          youTubeArray[index].urlError = "Enter valid url";
        }
      }
      if (!validation) {
        done({ youTubeArray });
      }
    });
  }
  return validation;
};

export const validateBankDetails = (
  { bankName, accountNumber, accountType, ibanNumber, vatTrnNumber },
  done
) => {
  let validation = true;
  if (!validateNonEmpty(bankName)) {
    validation = false;
    done({ bankNameError: "Enter valid bank name" });
  } else {
    if (bankName.length > 50) {
      validation = false;
      done({
        bankNameError: "Bank Name should not be greater then 50 characters"
      });
    }
  }
  if (!validateNonEmpty(accountNumber)) {
    validation = false;
    done({ accountNumberError: "Enter valid account number" });
  } else {
    if (accountNumber.length > 30) {
      validation = false;
      done({
        accountNumberError:
          "Account Number should not be greater then 30 characters"
      });
    }
  }
  if (!validateNonEmpty(ibanNumber)) {
    validation = false;
    done({ ibanNumberError: "Enter valid iban number" });
  } else {
    if (ibanNumber.length > 15) {
      validation = false;
      done({
        ibanNumberError:
          "IBAN Number should not be greater then 15 characters"
      });
    }
  }
  if (!validateNonEmpty(vatTrnNumber)) {
    validation = false;
    done({ vatTrnNumberError: "Enter valid vat trn number" });
  } else {
    if (vatTrnNumber.length > 15) {
      validation = false;
      done({
        vatTrnNumberError:
          "VAT TRN Number should not be greater then 15 characters"
      });
    }
  }
  if (!validateNonEmpty(accountType)) {
    validation = false;
    done({ accountTypeError: "Enter valid account type" });
  } else {
    if (accountType.length > 30) {
      validation = false;
      done({
        accountTypeError:
          "Account Type should not be greater then 30 characters"
      });
    }
  }
  return validation;
};
export const validateServiceDetails = async (
  {
      packages,
      faqs,
      selectedAddresses,
      name,
      description,
      price,
      category,
      service,
      showAddOwnSection,
  },
  done
) => {
  let validation = true;
  // if (showAddOwnSection) {
  //     if (!validateName(name)) {
  //         validation = false;
  //         done({ nameError: 'Enter valid Name and only alphabets are allowed' });
  //     }
  // }
  if (category === 'selectCategory') {
      validation = false;
      done({ categoryError: 'Choose valid category' });
  }
  if (!showAddOwnSection) {
      if (service === 'selectService') {
          validation = false;
          done({ serviceError: 'Choose valid service' });
      }
      if (service === 'noService') {
          validation = false;
          done({ serviceError: 'No Available Service' });
      }
  }
  if (description.length < 10) {
      validation = false;
      done({
          descriptionError: 'Description should be more then 10 characters',
      });
  }
  if (!validateNonEmpty(price)) {
      validation = false;
      done({ priceError: 'Enter valid hourly price' });
  }
  if (packages.length > 0) {
      // const validatePackages = [];
      const validatedPackages = packages.map((packageItem) => {
          // if (packageItem.name === '' || !validateName(packageItem.name)) {
          //     packageItem.nameError = 'Enter valid Name and only alphabets are allowed';
          //     validation = false;
          // }
          if (
              packageItem.description === '' ||
              !validateNonEmpty(packageItem.description)
          ) {
              packageItem.descriptionError = 'Enter valid description';
              validation = false;
          }
          if (!validateNonEmpty(packageItem.costPrice)) {
              packageItem.costPriceError = 'Enter valid cost price';
              validation = false;
          }
          if (parseInt(packageItem.costPrice) < parseInt(packageItem.price)) {
              packageItem.costPriceError =
                  'Cost price should be greater than discounted price';
              validation = false;
          }
          if (packageItem.price) {
              if (
                  packageItem.price === '' ||
                  !validateNonEmpty(packageItem.price)
              ) {
                  packageItem.priceError = 'Enter valid discounted price';
                  validation = false;
              }
          }
          // done({ packageItem });
          return packageItem;
      });
      done({ packages: validatedPackages });
  }
  if (faqs.length > 0) {
      const validateFAQs = faqs.map((faqItem) => {
          if (!validateNonEmpty(faqItem.question)) {
              faqItem.questionError = 'Enter valid Question';
              validation = false;
          }
          if (!validateNonEmpty(faqItem.answer)) {
              faqItem.answerError = 'Enter valid Answer';
              validation = false;
          }
          return faqItem;
      });
      done({ faqs: validateFAQs });
  }
  // if (selectedAddresses.length <= 0) {
  //     validation = false;
  //     done({ addressError: 'Select atleast one address' });
  // }
  return validation;
};
export const validateUpdateServiceDetails = async (
  {
      selectedAddresses,
        description,
        price,
        category,
        service,
        packages,
  },
  done
) => {
  let validation = true;
  // if (showAddOwnSection) {
  //     if (!validateName(name)) {
  //         validation = false;
  //         done({ nameError: 'Enter valid Name and only alphabets are allowed' });
  //     }
  // }
  if (!validateNonEmpty(category)) {
      validation = false;
      done({ categoryError: 'Choose valid category' });
  }
  if (description.length < 10) {
      validation = false;
      done({
          descriptionError: 'Description should be more then 10 characters',
      });
  }
  if (!validateNonEmpty(price)) {
      validation = false;
      done({ priceError: 'Enter valid hourly price' });
  }
  // if (selectedAddresses.length <= 0) {
  //     validation = false;
  //     done({ addressError: 'Select atleast one address' });
  // }
  if (packages.length > 0) {
    // const validatePackages = [];
    const validatedPackages = packages.map((packageItem) => {
        // if (packageItem.name === '' || !validateName(packageItem.name)) {
        //     packageItem.nameError = 'Enter valid Name and only alphabets are allowed';
        //     validation = false;
        // }
        if (
            packageItem.description === '' ||
            !validateNonEmpty(packageItem.description)
        ) {
            packageItem.descriptionError = 'Enter valid description';
            validation = false;
        }
        if (!validateNonEmpty(packageItem.costPrice)) {
            packageItem.costPriceError = 'Enter valid cost price';
            validation = false;
        }
        if (parseInt(packageItem.costPrice) < parseInt(packageItem.price)) {
            packageItem.costPriceError =
                'Cost price should be greater than discounted price';
            validation = false;
        }
        if (packageItem.price) {
            if (
                packageItem.price === '' ||
                !validateNonEmpty(packageItem.price)
            ) {
                packageItem.priceError = 'Enter valid discounted price';
                validation = false;
            }
        }
        // done({ packageItem });
        return packageItem;
    });
    done({ packages: validatedPackages });
}
  return validation;
};
export const validateCustomerAddressForm = (
  { address_line1, city, state, country, pincode, name, email, phone },
  done
) => {
  let validation = true;
  // if (!validateName(name)) {
  //     validation = false;
  //     done({ nameError: 'Enter valid Name and only alphabets are allowed' });
  // }
  if (!validateEmail(email)) {
      validation = false;
      done({ emailError: 'Enter valid email' });
  }
  if (!validateNonEmpty(address_line1)) {
      validation = false;
      done({ address_line1Error: 'Enter valid Address' });
  }
  if (!validateNonEmpty(city)) {
      validation = false;
      done({ cityError: 'Enter valid City' });
  }
  if (!validateNonEmpty(state)) {
      validation = false;
      done({ stateError: 'Enter valid State' });
  }
  if (!validateNonEmpty(country)) {
      validation = false;
      done({ countryError: 'Enter valid Country' });
  }
   return validation;
};
import React, { lazy } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import IntlMessages from '../../../util/IntlMessages';
import CircularProgress from "@material-ui/core/CircularProgress";
import { validateServiceForm } from "../../../util/validators/validateForm";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import * as actions from "../../../actions";
import { connect } from "react-redux";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import ReactCrop from "react-image-crop";
import Dropzone from "react-dropzone";
import "react-image-crop/dist/ReactCrop.css";

const ContainerHeader = lazy(() => import("../../../components/ContainerHeader/index"));
const CardBox = lazy(() => import("../../../components/CardBox/index"));
const SimpleSelect = lazy(() => import("../components/routes/selects/simple/SimpleSelect"));
const HOCLoader = lazy(() => import("../../../components/MyComponents/HOCLoader"));

const handleDropRejected = (...args) => console.log("reject", args);
class SubcategoryUpdate extends React.Component {
  constructor(props) {
    super();
    this.state = {
      error: "",
      description: "",
      name: "",
      status: "1",
      categoryId: "",
      categoryError: "",
      loader: true,
      loadingButton: false,
      nameError: "",
      redirect: false,
      type: "",
      noResponse: false,
      errorMessage: "",
      preview: null,
      existingImage: "",
      categoryData: [],
      subcategoryDetail: "",
      itemId: props.location.state ? props.location.state.itemId : "",
      preview: null,
      crop: {
        x: 40,
        y: 40,
        width: 100,
        height: 100,
      },
      croppedImageUrl: "",
    };
    this.handleDrop = this.handleDrop.bind(this);
  }
  componentDidMount() {
    if (!this.props.location.state) {
      return this.props.history.push("/app/service/list");
    } else {
      this.getData(this.props.location.state.itemId);
      this.props.categoryListAction(this.onCategoryDone);
    }
  }
  onCategoryDone = (success, data) => {
    if (success) {
      this.setState({ categoryData: data });
    }
    this.setState({ loader: false });
  };
  //apicall
  getData = async itemId => {
    this.props.subcategoryDetailAction(
      {
        id: itemId
      },
      this.onDoneDetail
    );
  };

  onDoneDetail = (success, data) => {
    if (success) {
      console.log(data.heading);
      this.setState(
        {
          subcategoryDetail: data,
          name: data.heading,
          status: data.status,
          description: data.description,
          categoryId: data.category_id,
          existingImage: data.image.medium
        },
        () => {
          this.setState({ loader: false });
        }
      );
    } else {
      this.setState({ loader: false, noResponse: true, error: data });
    }
  };
  //apicall
  handleDrop([{ preview }]) {
    this.setState({ preview });
  }

  onImageLoaded = (image, pixelCrop) => {
    this.imageRef = image;
  };
  onCropComplete = async (crop) => {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ croppedImageUrl });
    }
  };

  getCroppedImg(image, pixelCrop, fileName) {
    const canvas = document.createElement("canvas");

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      pixelCrop.x * scaleX,
      pixelCrop.y * scaleY,
      pixelCrop.width * scaleX,
      pixelCrop.height * scaleY,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );

    return new Promise((resolve, reject) => {
      this.fileUrl = canvas.toDataURL();
      resolve(this.fileUrl);
    });
  }

  submit = params => {
    const { name, description, status, categoryId, itemId } = this.state;

    if (validateServiceForm({ name, categoryId }, this.handleError)) {
      this.setState({ loadingButton: true });
      this.props.subcategoryUpdateAction(
        {
          id: itemId,
          name,
          image: this.state.croppedImageUrl,
          description,
          status,
          categoryId
        },
        this.onDone
      );
    }
  };
  handleError = params => {
    this.setState(params);
    this.setState({ loadingButton: false });
  };
  onDone = (success, message) => {
    if (success) {
      this.setState({ loadingButton: false });
      return this.props.history.push("/app/subcategory/list");
    } else {
      this.setState({ loadingButton: false });
    }
  };

  renderButton = () => {
    if (this.state.loadingButton) {
      return <CircularProgress />;
    }
    return (
      <Button
        variant="contained"
        color="primary"
        className="jr-btn"
        onClick={this.submit}
      >
        Update
      </Button>
    );
  };
  handleChange = categoryId => event => {
    this.setState({ [categoryId]: event.target.value, categoryError: "" });
  };

  render() {
    const { match } = this.props;

    const {
      status,
      loader,
      noResponse,
      errorMessage,
      categoryData,
      categoryError,
      nameError,
      description,
      descriptionError,
      subcategoryDetail,
      loadingButton,
      preview,
      croppedImageUrl,
      existingImage,
      name
    } = this.state;
    if (loader) {
      return (
        <div className="loader-view loader-center">
          <CircularProgress />
        </div>
      );
    }
    return (
      <HOCLoader
        loading={loader}
        noResponse={noResponse}
        errorShow={errorMessage}
        retryLink={"/app/subcategory/list"}
      >
        <div className="animated slideInUpTiny animation-duration-3">
          <ContainerHeader
            title={<IntlMessages id="update-subcategory" />}
            match={match}
          />

          <CardBox styleName="col-lg-12">
            <form
              className="row"
              noValidate
              autoComplete="off"
              id="category-create"
              encType="multipart/form-data"
            >
              <div className="col-md-12 col-xs-12">
                <div className="row">
                  {!preview && (
                    <div className="col-md-2 mt-1 mr-4">
                      {existingImage && (
                        <div>
                          <h3>Category Existing Image</h3>
                          <img
                            width={"100px"}
                            height={"100px"}
                            alt="existingImage"
                            src={existingImage}
                          />
                        </div>
                      )}
                    </div>
                  )}
                  <div className="col-md-2">
                    <h3>Drop Image here to Upload</h3>
                    <Dropzone
                      onDrop={this.handleDrop}
                      accept="image/jpeg,image/jpg,image/png"
                      multiple={false}
                      onDropRejected={handleDropRejected}
                    >
                      <p> Drag a file here or click to upload.</p>
                    </Dropzone>
                  </div>
                  <div className="col-md-6">
                    {preview && (
                      <div>
                        <h3>Original Image</h3>
                        <ReactCrop
                          src={preview}
                          imageStyle={{
                            width: "100%",
                            height: "100%"
                          }}
                          onImageLoaded={this.onImageLoaded}
                          onComplete={this.onCropComplete}
                          crop={this.state.crop}
                          onChange={crop => {
                            this.setState({ crop });
                          }}
                        />
                      </div>
                    )}
                  </div>
                  <div className="col-md-4">
                    {croppedImageUrl && (
                      <div>
                        <h3>Final Image</h3>
                        <img
                          width={"200px"}
                          height={"200px"}
                          alt="Crop"
                          src={croppedImageUrl}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="row">
                  <div className="col-md-4 col-12">
                    <TextField
                      error={nameError ? true : false}
                      helperText={nameError}
                      id="name"
                      label="Name"
                      name="name"
                      InputLabelProps={{
                        shrink: true
                      }}
                      placeholder="Add Name"
                      fullWidth
                      onChange={event => {
                        this.setState({
                          name: event.target.value,
                          nameError: "",
                          error: ""
                        });
                      }}
                      defaultValue={subcategoryDetail.heading}
                      value={name}
                      disabled={loadingButton ? true : false}
                      margin="normal"
                    />
                  </div>
                  <div className="col-md-4 col-xs-12">
                    <FormControl
                      className="w-100 mt-3"
                      error={categoryError ? true : false}
                    >
                      <InputLabel htmlFor="categoryId">Category</InputLabel>
                      <Select
                        fullWidth
                        name="categoryId"
                        value={this.state.categoryId}
                        onChange={this.handleChange("categoryId")}
                        input={
                          <Input
                            id="categoryId"
                            disabled={loadingButton ? true : false}
                          />
                        }
                      >
                        {categoryData &&
                          categoryData.map((item, index) => {
                            return (
                              <MenuItem value={item.key} key={index}>
                                {item.heading}
                              </MenuItem>
                            );
                          })}
                      </Select>
                      <FormHelperText>{categoryError}</FormHelperText>
                    </FormControl>
                  </div>
                  <div className="col-md-4 col-xs-12">
                    {/* <SimpleSelect
                      fullWidth
                      label="Status"
                      name="status"
                      id="category-status"
                      defaultValue={status}
                      onChange={status => {
                        this.setState({ status });
                      }}
                      defaultValue={serviceDetail.status}
                      disabled={loadingButton ? true : false}
                    /> */}

                    <FormControl className="w-100 mt-3">
                      <InputLabel htmlFor={"status"}>Status</InputLabel>
                      <Select
                        value={status}
                        onChange={this.handleChange("status")}
                        input={<Input id={"status"} />}
                      >
                        <MenuItem value={1}>
                          <em>Active</em>
                        </MenuItem>
                        <MenuItem value={2}>Inactive</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 col-xs-12">
                    <TextField
                      error={descriptionError ? true : false}
                      helperText={descriptionError}
                      id="description"
                      label="Description"
                      InputLabelProps={{
                        shrink: true
                      }}
                      onChange={event => {
                        this.setState({
                          description: event.target.value,
                          descriptionError: ""
                        });
                      }}
                      name="description"
                      placeholder="Add Description"
                      fullWidth
                      margin="normal"
                      defaultValue={description}
                      value={description}
                      disabled={loadingButton ? true : false}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-xs-12">
                <div className="mt-3" />
                {this.renderButton()}
              </div>
            </form>
          </CardBox>
        </div>
      </HOCLoader>
    );
  }
}

export default connect(
  null,
  actions
)(SubcategoryUpdate);

import axios from "axios";
import { NotificationManager } from "react-notifications";

import { getCookies } from "../../util/getToken";
import { errorResponse, validateApiData } from "../../util/validators/validators";

const SERVER_URL = process.env.REACT_APP_SERVER_URL

export const galleryList = async params => {
  let authToken = getCookies();
  try {
    const { data } = await axios({
      method: "post",
      data: params,
      url: `${SERVER_URL}/admin/gallery/list`,
      headers: { "x-auth-token": authToken }
    });
    return validateApiData(data) ? data : errorResponse;
  } catch (e) {
    console.log("Error in gallery list", e);
    NotificationManager.error(errorResponse.message, "Oops!");
    return errorResponse;
  }
};

export const galleryVideoList = async params => {
  let authToken = getCookies();
  try {
    const { data } = await axios({
      method: "post",
      data: params,
      url: `${SERVER_URL}/admin/gallery/vlist`,
      headers: { "x-auth-token": authToken }
    });
    return validateApiData(data) ? data : errorResponse;
  } catch (e) {
    console.log("Error in gallery list", e);
    NotificationManager.error(errorResponse.message, "Oops!");
    return errorResponse;
  }
};

export const proGalleryList = async params => {
  let authToken = getCookies();
  try {
    const { data } = await axios({
      method: "post",
      data: params,
      url: `${SERVER_URL}/admin/provider/gallery`,
      headers: { "x-auth-token": authToken }
    });
    return validateApiData(data) ? data : errorResponse;
  } catch (e) {
    console.log("Error in gallery list", e);
    NotificationManager.error(errorResponse.message, "Oops!");
    return errorResponse;
  }
};

export const deleteGalleryImage = async params => {
  let authToken = getCookies();
  try {
    const { data } = await axios({
      method: "delete",
      data: params,
      url: `${SERVER_URL}/admin/gallery/deleted`,
      headers: { "x-auth-token": authToken }
    });
    return validateApiData(data) ? data : errorResponse;
  } catch (e) {
    console.log("Error in gallery list", e);
    NotificationManager.error(errorResponse.message, "Oops!");
    return errorResponse;
  }
};

export const deleteGalleryVideo = async params => {
  let authToken = getCookies();
  try {
    const { data } = await axios({
      method: "delete",
      data: params,
      url: `${SERVER_URL}/admin/gallery/vdeleted`,
      headers: { "x-auth-token": authToken }
    });
    return validateApiData(data) ? data : errorResponse;
  } catch (e) {
    console.log("Error in gallery list", e);
    NotificationManager.error(errorResponse.message, "Oops!");
    return errorResponse;
  }
};
import axios from "axios";
import {
  all,
  call,
  fork,
  put,
  takeEvery
} from "redux-saga/effects";

import {
  CUSTOMER_CREATE,
  GET_USERS,
  SORT_CUSTOMER,
} from "../constants/ActionTypes";
import { CUSTOMER_DETAIL } from "../constants/ActionTypes";
import {
  showMessage,
  listUsers,
  fetchDetail,
  fetchSortCustomer,
} from "../actions/Customer";

const SERVER_URL = process.env.REACT_APP_SERVER_URL

const createUserWithEmailPasswordRequest = async (
  name,
  email,
  password,
  status,
  phone,
  role
) => {
  try {
    const { data } = await axios({
      method: "post",
      url: `${SERVER_URL}/admin/user/customerCreate`,
      data: {
        name,
        email,
        password,
        status,
        role: role,
        phone
      }
      // headers: {
      //   "x-auth-token":
      //     "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJwYXNzd29yZCI6IjViOWI2NjIwZDk1ZTEzMTk5NDJlODc0MGpAai5jb20iLCJpYXQiOjE1MzgzODExODd9.rGYDmlKQ5etNYeKF1rvGsAXFctzjKwSp666E4HKmVYs",
      //   "Content-Type": "application/json"
      // }
    });
    return data;
  } catch (e) {
    console.log("err", e);
  }
};

const getAllUsers = async role => {
  try {
    const { data } = await axios({
      method: "post",
      url: `${SERVER_URL}/admin/user/list`,
      data: {
        role
      }
    });
    return data;
  } catch (e) {
    console.log("err", e);
  }
};

function* createUserWithEmailPassword({ payload }) {
  const {
    params: { email, password, name, status, phone, role },
    done
  } = payload;
  try {
    const user = yield call(
      createUserWithEmailPasswordRequest,
      name,
      email,
      password,
      status,
      phone,
      role
    );
    if (user.status === "NOK") {
      done(false);
    } else if (user.status === "OK") {
      yield put(showMessage(user.message));
      done(true);
    }
  } catch (error) {
    yield put(showMessage(error));
  }
}

function* getUserList({ payload }) {
  try {
    const response = yield call(getAllUsers, payload);
    if (response.status === "NOK") {
      yield put(showMessage(response.message));
    } else if (response.status === "OK") {
      yield put(listUsers(response.data));
    }
  } catch (error) {
    yield put(showMessage(error));
  }
}

const getUserDetail = async userId => {
  try {
    const { data } = await axios({
      method: "post",
      url: `${SERVER_URL}/admin/user/detail`,
      data: {
        userId
      }
    });
    return data;
  } catch (e) {
    console.log("err", e);
  }
};

function* getCustomerDetail({ payload }) {
  try {
    const response = yield call(getUserDetail, payload);
    if (response.status === "NOK") {
      yield put(showMessage(response.message));
    } else if (response.status === "OK") {
      yield put(fetchDetail(response.data));
    }
  } catch (error) {
    yield put(showMessage(error));
  }
}

const getSortApi = async (order, orderBy, column) => {
  try {
    const { data } = await axios({
      method: "post",
      url: `${SERVER_URL}/admin/user/detail`,
      data: {
        order,
        orderBy,
        column
      }
    });
    return data;
  } catch (e) {
    console.log("err", e);
  }
};

function* getSortCustomerList({ payload }) {
  try {
    const response = yield call(getSortApi, payload);
    if (response.status === "NOK") {
      yield put(showMessage(response.message));
    } else if (response.status === "OK") {
      yield put(fetchSortCustomer(response.data));
    }
  } catch (error) {
    yield put(showMessage(error));
  }
}

export function* createCustomer() {
  yield takeEvery(CUSTOMER_CREATE, createUserWithEmailPassword);
}

export function* userList() {
  yield takeEvery(GET_USERS, getUserList);
}

export function* customerDetail() {
  yield takeEvery(CUSTOMER_DETAIL, getCustomerDetail);
}

export function* sortCustomerSaga() {
  yield takeEvery(SORT_CUSTOMER, getSortCustomerList);
}

export default function* rootSaga() {
  yield all([
    fork(createCustomer),
    fork(userList),
    fork(customerDetail),
    fork(sortCustomerSaga)
  ]);
}
import {
  catCreate,
  categoryUpdate,
  categoryDelete,
  categoryRestore
} from "../apiHelpers/category/create";
import { getCategoryDetail } from "../apiHelpers/category/detail";
import { getCategories } from "../apiHelpers/category/list";

export const categoryCreateAction = (params, done) => async dispatch => {
  const apiData = await catCreate(params);
  if (apiData.status == "OK") {
    done(true, apiData.data);
  } else {
    done(false, apiData.message);
  }
};

export const categoryDetailAction = (params, done) => async dispatch => {
  const apiData = await getCategoryDetail(params);
  if (apiData.status == "OK") {
    done(true, apiData.data);
  } else {
    done(false, apiData.message);
  }
};

export const categoryListAction = done => async dispatch => {
  const apiData = await getCategories();
  if (apiData.status == "OK") {
    done(true, apiData.data);
  } else {
    done(false, apiData.message);
  }
};

export const categoryUpdateAction = (params, done) => async dispatch => {
  const apiData = await categoryUpdate(params);
  if (apiData.status == "OK") {
    done(true, apiData.data);
  } else {
    done(false, apiData.message);
  }
};

export const deleteCategoryAction = (params, done) => async dispatch => {
  const apiData = await categoryDelete(params);
  if (apiData.status == "OK") {
    done(true, apiData.data);
  } else {
    done(false, apiData.message);
  }
};

export const restoreCategoryAction = (params, done) => async dispatch => {
  const apiData = await categoryRestore(params);
  if (apiData.status == "OK") {
    done(true, apiData.data);
  } else {
    done(false, apiData.message);
  }
};
import React, { lazy } from "react";

import IntlMessages from '../../../util/IntlMessages';

const AddressMap = lazy(() => import("../../../components/MyComponents/AddressMap"));
const CardBox = lazy(() => import("../../../components/CardBox"));
const ContainerHeader = lazy(() => import("../../../components/ContainerHeader"));

class Map extends React.Component {
  constructor(props) {
    super();
    this.state = {
      error: "",
      redirect: false,
      noResponse: false,
      loader: false,
      mainDetail: null,
      detail: props.location.state ? props.location.state.addressDetail : ""
    };
  }

  render() {
    const { match } = this.props;
    const { detail } = this.state;
    return (
      <div className="animated slideInUpTiny animation-duration-3">
        <ContainerHeader
          title={<IntlMessages id="Provider Address" />}
          match={match}
        />
        <div className="row">
          <CardBox styleName="col-lg-12">
            <AddressMap detail={detail} />
          </CardBox>
        </div>
      </div>
    );
  }
}

export default Map;
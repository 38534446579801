import React, { lazy } from "react";
import Table from "../table/index";

const TransactionTable = lazy(() => import("../../../components/MyComponents/TransactionTable"));

class TransactionList extends React.Component {
  render = () => {
    const { title = "Transaction", match } = this.props;

    return (
      <TransactionTable
        headings={[
          { name: "Total Amount", id: "amount" },
          { name: "Date Time", id: "created_at" },
          { name: "Transction Id", id: "transaction_id" },
          { name: "Booking Token", id: "booking_token" },
          { name: "Provider", id: "provider_name" },
          { name: "Customer", id: "customer_name" },
          { name: "Transaction Status", id: "status" }
        ]}
        title={title}
        match={match}
      />
    );
  };
}

export default TransactionList;
